import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../utility";
import {decryptData} from "../../helpers/SecureLocalStorage";

const initialState = {
    accessToken: decryptData(localStorage.getItem('accessToken')),
    refreshToken: decryptData(localStorage.getItem("refreshToken")),
    oAuthProviderType: decryptData(localStorage.getItem("oAuthProviderType")),
    imageURL: decryptData(localStorage.getItem("imageURL")),
    userName: decryptData(localStorage.getItem("userName")),
    name: decryptData(localStorage.getItem("name")),
    email: decryptData(localStorage.getItem("email")),
    tokenGeneratedTime: null,
    error: null,
    loading: false,
    authRidirectPath: "/",
    isSessionTimeout: false,
    selectedUserDataFromLogin: "",
};

const authStart = (state, action) => {
    return updateObject(state, {error: null, loading: true});
};

const authSuccess = (state, action) => {
    return updateObject(state, {
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
        oAuthProviderType: action.oAuthProviderType,
        imageURL: action.imageURL,
        userName: action.userName,
        name: action.name,
        email: action.email,
        error: null,
        loading: false,
    });
};
const refreshAuthSession = (state, action) => {
    return updateObject(state, {
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
    });
};
const updateProfile = (state, action) => {
    return updateObject(state, {
        imageURL: action.imageURL,
    });
};
const updateProfileName = (state, action) => {
    return updateObject(state, {
        name: action.name,
    });
};
const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
};

const authLogout = (state, action) => {
    return updateObject(state, {
        accessToken: null,
        refreshToken: null,
        oAuthProviderType: null,
        imageURL: null,
        email: null,
        userName: null,
        name: null,
        isSessionTimeout: null
    });
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, {
        authRedirectPath: action.path,
    });
};

const getUserDataFromLogin = (state, action) => {
    return updateObject(state, {
        selectedUserDataFromLogin: action.selectedUserDataFromLogin,
    });
};

const sessionTimeout = (state, action) => {
    return updateObject(state, {
        isSessionTimeout: true,
    });
};

const resetSessionTimeout = (state, action) => {
    return updateObject(state, {
        isSessionTimeout: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionTypes.AUTH_FAIL:
            return authFail(state, action);
        case actionTypes.AUTH_LOGOUT:
            return authLogout(state, action);
        case actionTypes.UPDATE_PROFILE:
            return updateProfile(state, action);
        case actionTypes.UPDATE_NAME:
            return updateProfileName(state, action);
        case actionTypes.REFRESH_SESSION:
            return refreshAuthSession(state, action);
        case actionTypes.SET_AUTH_RIDIRECT_PATH:
            return setAuthRedirectPath(state, action);
        case actionTypes.SELECT_USER_DETAILS:
            return getUserDataFromLogin(state, action);
        case actionTypes.SESSION_TIMEOUT:
            return sessionTimeout(state, action);
        case actionTypes.RESET_SESSION_TIMEOUT:
            return resetSessionTimeout(state, action);

        default:
            return state;
    }
};

export default reducer;
