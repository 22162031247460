import React, {useEffect, useRef, useState} from "react";
import styles from "./CreateFantasyTeam.module.scss";
import NavbarSecondary from "../../components/NavigationBar/NavigationBar";
import classNames from "classnames";
import Footer from "../../components/Footer/Footer";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import EditIcon from "../../assets/images/icons/edit_icon.svg";
import SearchIcon from "../../assets/images/icons/search_icon.svg";
import MoreInfoIcon from "../../assets/images/icons/more_info_icon.svg";
import TrashIcon from "../../assets/images/delete_icon.webp";
import {getAllPlayersByTournamentId, getFantasyTeamByUsernameAndTournamentId, postUserFantasyTeam} from "../../services/FantasyService";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {getPlayerHittingRank, getRandomPlaceHolderImage, playerRoleStyleController} from "../../helpers/CommonFunctions";
import FantasyTeamConfirmation from "../../components/Modals/FantasyTeamConfirmation/FantasyTeamConfirmation";
import PlayerInfoModal from "../../components/Modals/PlayerInfoModal/PlayerInfoModal";
import HowFantasyWorksModal from "../../components/Modals/HowFantasyWorks/HowFantasyWorks";
import {TOURNAMENT_TYPES} from "../../utilities/Constants";
import {getConf} from "../../services/ConfigService";
import {FANTASY_TEAM} from "../../utilities/ConfigConstants";
import {t} from "i18next";
import FantasyPointCalculation from "../../components/Modals/HowFantasyWorks/FantasyPointCalculation";

import PlaceHolder1 from "../../assets/images/FantasyProfilePic1.webp";

const PLAYER_ROLE = {
   PLAYER: "PLAYER", CAPTAIN: "CAPTAIN", VICE_CAPTAIN: "VICE_CAPTAIN"
}

const CreateFantasyTeam = (props) => {
   const {tournamentId} = useParams();
   const {state} = useLocation();
   const teamSectionRef = useRef();
   const allPlayersSectionRef = useRef();
   const navigate = useNavigate();
   const navigateWithReplace = (path) => navigate(path, {replace: true})
   const pageLang = localStorage.getItem('language');
   const MAX_PLAYER_COUNT = getConf(FANTASY_TEAM.MAX_PLAYER_COUNT)
   const PLAYER_ADDED_TOAST_ID = "PLAYER_ADDED_TOAST_ID";
   const TEAM_CREATING_TOAST_ID = "TEAM_CREATING_TOAST_ID";

   const [allTournamentPlayers, setAllTournamentPlayers] = useState([]);
   const [filteredPlayers, setFilteredPlayers] = useState([]);
   const [filterText, setFilterText] = useState('');
   const [isEditing, setIsEditing] = useState(false);

   const [fantasyTeamName, setFantasyTeamName] = useState('');
   const [isErrorInTeamName, setIsErrorInTeamName] = useState(false);
   const [fantasyTeamObj, setFantasyTeamObj] = useState({}); // {playerId: playerObj
   const [teamCaptain, setTeamCaptain] = useState(null);
   const [teamViceCaptain, setTeamViceCaptain] = useState(null);
   const [selectedPlayerCount, setSelectedPlayerCount] = useState(0);

   const [showHowFantasyWorksModal, setShowHowFantasyWorksModal] = useState(false);
   const [showFantasyPointCalculationModal, setShowFantasyPointCalculationModal] = useState(false);
   const [showTeamConfirmationModal, setShowTeamConfirmationModal] = useState(false);
   const [showPlayerInfoModal, setShowPlayerInfoModal] = useState(false);
   const [selectedPlayerId, setSelectedPlayerId] = useState(null); // player id for player info modal

   const [isPlayersLoading, setIsPlayersLoading] = useState(true);
   const [isTeamLoading, setIsTeamLoading] = useState(true);
   const [isTeamSubmitting, setIsTeamSubmitting] = useState(false);
   const [errorLoadingPlayers, setErrorLoadingPlayers] = useState(false);
   const [screenSize, setScreenSize] = useState(window.innerWidth);
   const [placeHolderImage, setPlaceHolderImage] = useState(Array(MAX_PLAYER_COUNT).fill(PlaceHolder1));

   const handleTeamNameChange = (e) => {
      const value = e.target.value;
      setFantasyTeamName(value)

      if (value) {
         setIsErrorInTeamName(false);
      } else {
         setIsErrorInTeamName(true);
      }
   }

   const handleAddPlayer = (player, type = PLAYER_ROLE.PLAYER) => {
      if (Object.keys(fantasyTeamObj).length >= MAX_PLAYER_COUNT) {
         toast.warning(t("FANTASY.YOU_HAVE_ALREADY_SELECTED_MAXIMUM_NUMBER_OF_PLAYERS"));
         return;
      }
      if (!fantasyTeamObj[player.playerId]) {
         const newPlayerValue = {
            isCaptain: type === PLAYER_ROLE.CAPTAIN,
            isVcCaptain: type === PLAYER_ROLE.VICE_CAPTAIN,
            playerId: player.playerId,
            playerName: player.playerName,
            playerPictureURL: player.playerPictureURL,
            playerRole: "PLAYER",
            addedOrder: Date.now(), // Add a timestamp to maintain the order
         }

         if (type === PLAYER_ROLE.CAPTAIN) {
            handleMakeCaptain(newPlayerValue)
         } else if (type === PLAYER_ROLE.VICE_CAPTAIN) {
            handleMakeViceCaptain(newPlayerValue)
         } else {
            handleMakeOther(newPlayerValue)
         }

         const firstName = player.playerName?.split(" ")[0];
         if (toast.isActive(PLAYER_ADDED_TOAST_ID)) {
            toast.update(PLAYER_ADDED_TOAST_ID, {
               render: `${firstName} ${t("FANTASY.HAS_BEEN_ADDED_TO_YOUR_FANTASY_TEAM")}`
            })
         } else {
            toast.success(`${firstName} ${t("FANTASY.HAS_BEEN_ADDED_TO_YOUR_FANTASY_TEAM")}`, {
               toastId: PLAYER_ADDED_TOAST_ID
            });
         }

         // if all players are selected, scroll to the fantasy team section
         if (selectedPlayerCount === MAX_PLAYER_COUNT - 1) {
            if (!teamCaptain && !teamViceCaptain) {
               toast.info(t("FANTASY.NOW_SELECT_CAPTAIN_AND_VICE_CAPTAIN"));
            } else if (!teamCaptain) {
               toast.info(t("FANTASY.NOW_SELECT_CAPTAIN"));
            } else if (!teamViceCaptain) {
               toast.info(t("FANTASY.NOW_SELECT_VICE_CAPTAIN"));
            }

            handleScrollToFantasyTeam();
         }
      }
   }

   const handleRemovePlayer = (player) => {
      setFantasyTeamObj(prv => {
         const newFantasyTeamObj = {...prv};
         delete newFantasyTeamObj[player.playerId];
         return newFantasyTeamObj;
      })

      if (player.playerId === teamCaptain?.playerId) {
         setTeamCaptain(null)
      } else if (player.playerId === teamViceCaptain?.playerId) {
         setTeamViceCaptain(null)
      }
   }

   const handleMakeCaptain = (player) => {
      setTeamCaptain(player)

      // if player is already vice captain, remove vice captain
      if (player.playerId === teamViceCaptain?.playerId) {
         setTeamViceCaptain(null)
      }

      setFantasyTeamObj(Object.keys(fantasyTeamObj).reduce((acc, key) => {
         if (key === player.playerId.toString()) {
            acc[key] = {
               ...player, isCaptain: true, isVcCaptain: false
            }
         } else {
            acc[key] = {
               ...fantasyTeamObj[key], isCaptain: false
            }
         }
         return acc;
      }, {[player.playerId]: {...player}}));
   }

   const handleMakeViceCaptain = (player) => {
      setTeamViceCaptain(player)

      // if player is already captain, remove captain
      if (player.playerId === teamCaptain?.playerId) {
         setTeamCaptain(null)
      }

      setFantasyTeamObj(Object.keys(fantasyTeamObj).reduce((acc, key) => {
         if (key === player.playerId.toString()) {
            acc[key] = {
               ...player, isVcCaptain: true, isCaptain: false
            }
         } else {
            acc[key] = {
               ...fantasyTeamObj[key], isVcCaptain: false
            }
         }
         return acc;
      }, {[player.playerId]: {...player}}));
   }

   const handleMakeOther = (player) => {
      setFantasyTeamObj(Object.keys(fantasyTeamObj).reduce((acc, key) => {
         if (key === player.playerId.toString()) {
            acc[key] = {
               ...player, isCaptain: false, isVcCaptain: false
            }
         } else {
            acc[key] = {
               ...fantasyTeamObj[key]
            }
         }
         return acc;
      }, {[player.playerId]: {...player}}));

      if (player.playerId === teamCaptain?.playerId) {
         setTeamCaptain(null)
      } else if (player.playerId === teamViceCaptain?.playerId) {
         setTeamViceCaptain(null)
      }
   }

   const handleContinue = () => {
      // check team name
      if (!fantasyTeamName) {
         setIsErrorInTeamName(true);
         toast.warning(t("FANTASY.PLEASE_ENTER_TEAM_NAME"));
         return;
      }

      // check captain
      // if (!teamCaptain) {
      //    toast.warning(t("FANTASY.PLEASE_SELECT_THE_CAPTAIN"));
      //    return;
      // }

      // check vice captain
      // if (selectedPlayerCount > 1 && !teamViceCaptain) {
      //    toast.warning(t("FANTASY.PLEASE_SELECT_THE_VICE_CAPTAIN"));
      //    return;
      // }

      setShowTeamConfirmationModal(true)
   }

   const navigateToTournament = () => {
      if (state?.returnPath) {
         navigate(state.returnPath)
      } else if (state?.tournamentDetails) {
         const tournamentId = state?.tournamentDetails?.id;
         const tournamentType = state?.tournamentDetails?.tournamentType;

         if (tournamentType === TOURNAMENT_TYPES.KNOCKOUT) {
            navigateWithReplace(`/knockout/${tournamentId}`)
         } else if (tournamentType === TOURNAMENT_TYPES.LEAGUE) {
            navigateWithReplace(`/league/${tournamentId}`)
         } else if (tournamentType === TOURNAMENT_TYPES.LEAGUE_KNOCKOUT) {
            navigateWithReplace(`/league-knockout/${tournamentId}`)
         }
      } else {
         navigateWithReplace(`/tournaments`)
      }
   }

   const handleSkipFantasy = () => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
         'event': 'fantasy_skip',
         'type': 'fantasy',
         'timeStamp': Date.now()
      });

      navigateToTournament();
   }

   const handleConfirmationWithEdit = async () => {
      toast.loading(`Your Fantasy Team is getting Created..`, {toastId: TEAM_CREATING_TOAST_ID});

      setIsTeamSubmitting(true);

      // post fantasy team
      const team = Object.values(fantasyTeamObj).map(player => {
         return {
            playerId: player.playerId,
            playerName: player.playerName,
            playerPictureURL: player.playerPictureURL,
            playerRole: player.playerRole,
            isCaptain: player.isCaptain,
            isVcCaptain: player.isVcCaptain
         }
      });

      // check if the state?.tournamentDetails?.startTime is in the past
      if (isEditing && state?.tournamentDetails?.startTime && new Date(state?.tournamentDetails?.startTime) < new Date()) {
         toast.update(TEAM_CREATING_TOAST_ID, {
            render: t("FANTASY.YOU_CANT_EDIT_THE_TEAM_BECAUSE_THE_TOURNAMENT_HAS_ALREADY_STARTED"),
            type: toast.TYPE.ERROR,
            autoClose: 5000,
            isLoading: false
         })
         setIsTeamSubmitting(false);
         return;
      }

      console.log("fantasy team", props.userName, tournamentId, fantasyTeamName, team)
      postUserFantasyTeam(props.userName, tournamentId, fantasyTeamName, team, props.Token)
         .then((response) => {
            if (response.apiStatus) {
               toast.update(TEAM_CREATING_TOAST_ID, {
                  render: t("FANTASY.FANTASY_TEAM_CREATED_SUCCESSFULLY"),
                  type: toast.TYPE.SUCCESS,
                  autoClose: 5000,
                  isLoading: false
               })

               window.dataLayer = window.dataLayer || [];
               window.dataLayer.push({
                  'event': 'fantasy_created',
                  'type': 'fantasy',
                  'timeStamp': Date.now()
               });

               setShowTeamConfirmationModal(false)
               navigateToTournament();
            } else {
               toast.update(TEAM_CREATING_TOAST_ID, {
                  render: response?.rejectMessages?.length > 0 ? response.rejectMessages[0] : t("FANTASY.FAILED_TO_CREATE_FANTASY_TEAM"),
                  type: toast.TYPE.ERROR,
                  autoClose: 5000,
                  isLoading: false
               })
            }

         })
         .catch((error) => {
            console.error("error", error)
            toast.error(t("FANTASY.FAILED_TO_CREATE_FANTASY_TEAM"));
         })
         .finally(() => {
            setIsTeamSubmitting(false);
         });
   }

   const handleShowPlayerInfo = (playerId) => {
      setSelectedPlayerId(playerId);
      setShowPlayerInfoModal(true);
   }

   const handleSearch = (e) => {
      setFilterText(e.target.value);
   }

   const handleScrollToFantasyTeam = () => {
      teamSectionRef.current.scrollIntoView({behavior: "smooth"});
   }

   const handleScrollToAllPlayers = () => {
      allPlayersSectionRef.current.scrollIntoView({behavior: "smooth"});
   }

   useEffect(() => {
      if (!props.isAuthenticated || !props.userName) {
         sessionStorage.setItem('redirectPath', window.location.pathname)
         navigateWithReplace(`/login`)
      }
   }, [props.isAuthenticated, props.userName]);


   // check if tournament details are available
   useEffect(() => {
      if (!state?.tournamentDetails) {
         navigateWithReplace(`/tournaments`)
      }
   }, [state?.tournamentDetails]);

   // get all players of the tournament
   useEffect(() => {
      const fetchAllTournamentPlayers = async () => {
         getAllPlayersByTournamentId(tournamentId)
            .then((response) => {
               if (response.apiStatus && response.players) {
                  setAllTournamentPlayers(response.players);
                  setErrorLoadingPlayers(false);
               } else {
                  setErrorLoadingPlayers(true)
               }
            })
            .finally(() => setIsPlayersLoading(false));
      }

      fetchAllTournamentPlayers();
   }, [tournamentId]);

   // get fantasy team of the user
   useEffect(() => {
      const fetchFantasyTeam = async () => {
         getFantasyTeamByUsernameAndTournamentId(props.userName, tournamentId, props.Token)
            .then((response) => {
               if (response.apiStatus && response.team && response?.team?.length > 0) {
                  setIsEditing(true);
                  const teamObj = response?.team.reduce((acc, player) => {
                     acc[player.playerId] = {...player, playerRole: player.playerRole};

                     // set captain and vice captain
                     if (player.isCaptain) {
                        setTeamCaptain(player);
                     } else if (player.isVcCaptain) {
                        setTeamViceCaptain(player);
                     }

                     return acc;
                  }, {});
                  if (response.teamName) {
                     setFantasyTeamName(response.teamName)
                  }
                  setFantasyTeamObj(teamObj);
               }
            })
            .finally(() => setIsTeamLoading(false));
      }

      fetchFantasyTeam();
   }, [props.Token, props.userName, tournamentId]);

   // filter players based on search text
   useEffect(() => {
      // filter players with search text and sort added players last
      const filteredPlayers = allTournamentPlayers.filter(player => {
         return player.playerName.toLowerCase().includes(filterText.toLowerCase())
      }).sort((a, b) => {
         if (fantasyTeamObj[a.playerId] && !fantasyTeamObj[b.playerId]) {
            return 1;
         } else if (!fantasyTeamObj[a.playerId] && fantasyTeamObj[b.playerId]) {
            return -1;
         } else {
            return 0;
         }
      });

      setFilteredPlayers(filteredPlayers);
   }, [allTournamentPlayers, fantasyTeamObj, filterText])

   // update player count and captain/vice captain
   useEffect(() => {
      setSelectedPlayerCount(Object.keys(fantasyTeamObj).length);
   }, [fantasyTeamObj])

   // update screen size
   useEffect(() => {
      const handleResize = () => {
         setScreenSize(window.innerWidth);
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
   }, []);

   // get random placeholder images
   useEffect(() => {
      setPlaceHolderImage(Array(MAX_PLAYER_COUNT).fill(0).map(() => getRandomPlaceHolderImage()));
   }, [MAX_PLAYER_COUNT]);

   const loadingIndicator = (<div style={{textAlign: "center"}}>
      <div className="lds-facebook">
         <div></div>
         <div></div>
         <div></div>
      </div>
   </div>);

   // player count indicator
   const playerCountIndicator = (isCountShow) => (<div className="row">
      <div className={classNames(styles.CounterWrapper, "col-md-12 CounterWrapper")}>
         <div className={classNames(styles.TeamPlayerCounter, "TeamPlayerCounter")}>
            {[...Array(MAX_PLAYER_COUNT)].map((_, index) => {
               if (index < selectedPlayerCount) {
                  return <div key={index} className={classNames(styles.Filled, "Filled")}></div>
               } else {
                  return <div key={index} className={classNames(styles.Empty, "Empty")}></div>
               }
            })}
         </div>
         <div>{isCountShow && <>{selectedPlayerCount}/{MAX_PLAYER_COUNT} Selected</>}</div>
      </div>
   </div>)

   // empty card for captain slot
   const emptyCardCaptain = (<div className={classNames(styles.Card)}>
      <div className={classNames(styles.CardBackground)}>
         <div className="row">
            <div className={classNames(styles.TrashIcon)}></div>
            <div className={classNames(styles.ProfilePic, styles.EmptyProfilePicBorder)}>
               <div className={classNames(styles.ProfilePic, styles.EmptyProfilePic)}
                    style={{backgroundImage: `url(${placeHolderImage[MAX_PLAYER_COUNT - 1]})`}}>
               </div>
            </div>
         </div>
         <div className={classNames(styles.TitleRow, "row")}>
            <h4>{t("FANTASY.PLAYER_NAME")}</h4>
            <span>{t("FANTASY.CAPTAIN")}</span>
         </div>
         <div className={classNames(styles.BtnRow, "row")}>
            <button disabled>{t("FANTASY.MAKE_V_CAPTAIN")}</button>
            <button disabled>{t("FANTASY.MAKE_OTHER")}</button>
         </div>
      </div>
   </div>)

   // empty card for vice captain slot
   const emptyCardViceCaptain = (<div className={classNames(styles.Card)}>
      <div className={classNames(styles.CardBackground)}>
         <div className="row">
            <div className={classNames(styles.TrashIcon)}></div>
            <div className={classNames(styles.ProfilePic, styles.EmptyProfilePicBorder)}>
               <div className={classNames(styles.ProfilePic, styles.EmptyProfilePic)}
                    style={{backgroundImage: `url(${placeHolderImage[MAX_PLAYER_COUNT - 2]})`}}>
               </div>
            </div>
         </div>
         <div className={classNames(styles.TitleRow, "row")}>
            <h4>{t("FANTASY.PLAYER_NAME")}</h4>
            <span>{t("FANTASY.VICE_CAPTAIN")}</span>
         </div>
         <div className={classNames(styles.BtnRow, "row")}>
            <button disabled>{t("FANTASY.MAKE_CAPTAIN")}</button>
            <button disabled>{t("FANTASY.MAKE_OTHER")}</button>
         </div>
      </div>
   </div>)

   // empty card for other players slot
   const emptyCardOther = (index) => (<div key={index} className={classNames(styles.Card)}>
      <div className={classNames(styles.CardBackground)}>
         <div className="row">
            <div className={classNames(styles.TrashIcon)}></div>
            <div className={classNames(styles.ProfilePic, styles.EmptyProfilePicBorder)}>
               <div className={classNames(styles.ProfilePic, styles.EmptyProfilePic)}
                    style={{backgroundImage: `url(${placeHolderImage[selectedPlayerCount - (teamCaptain ? 1 : 0) - (teamViceCaptain ? 1 : 0) + index]})`}}>
               </div>
            </div>
         </div>
         <div className={classNames(styles.TitleRow, "row")}>
            <h4>{t("FANTASY.PLAYER_NAME")}</h4>
         </div>
         <div className={classNames(styles.BtnRow, "row")}>
            <button disabled>{t("FANTASY.MAKE_CAPTAIN")}</button>
            <button disabled>{t("FANTASY.MAKE_V_CAPTAIN")}</button>
         </div>
      </div>
   </div>)

   // sort selected players based on the addedOrder property
   const getSortedSelectedPlayers = () => {
      const playersArray = Object.values(fantasyTeamObj);
      return playersArray.sort((a, b) => a.addedOrder - b.addedOrder);
   }

   return (<React.Fragment>
      <div className="Radial1"></div>
      <div className={classNames(styles.lightBolt)}></div>
      <div className={classNames(styles.ellipse50)}></div>

      <div className={classNames(styles.FantasyTeamPage, "pb-4")}>
         <div className="container p-0">
            <div className="col-12 row">
               <div
                  className={classNames(styles.ResponceCont, "col-12", "row", "ps-2", "pe-2", "ps-xxl-0", "pe-xxl-0")}>
                  <div className="col-12  p-0">
                     <NavbarSecondary page="matches"/>
                  </div>
                  <div className={classNames(styles.matchCardContainer, "col-12 p-0")}>
                     <div className={classNames(styles.contentWrapper, "row gx-0 mt40")}>
                        <div className={classNames(styles.container, "container")}>
                           <div className="col p-0">
                              <h1 className="gradient-color">{t("FANTASY.CREATE_FANTASY_TEAM")}</h1>
                           </div>
                           <div id="FantasyTeamSelector"
                                className={classNames(styles.FantasyTeamSelector, "FantasyTeamSelector row")}>
                              {screenSize <= 768 && playerCountIndicator(true)}
                              <div ref={allPlayersSectionRef} className={classNames(styles.PlayerListCol)}>
                                 <div className="row">
                                    <div className="col-md-12">
                                       <div className={classNames(styles.SearchInputWrapper)}>
                                          <input placeholder={t("FANTASY.SEARCH_ATHLETE")} type="text"
                                                 onChange={handleSearch}/>
                                          <img src={SearchIcon}/>
                                          <a className={classNames(styles.ShowTeamBtn)}
                                             onClick={handleScrollToFantasyTeam}>{t("FANTASY.SHOW_TEAM")}</a>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="row">
                                    <div className={classNames(styles.PlayerListWrapper, "col-md-12")}>
                                       {/* left col start */}
                                       <div className={classNames(styles.PlayerList)}>
                                          {/* Player list start */}
                                          {!isPlayersLoading && errorLoadingPlayers && <div>
                                             {t("FANTASY.ERROR_LOADING_PLAYERS_TRY_REFRESHING_THE_PAGE")}
                                          </div>}
                                          {isPlayersLoading ? loadingIndicator : filteredPlayers.map(player => {
                                             return (<div className={classNames(styles.Player)}>
                                                <div className={classNames(styles.Wrapper)}>
                                                   <div className={classNames(styles.PlayerProfilePic)}>
                                                      <div className={classNames(styles.PlayerLogo)} style={{backgroundImage: `url(${player.teamPictureURL})`}}>
                                                         {/* <img src={player.teamPictureURL}/> */}
                                                      </div>
                                                      <img src={player?.playerPictureURL}/>
                                                   </div>
                                                   <div className={classNames(styles.ProfileInfo)}>
                                                      <div className={classNames(styles.PlayerNameRow)}>
                                                         <h4>{player.playerName}&nbsp;</h4>
                                                         <a onClick={() => handleShowPlayerInfo(player.playerId)}><img
                                                            style={{cursor: 'pointer'}} src={MoreInfoIcon}/></a>
                                                         {fantasyTeamObj[player.playerId] ?
                                                            <button className={classNames(styles.AddBtnWrapper)}
                                                                    onClick={() => handleRemovePlayer(player)}>
                                                               {t("FANTASY.REMOVE")}
                                                            </button> :
                                                            <button className={classNames(styles.AddBtnWrapper)}
                                                                    onClick={() => handleAddPlayer(player, PLAYER_ROLE.PLAYER)}>
                                                               {t("FANTASY.ADD")}
                                                            </button>}
                                                      </div>
                                                      <div className={classNames(styles.OtherInfo)}>
                                                         <ul>
                                                            <li>
                                                               <label>{t("FANTASY.TEAM")} : </label><span>{player.teamName}</span>
                                                            </li>
                                                            <li>
                                                               <label>{t("FANTASY.BATTING")} : </label><span>{playerRoleStyleController(player.battingStyle, pageLang)}</span>
                                                            </li>
                                                            <li><label>{t("FANTASY.BOWLING")} : </label>
                                                               <span>{playerRoleStyleController(player.bowlingStyle, pageLang)}</span>
                                                            </li>
                                                            <li>
                                                               <label>{t("FANTASY.HITTING_POWER")} : </label><span>{getPlayerHittingRank(player.maxBattingPower, pageLang)}</span>
                                                            </li>
                                                            <li>
                                                               <div
                                                                  className={classNames(styles.progressBarWrapper, "progressBarWrapper")}>
                                                                           <span
                                                                              className={classNames(styles.ProgressGroove, "progressGroove")}>
                                                                              <span
                                                                                 className={classNames(styles.ProgresStick, "progresStick")}
                                                                                 style={{width: `${player?.maxBattingPower}%`}}>
                                                                                 <span
                                                                                    className={classNames(styles.ProgressDot, "progressDot")}></span>
                                                                              </span>
                                                                           </span>
                                                               </div>
                                                            </li>
                                                         </ul>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>)
                                          })}
                                          {/* Player list end */}

                                       </div>
                                       {/* left col end */}
                                    </div>
                                 </div>
                              </div>

                              {/* Right Col start */}
                              <div ref={teamSectionRef} id="SelectedPlayersCol"
                                   className={classNames(styles.SelectedPlayersCol)}>

                                 {isTeamLoading ? loadingIndicator : <>
                                    <div className="row">
                                       <div className="col-md-12">
                                          <div className={classNames(styles.CreateTeamNameWrapper)}>
                                             <div className={classNames(styles.CreateTeamName)}
                                                  style={isErrorInTeamName ? {borderColor: '#FF002E'} : {}}>
                                                <input placeholder={t("FANTASY.TEAM_NAME")} type="text"
                                                       value={fantasyTeamName}
                                                       onChange={handleTeamNameChange}/>
                                                <img src={EditIcon}/>
                                             </div>
                                             <a className={classNames(styles.SearchAthlete)}
                                                style={{cursor: 'pointer'}}
                                                onClick={handleScrollToAllPlayers}>{t("FANTASY.SEARCH_ATHLETE")}</a>
                                          </div>
                                       </div>
                                    </div>
                                    <div className={classNames(styles.TeamCaptains, "row")}>
                                       <div className="col-md-12">
                                          <div className={classNames(styles.CaptainRow)}>
                                             {(teamCaptain || teamViceCaptain) ? <>
                                                {teamCaptain ? <div className={classNames(styles.Card)}>
                                                   <div className={classNames(styles.CardBackground)}>
                                                      <div className="row">
                                                         <div className={classNames(styles.TrashIcon)}
                                                              onClick={() => handleRemovePlayer(teamCaptain)}
                                                              style={{cursor: 'pointer'}}>
                                                            <img src={TrashIcon}/></div>
                                                         <div className={classNames(styles.ProfilePic)}
                                                              style={{backgroundImage: `url(${teamCaptain?.playerPictureURL})`}}></div>
                                                      </div>
                                                      <div className={classNames(styles.TitleRow, "row")}>
                                                         <div className={classNames(styles.PlayerNameWrapper)}>
                                                            <h4>{teamCaptain?.playerName}{' '}</h4>
                                                            <a onClick={() => handleShowPlayerInfo(teamCaptain.playerId)}><img
                                                               src={MoreInfoIcon} style={{cursor: 'pointer'}}/></a>
                                                         </div>
                                                         <span>{t("FANTASY.CAPTAIN")}</span>
                                                      </div>
                                                      <div className={classNames(styles.BtnRow, "row")}>
                                                         <button onClick={() => handleMakeViceCaptain(teamCaptain)}>
                                                            {t("FANTASY.MAKE_V_CAPTAIN")}
                                                         </button>
                                                         <button onClick={() => handleMakeOther(teamCaptain)}>
                                                            {t("FANTASY.MAKE_OTHER")}
                                                         </button>
                                                      </div>
                                                   </div>
                                                </div> : emptyCardCaptain}

                                                {teamViceCaptain ? <div className={classNames(styles.Card)}>
                                                   <div className={classNames(styles.CardBackground)}>
                                                      <div className="row">
                                                         <div className={classNames(styles.TrashIcon)}
                                                              onClick={() => handleRemovePlayer(teamViceCaptain)}
                                                              style={{cursor: 'pointer'}}>
                                                            <img src={TrashIcon}/></div>
                                                         <div className={classNames(styles.ProfilePic)}
                                                              style={{backgroundImage: `url(${teamViceCaptain?.playerPictureURL})`}}></div>
                                                      </div>
                                                      <div className={classNames(styles.TitleRow, "row")}>
                                                         <div className={classNames(styles.PlayerNameWrapper)}>
                                                            <h4>{teamViceCaptain?.playerName}{' '}</h4>
                                                            <a onClick={() => handleShowPlayerInfo(teamViceCaptain.playerId)}><img
                                                               src={MoreInfoIcon} style={{cursor: 'pointer'}}/></a>
                                                         </div>

                                                         <span>{t("FANTASY.VICE_CAPTAIN")}</span>
                                                      </div>
                                                      <div className={classNames(styles.BtnRow, "row")}>
                                                         <button onClick={() => handleMakeCaptain(teamViceCaptain)}>
                                                            {t("FANTASY.MAKE_CAPTAIN")}
                                                         </button>
                                                         <button onClick={() => handleMakeOther(teamViceCaptain)}>
                                                            {t("FANTASY.MAKE_OTHER")}
                                                         </button>
                                                      </div>
                                                   </div>
                                                </div> : emptyCardViceCaptain}
                                             </> : <>{emptyCardCaptain}{emptyCardViceCaptain}</>}
                                          </div>
                                       </div>
                                    </div>

                                    <div className={classNames(styles.OtherPlayers, "row")}>
                                       <div className="col-md-12">
                                          <div className={classNames(styles.Wrapper)}>
                                             {getSortedSelectedPlayers().map(player => {
                                                if (!player.isCaptain && !player.isVcCaptain) {
                                                   return (<div className={classNames(styles.Card)}>
                                                      <div className={classNames(styles.CardBackground)}>
                                                         <div className="row">
                                                            <div className={classNames(styles.TrashIcon)}
                                                                 onClick={() => handleRemovePlayer(player)}
                                                                 style={{cursor: 'pointer'}}>
                                                               <img src={TrashIcon}/></div>
                                                            <div className={classNames(styles.ProfilePic)}
                                                                 style={{backgroundImage: `url(${player?.playerPictureURL})`}}></div>
                                                         </div>
                                                         <div className={classNames(styles.TitleRow, "row")}>
                                                            <div className={classNames(styles.PlayerNameWrapper)}>
                                                               <h4>{player.playerName}{' '} </h4>
                                                               <a onClick={() => handleShowPlayerInfo(player.playerId)}>
                                                                  <img style={{cursor: 'pointer'}}
                                                                       src={MoreInfoIcon}/>
                                                               </a>
                                                            </div>
                                                         </div>
                                                         <div className={classNames(styles.BtnRow, "row")}>
                                                            <button onClick={() => handleMakeCaptain(player)}>
                                                               {t("FANTASY.MAKE_CAPTAIN")}
                                                            </button>
                                                            <button onClick={() => handleMakeViceCaptain(player)}>
                                                               {t("FANTASY.MAKE_V_CAPTAIN")}
                                                            </button>
                                                         </div>
                                                      </div>
                                                   </div>)
                                                } else {
                                                   return null;
                                                }
                                             })}

                                             {/*   Show empty cards*/}
                                             {MAX_PLAYER_COUNT - 2 >= Object.values(fantasyTeamObj).filter(player => !player.isCaptain && !player.isVcCaptain).length && [...Array(MAX_PLAYER_COUNT - 2 - Object.values(fantasyTeamObj).filter(player => !player.isCaptain && !player.isVcCaptain).length)]
                                                .map((_, index) => {
                                                   return emptyCardOther(index)
                                                })}
                                          </div>
                                       </div>
                                    </div>
                                    <div className={classNames(styles.CounterRow)}>
                                       {playerCountIndicator(false)}
                                    </div>
                                    <div className={classNames(styles.FooterBtnRow, "row")}>
                                       <div className="col-md-12">
                                          <div className={classNames(styles.BtnRow)}>
                                             <div className={classNames(styles.Col2)}>
                                                <button className={classNames(styles.SkipFantasyBtn)}
                                                        onClick={handleSkipFantasy}>
                                                   <div>{t("FANTASY.REVIEW_SCHEDULE")}</div>
                                                </button>
                                                <button className={classNames(styles.ContinueBtn)}
                                                        disabled={selectedPlayerCount < 1}
                                                        style={{
                                                           cursor: selectedPlayerCount < 1 ? "not-allowed" : "pointer",
                                                           opacity: selectedPlayerCount < 1 ? 0.5 : 1
                                                        }}
                                                        onClick={handleContinue}>
                                                   <div>{t("FANTASY.CONTINUE")}</div>
                                                </button>
                                             </div>
                                             <div className={classNames(styles.Col1)}>
                                                <button className={classNames(styles.HowFantasyWorksBtn)}
                                                        onClick={() => {
                                                           setShowHowFantasyWorksModal(true)
                                                        }}>
                                                   <div>{t("FANTASY.HOW_FANTASY_WORKS")}</div>
                                                </button>
                                             </div>

                                          </div>
                                       </div>
                                    </div>
                                 </>}
                              </div>
                              {/* Right col end	 */}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="mt-2">
            <Footer/>
         </div>
      </div>

      {showHowFantasyWorksModal && <HowFantasyWorksModal
         handleModalClose={() => {
            setShowHowFantasyWorksModal(false);
         }}
         handleShowHowFantasyPoints={() => {
            setShowFantasyPointCalculationModal(true);
         }}/>}
      {showFantasyPointCalculationModal && <FantasyPointCalculation handleModalClose={() => {
         setShowFantasyPointCalculationModal(false);
      }}/>}

      {showPlayerInfoModal && <PlayerInfoModal playerId={selectedPlayerId}
                                               allTournamentPlayers={allTournamentPlayers}
                                               handleModalClose={() => {
                                                  setShowPlayerInfoModal(false)
                                               }}/>}

      {showTeamConfirmationModal && <FantasyTeamConfirmation
         isSubmitting={isTeamSubmitting}
         captain={teamCaptain}
         viceCaptain={teamViceCaptain}
         handleConfirmation={handleConfirmationWithEdit}
         openSecondModal={() => {
            setShowFantasyPointCalculationModal(true);
            setShowTeamConfirmationModal(false);
         }}
         handleModalClose={() => {
            setShowTeamConfirmationModal(false)
         }}/>}

   </React.Fragment>);
};

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken != null || undefined,
      userId: state.auth.userId,
      userName: state.auth.userName,
      email: state.auth.email,
      Token: state.auth.accessToken,
      refresh: state.auth.refreshToken
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      onLogout: () => dispatch(actions.logout()),
      onRefreshAuth: (accessToken, refreshToken) => dispatch(actions.refreshSession(accessToken, refreshToken)),
   };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateFantasyTeam);
