import React, {useEffect, useState} from 'react';
import {IoCloseOutline} from "react-icons/io5";
import styles from "./GuidanceTip.module.scss";
import classNames from "classnames";
import {guideObj} from "../../utilities/CommonMessages";
import {getConf} from "../../services/ConfigService";
import {GUIDANCE_TIP} from "../../utilities/ConfigConstants";

const GuidanceTip = ({
                        page,
                        gotItIndex,
                        gotItHandler,
                        nextGotItNum,
                        tipCancelHandler,
                        tipIndexCounter,
                        setTipIndexCounter,
                        screenSize
                     }) => {
   console.log(guideObj?.[page]?.[nextGotItNum]?.position[screenSize]?.[0]);
   const [currentContentId, setCurrentContentId] = useState(0);
   const contentLength = guideObj?.[page]?.[nextGotItNum]?.contents.length;
   let guidanceTipConfig = getConf(GUIDANCE_TIP.GUIDANCE_TIP_SHOW)

   const contentIdIterator = () => {
      setCurrentContentId(currentContentId + 1);
      setTipIndexCounter(tipIndexCounter + 1)
   }

   const tipStopIndex = {
      "home": 4,
      "matches": 4,
      "match": 12,
      "matchups": 4,
      "playerProfile": 4,
      "userProfile": 6,
      "leaderboard": 6
   };

   useEffect(() => {
      if (tipStopIndex[page] === tipIndexCounter) {
         sessionStorage.setItem(page, true)
      }
   }, [tipIndexCounter]);

   const forceGuidanceTipOff = () => {
      tipCancelHandler();
      sessionStorage.setItem(page, true)
   }

   const pageWholeTipCount = guideObj?.[page]?.reduce((acc, curr) => acc + curr['contents'].length, 0);

   const isPreviouslyNotLogged = localStorage.getItem("previouslyLogged") === "false";

   return ((nextGotItNum !== -1) && isPreviouslyNotLogged && !(sessionStorage.getItem(page)) && guidanceTipConfig ?
         <div className={guideObj?.[page]?.[nextGotItNum]?.toolTipPosition}>
            <div className={classNames(styles.notificationWrapper, "blue-notification-wrapper")} style={{
               top: guideObj?.[page]?.[nextGotItNum]?.position[screenSize]?.[0],
               right: guideObj?.[page]?.[nextGotItNum]?.position[screenSize]?.[1],
               bottom: guideObj?.[page]?.[nextGotItNum]?.position[screenSize]?.[2],
               left: guideObj?.[page]?.[nextGotItNum]?.position[screenSize]?.[3],
               zIndex: 5,
               display: ((nextGotItNum !== gotItIndex) || (nextGotItNum === -1)) && "none"
            }}>
               <div className={classNames(styles.contentWrapper)}>
                  <div className={guideObj?.[page]?.[nextGotItNum]?.arrowStyle}></div>
                  <div className={`${guideObj?.[page]?.[nextGotItNum]?.arrowStyle}Outter`}></div>
                  <div className={classNames(styles.titleText, "blue-notification-header body-text1 color-white")}>
                     {guideObj?.[page]?.[nextGotItNum]?.title}
                     <button className='closeIco  color-white' style={{float: "right", backgroundColor: "transparent", border: "none", outline: "none", fontSize: "25px"}} onClick={forceGuidanceTipOff}>
                        <IoCloseOutline/>
                     </button>
                  </div>
                  <div className="blue-notification-content body-text3">
                     {guideObj?.[page]?.[nextGotItNum]?.contents[currentContentId]}
                  </div>
                  <div className={classNames(styles.blueNotificationFooter, "blue-notification-footer")}>
                     {(contentLength - 1 === currentContentId) ?
                        <button className={classNames(styles.gradientGreenBtnNotification)}
                                onClick={gotItHandler}>{(tipIndexCounter === pageWholeTipCount) ? "FINISH" : "NEXT"}</button> :  //previous GOT IT button
                        <button className={classNames(styles.gradientGreenBtnNotification)}
                                onClick={contentIdIterator}>NEXT</button>}
                     <label className={classNames(styles.tipCount)}>
                        <span className="val01">{tipIndexCounter}</span> of <span
                        className="val02">{pageWholeTipCount}</span>
                     </label>
                  </div>
               </div>
            </div>
         </div> : <></>
   );
};

export default GuidanceTip;
