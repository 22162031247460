import axios from "axios";
import {ApiEndpoints} from "../services/ApiConstants";

export default () => {
    const axiosInstance = axios.create({
        baseURL: ApiEndpoints.BASE_URL,
    });

    //can declare interceptors
    axiosInstance.interceptors.request.use(
        (request) => {
            if (request.url === ApiEndpoints.BASE_URL + ApiEndpoints.SOCIAL_LOGIN) {
                request.headers = {
                    "Content-Type": "application/json",
                };
            }
            return request;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};
