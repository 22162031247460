import {ApiEndpoints} from "./ApiConstants";
import AxiosInstance from "../helpers/AxiosInstance";


export const getScheduledMatch = (matchId,lang) => {
    const url = `${ApiEndpoints.GET_SCHEDULED_MATCH}/${matchId}?lang=${lang}`
    return AxiosInstance()
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response;
        });
};
export const getNextMatch = (matchId) => {
    const url = `${ApiEndpoints.GET_SCHEDULED_MATCH}/match/next?matchId=${matchId}`
    return AxiosInstance()
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response;
        });
};
