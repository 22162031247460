import React, {useEffect, useState} from "react";
import Classnames from "classnames";
import Styles from './VerifyCode.module.css'
import * as UserService from "../../services/UserService"
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import Timeout from "../../components/Timeouts/Timeout";
import {toast} from "react-toastify";
import {AUTHENTICATION_TOASTS} from "../../utilities/ToastMessages";
import {Puff} from "react-loading-icons";
import {useTranslation} from "react-i18next";

const VerifyCode = (props) => {
   let navigate = useNavigate();
   const [code, setCode] = useState("");
   const [codeError, setCodeError] = useState(true)
   const [codeErrorCode, setCodeErrorCode] = useState("")
   const inputRefs = []; // Array to store references to each input field
   const [timerStart, setTimerStart] = useState(false)
   const [showTimer, setShowTimer] = useState(false)
   const [isSignUpInProgress, setIsSignUpInProgress] = useState(false)
   const [lang, setLang] = useState(localStorage.getItem("language"));

   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   const loadingIndicator = (
       <div className="w-100 text-center">
          <div style={{
             position: 'fixed',
             top: '50%',
             left: '50%',
             transform: 'translate(-50%, -50%)'
          }}>
             <div className="lds-facebook">
                <div></div>
                <div></div>
                <div></div>
             </div>
          </div>
       </div>);

   // Function to handle input change
   const handleInputChange = (e, index) => {

      e.target.value = e.target.value.replace(/[^0-9]/g, '');

      const inputLength = e.target.value.length;

      // Move focus to next input field when a digit is entered and input length is 1
      if (inputLength === 1 && index < inputRefs.length - 1) {
         inputRefs[index + 1].focus();
      }
      const updatedNumber = inputRefs.map((input) => input.value).join('');
      setCode(updatedNumber);
   };


   useEffect(() => {
      setCodeErrorCode("")
      let codeValid;
      if (code) {
         codeValid = code.length === 6;
         setCodeError(!codeValid)
      }
   }, [code])
   useEffect(() => {
      if (codeErrorCode == "") {
         verifyUserEmail()
      }

   }, [])
   const verifyUserEmail = () => {
      const EmailResponse = UserService.verifyEmail(props.user?.firstName, {
         "attribute": props.email

      })
      EmailResponse.then((response) => {
         if (response.apiStatus === true) {
         } else {
            setCode("")
         }
      }).catch(e => {
         toast.error(AUTHENTICATION_TOASTS.ERROR_OCCURRED_SENDING_CODE)
      })

   }


   const createNewUser = async () => {
      setIsSignUpInProgress(true)
      UserService.createNewUser({
         ...props.user,
         "userName": props.email,
         "password": props.password,
         "verificationCode": code,
         "email": props.email.toLowerCase()
      }).then(
         (response) => {
            if (response?.data?.apiStatus == false && response?.data?.loginStatus == false) {
               if (lang == "hi"){
                  if (response?.data?.message == "INVALID_EMAIL_VERIFICATION_CODE") {
                     setCodeErrorCode("अमान्य ईमेल सत्यापन कोड")
                  } else if (response?.data?.message == "FAILED_USER_CREATE") {
                     setCodeErrorCode("उपयोगकर्ता बनाने में विफल");
                  }
               } else {
                  if (response?.data?.message == "INVALID_EMAIL_VERIFICATION_CODE") {
                     setCodeErrorCode("Invalid Email Verification Code")
                  } else if (response?.data?.message == "FAILED_USER_CREATE") {
                     setCodeErrorCode("Failed to create the user");
                  }
               }
            } else if (response?.apiStatus == true && response?.loginStatus == true) {
               props.onAuth(response.accessToken, response.refreshToken, "email", props.user.profilePicURL, response.userName, (props.user.firstName + " " + props.user.lastName), props.email)
               localStorage.setItem("previouslyLogged", false);

               window.dataLayer.push({
                  'event': 'signup',
                  'type': 'email',
                  'timeStamp': Date.now()
               });

               navigate(`/create-account-success`)

            }
            setIsSignUpInProgress(false)
         }
      ).catch((err) => {
         setIsSignUpInProgress(false)
      })
   }
   const timerResetter = (input) => {
      setShowTimer(input)
   }
   const closeModal = () => {
      props.onCloseModal(false);
   }

   if (!translationsLoaded) {
      return loadingIndicator;
   }

   return (
      <div className={Classnames("modal fade show", Styles.modalBlur)} id="exampleModalCenter" tabIndex="-1"
           role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{display: "block"}}>
         <div className={Classnames("modal-dialog-centered", Styles.ModalDialog)} role="document">
            <div className={Classnames(Styles.ModalContent, "modal-content")}>
               {/*Modal-1 Started*/}


               <div className={Classnames(Styles.ModalCont)}>
                  <div className="p-4 row">
                     <div style={{textAlign: "right", padding: "0px"}}>
                        <button className={Classnames(Styles.closeButton, "close p-0")} type="button" onClick={closeModal} data-dismiss="modal" aria-label="Close" style={{backgroundColor: "#03161f", border: "0px"}}>
                           <span className={Styles.closeButton} aria-hidden="true"><img src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/></span>
                        </button>

                     </div>
                     <div className={Classnames(Styles.ModalTitle, "text-center")}>{t("VERIFY_CODE.LETS_SECURE")}<br/> {t("VERIFY_CODE.YOUR_ACCOUNT")}
                     </div>
                     <div className={Classnames(Styles.ModalSmallText, "pt-3", "text-center")}>
                        {t("VERIFY_CODE.WE_SENT_THE_CODE_TO_THE_EMAIL_ADDRESS_PROVIDED")} <br/>
                        {t("VERIFY_CODE.PLEASE_ENTER_THE_6_DIGIT_CODE_IN_THAT_EMAIL")}
                     </div>
                     <div className="mt-3 ms-auto me-auto text-center p-0 col-10 col-md-12">
                        {Array.from({length: 6}, (_, i) => (
                           <input
                              key={i}
                              type=""
                              name="name"
                              placeholder=""
                              size="1"
                              autoComplete="off"
                              maxLength={1}
                              className={Classnames(Styles.NumIn)}
                              ref={(input) => (inputRefs[i] = input)} // Create a reference to the input field
                              onChange={(e) => handleInputChange(e, i)} // Call handleInputChange on input change
                           />
                        ))}
                     </div>
                     <div className="ml-3 error-text mt-3" style={{"color": '#ff006a'}}>
                        {codeErrorCode}
                     </div>
                     <div className="mt-3">
                        {isSignUpInProgress ?
                           <button type="button" disabled
                                   onClick={createNewUser} className={Classnames(Styles.LoginButton, Styles.DisabledButton)}>{t("VERIFY_CODE.CREATING_THE_ACCOUNT")}
                              <Puff height={'2em'} strokeWidth={5} speed={2}/></button> :
                           <input type="submit" disabled={codeError} value={t("VERIFY_CODE.VERIFY_THE_CODE")}
                                  onClick={createNewUser} className={Classnames(Styles.LoginButton)}/>}
                     </div>
                     <div className={Classnames(Styles.ModalSmallText, "pt-3", "text-center")}>
                        {t("VERIFY_CODE.IF_YOU_HAVENT_RECEIVED_THE_EMAIL_ON")} <font
                        style={{color: '#ffffff'}}>{props.email}</font>
                        {t("VERIFY_CODE.AFTER_A_FEW_MINUTES, PLEASE_CHECK_YOUR_JUNK_MAIL_FOLDER_AS_IT_MAY_HAVE_BEEN_MISTAKEN_FOR_SPAM, OR_TRY_A_DIFFERENT_EMAIL_ADDRESS")}
                     </div>
                     <div className={Classnames(Styles.ModalSmallText2, "pt-3", "text-center")}>
                        {showTimer ? <Timeout restartKey={timerStart} resetTimer={timerResetter}/> :
                           <div>
                              <text style={{color: "#ffffff"}}>{t("VERIFY_CODE.CANT_FIND_THE_CODE")}?&nbsp;</text>
                              <font onClick={() => {
                                 setShowTimer(true)
                                 setTimerStart(true)
                                 verifyUserEmail()
                              }}>{t("VERIFY_CODE.RESEND")}
                              </font>
                           </div>
                        }<br/>
                        {t("VERIFY_CODE.INCORRECT_EMAIL")} <font><Link to={"/register"}>{t("VERIFY_CODE.UPDATE_THE_EMAIL_ADDRESS")}</Link></font>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}
const mapStateToProps = state => {
   return {
      isAuthenticated: state.auth.token !== null,
   }
}

const mapDispatchToProps = dispatch => {
   return {
      onAuth: (accessToken, refreshToken, oAuthProviderType, imageURL, userName, name, email) => dispatch(actions.auth(accessToken, refreshToken, oAuthProviderType, imageURL, userName, name, email))
   }
}
export default connect(mapStateToProps, mapDispatchToProps)(VerifyCode);
