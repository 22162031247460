import React, {useEffect, useState} from "react";
import Styles from "./Register.module.scss"
import Classnames from "classnames"
import * as UserService from "../../services/UserService"
import GoogleLoginButton from "../../components/SocialLogins/GoogleLogin";
import FacebookLoginButton from "../../components/SocialLogins/FacebookLogin";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {AUTHENTICATION_TOASTS} from "../../utilities/ToastMessages";
import * as EmailSubscriptionService from "../../services/EmailSubscriptionService";
import {connect} from "react-redux";
import pac_player from "../../assets/images/pac_player.webp";
import {useTranslation} from "react-i18next";
import {AUTHENTICATION_TYPES} from "../../utilities/Constants";

const Register = (props) => {
   let navigate = useNavigate();
   const [email, setEmail] = useState("")
   const [password, setPassword] = useState("")
   let [emailValid, setEmailValid] = useState(false)
   let [passwordValid, setPasswordValid] = useState(false)
   const [emailError, setEmailError] = useState("")
   const [passwordError, setPasswordError] = useState("")
   const [showModal, setShowModal] = useState(false)
   const [disable, setDisable] = useState(false)
   const [showPassword, setShowPassword] = useState(false);
   const [disableFromValidation, setDisableFromValidation] = useState(true);
   const [isTNCChecked, setIsTNCChecked] = useState(false);
   const [isNewsLetterChecked, setIsNewsLetterChecked] = useState(false);

   // useEffect(() => {
   //   console.log(props)
   //   if (props.isAuthenticated) {
   //     navigate("/");
   //   }
   // }, [])
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   const loadingIndicator = (
      <div className="w-100 text-center">
         <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
         }}>
            <div className="lds-facebook">
               <div></div>
               <div></div>
               <div></div>
            </div>
         </div>
      </div>);

   const handleDisableClick = (input) => {
      setDisable(input)
   }
   const verifyUserEmail = () => {
      UserService.verifyEmail("", {
         "attribute": email
      }).then((response) => {
         if (response.apiStatus === true) {
            setShowModal(true)
         }
      })
   }

   const passDataToCreateAccount = async () => {

      if (isNewsLetterChecked) {
         EmailSubscriptionService.SubscribeUserToNewsLetter(email).then((response) => {
            if (response.status == 200) {

            }
         })
      }

      let data = {
         password: password,
         email: email
      }
      navigate('/create-account', {
         state: data
      })

   }
   const passwordStrengthBar = (passwordError) => {
      switch (passwordError) {
         case "Strong":
            return (
               <div className={Styles.passwordStrength}>
                  <div className={Styles.strengthBar} style={{backgroundColor: "#00FF00", width: "100%"}}></div>
                  <div className={Styles.strengthLabel} style={{"color": '#00FF00'}}>{t("REGISTER.STRONG")}</div>
               </div>
            );

         case "Good":
            return (
               <div className={Styles.passwordStrength}>
                  <div className={Styles.strengthBar} style={{backgroundColor: "#65CE3FFF", width: "75%"}}></div>
                  <div className={Styles.strengthLabel} style={{"color": '#65CE3FFF'}}>{t("REGISTER.GOOD")}</div>
               </div>
            );

         case "Fair":
            return (
               <div className={Styles.passwordStrength}>
                  <div className={Styles.strengthBar} style={{backgroundColor: "#F8F703FF", width: "50%"}}></div>
                  <div className={Styles.strengthLabel} style={{"color": '#F8F703FF'}}>{t("REGISTER.FAIR")}</div>
               </div>
            );

         case "Weak":
            return (
               <div className={Styles.passwordStrength}>
                  <div className={Styles.strengthBar} style={{backgroundColor: "#F87E03FF", width: "25%"}}></div>
                  <div className={Styles.strengthLabel} style={{"color": '#F87E03FF'}}>{t("REGISTER.WEAK")}</div>
               </div>
            );

         case "Very weak":
            return (
               <div className={Styles.passwordStrength}>
                  <div className={Styles.strengthBar} style={{backgroundColor: "#FF0101FF", width: "10%"}}></div>
                  <div className={Styles.strengthLabel} style={{"color": '#FF0101FF'}}>{t("REGISTER.VERY_WEAK")}</div>
               </div>
            );
         default:
            return (<></>)
      }
   }
   const getPasswordStrength = (password) => {
      const regexPattern1 = /^(?!.*\s)(?!.*(.)\1\1\1)(?!.*[_]{2})\S{8,}$/;

      if (password.match(regexPattern1)) {
         const passwordLength = password.length;
         const hasUppercase = /[A-Z]/.test(password);
         const hasLowercase = /[a-z]/.test(password);
         const hasDigit = /\d/.test(password);
         const hasAlpha = /[\W_]/.test(password);
         const hasSpecial = /[^\w\d\s:]/.test(password);
         let score = passwordLength * 2;
         if (hasUppercase) score += 4;
         if (hasLowercase) score += 2;
         if (hasDigit) score += 3;
         if (hasAlpha) score += 4;
         if (hasSpecial) score += 5;
         return Math.min(Math.floor(score / 10), 4);
      } else {
         return 0;
      }
   }


   useEffect(() => {
      setPasswordValid(true)
      setEmailValid(true)
   }, [])
   const handleModalClose = (childResponse) => {
      setShowModal(childResponse)
   }
   const handlePasswordChange = (e) => {
      setPasswordError("")
      setPasswordValid(true);
      setPassword(e.target.value)
      setDisableFromValidation(true)
      if (e.target.value === "") {
         setPasswordError("")
         setPasswordValid(true)
         setDisableFromValidation(true)
      } else {
         if (getPasswordStrength(e.target.value) === 4) {
            setPasswordError("Strong")
            setPasswordValid(true);
         } else if (getPasswordStrength(e.target.value) === 3) {
            setPasswordError("Good")
            setPasswordValid(true);
         } else if (getPasswordStrength(e.target.value) === 2) {
            setPasswordError("Fair")
            setPasswordValid(true);

         } else if (getPasswordStrength(e.target.value) === 1) {
            setPasswordError("Weak")
            setPasswordValid(false);

         } else {
            setPasswordError("Very weak")
            setPasswordValid(false);

         }
      }
   }
   useEffect(() => {
      if (email !== "" && password !== "") {
         setDisableFromValidation(false)
      }
   }, [email, password])


   const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
   };

   const handleTNCCheckboxChange = (event) => {
      setIsTNCChecked(event.target.checked);
   };

   const handleNewsLetterChanged = (event) => {
      setIsNewsLetterChecked(event.target.checked);
   }

   const handleEmailChange = (e) => {
      setEmailError("")
      setEmailValid(false)
      setEmail(e.target.value)
      const inputEmail = e.target.value
      if (e.target.value) {
         emailValid = e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) !== null ? true : false;
         // setEmailValid(emailValid)
         if (e.target.value == "") {
            setEmailError("")
            setEmailValid(false)
         } else {
            setEmailError(emailValid ? '' : `${t("REGISTER.INVALID_EMAIL")}`);
         }
         if (emailValid) {
            const emailAvailability = UserService.emailAvailability(
               {
                  "attribute": e.target.value?.toLowerCase()
               }
            )
            emailAvailability.then((response) => {
               setEmailValid(false);
               if (response.available !== true && response.apiStatus === true) {
                  setEmailValid(false);
                  setEmailError(`${t("REGISTER.EMAIL_ALREADY_REGISTERED.")}`)

               } else if (e.target.value == "") {
                  setEmailError("")
                  setEmailValid(false)
               } else if (response.available === true && response.apiStatus === true) {
                  setEmailError("")
                  setEmailValid(true)
               } else {
                  setEmailError("")
                  setEmailValid(false)
               }
            }).catch(e => {
               toast.error(AUTHENTICATION_TOASTS.ERROR_CHECKING_EMAIL_AVAILABILITY)
            })
         }
      } else {
         setEmailError("")
         setEmailValid(false)
      }
   }

   if (!translationsLoaded) {
      return loadingIndicator;
   }

   //const pac_player = "https://meta11.blob.core.windows.net/serviceplatform/pakistan-player.webp";
   return (
      <div style={{height: "100vh", overflowX: "hidden"}}>
         <div className={Classnames("row", Styles.midContent, "pt-0 pt-sm-0 pt-md-0 pt-lg-5", "pb-2")}>
            <div className={Classnames(Styles.Radial1)}></div>


            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 ">
               <Link to={"/"}>
                  <img src="https://storage.googleapis.com/meta11/serviceplatform/M11FantasyBetaLogo.webp" alt=""
                       className={Classnames(Styles.loginLogo, Styles.marginTopMd)} style={{width: '197px', height: '54px'}}/>
               </Link>
               <div className="col-12 d-flex p-0">
                  <div className={Classnames("mt-5", Styles.TopBotGaps)}>
                     <div className="heading2 gradient-color">{t("REGISTER.GET_STARTED")}</div>
                  </div>
               </div>
               <div className={Classnames(Styles.FormBox, "justify-content-start")}>
                  <div className={Classnames(Styles.group)}>
                     <input type="text" name="name" required={true} autoComplete="off" value={email} style={{maxWidth: "none", width: "100%"}}
                            onChange={handleEmailChange}
                            className={!emailValid ? Classnames(Styles.Error) : ""}/>
                     <label>{t("REGISTER.EMAIL_ADDRESS")}</label>
                  </div>
                  <div className="w-100 text-left mb-3 color-pink body-text4" style={{"height": "10px"}}>
                     {emailError}
                  </div>
                  <div className={Classnames(Styles.group)}>
                     <input type={showPassword ? 'text' : 'password'} name="name" autoComplete="new-password"
                            required={true} value={password}
                            onChange={handlePasswordChange} onPaste={handlePasswordChange}
                            className={Classnames(!passwordValid ? Classnames(Styles.Error) : "", showPassword ? "" : Styles.asteriskInput)}/>
                     <label>{t("REGISTER.PASSWORD")}</label>

                     <span className={!showPassword ? Styles.normalEye : Styles.slashEye} onClick={togglePasswordVisibility}></span>
                  </div>
                  <div className={Classnames(Styles.passwordStrengthWrapper)}>
                     <div className="w-100 text-left mb-4">
                        {passwordStrengthBar(passwordError)}
                     </div>
                  </div>
                  <div className={Classnames(Styles.agreementstWrapper)}>
                     <div className={Classnames(Styles.checkIndent, "mb-2 align-middle row")} style={{color: '#95A6B7'}}>
                        <input type="checkbox" className={Classnames(Styles.FormCheckInput, "form-check-input float-start")}
                               id="exampleCheck1" checked={isNewsLetterChecked} onChange={handleNewsLetterChanged} style={{margin: "0px"}}/>
                        <div className="float-start p-0 col-11 body-text4 color-gray2">{t("REGISTER.SIGN_ME_UP_FOR_META_11_UPDATES")}</div>

                     </div>
                     <div style={{color: '#95A6B7'}} className={Classnames(Styles.checkIndent, "mt-1 align-middle row float-start")}>
                        <input type="checkbox" className={Classnames(Styles.FormCheckInput, "form-check-input float-start")}
                               id="exampleCheck2" checked={isTNCChecked} onChange={handleTNCCheckboxChange} style={{margin: "0px"}}/>
                        <div className={Classnames(Styles.agreeToTextWrapper, "float-start body-text4 color-gray2 p-0 col-11 text-start")}>{t("REGISTER.I_HEREBY_AGREE_TO_THE")}<a href="/terms-and-conditions"
                                                                                                                                                                                   className={Classnames("color-textturquiose")}>{t("REGISTER.TERMS & CONDITIONS")}</a> {t("REGISTER.META_11")}</div>
                     </div>
                  </div>
                  <input type="submit" onClick={passDataToCreateAccount}
                         disabled={!passwordValid || !emailValid || disable || disableFromValidation || !isTNCChecked}
                         value={t("REGISTER.SIGN_UP")}
                         className={Classnames(Styles.LoginButton, (!passwordValid || !emailValid || disable || disableFromValidation || !isTNCChecked) ? "" : Styles.buttonColor)}/>
                  <div className={Classnames(Styles.OrSpace)}>
                     <text>{t("REGISTER.OR")}</text>
                  </div>
                  <div className={Classnames("body-text3 color-gray2 mt-2 mb-3")}> {t("REGISTER.BY_SIGNING_UP_USING_GOOGLE/ FACEBOOK, YOU_AGREE_TO")}<a href="/terms-and-conditions" className={Classnames("color-textturquiose")}>{t("REGISTER.TERMS & CONDITIONS")}</a>&nbsp;
                     {t("REGISTER.AND")} <a href="/privacy-policy" className={Classnames("color-textturquiose")}> {t("REGISTER.PRIVACY_POLICY")} </a> &nbsp;{t("REGISTER.OF_META_11")}
                  </div>
                  <GoogleLoginButton disable={disable} disableClick={handleDisableClick} type={AUTHENTICATION_TYPES.REGISTER}/>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <FacebookLoginButton disable={disable} disableClick={handleDisableClick} type={AUTHENTICATION_TYPES.REGISTER}/>
               </div>
               <div className={Classnames("body-text3 color-gray2 mt-2 mb-3 d-flex pt-3")} style={{width: "100%"}}>{t("REGISTER.ALREADY_HAVE_AN_ACCOUNT?")} <a href="/login" className={Classnames("color-textturquiose ps-2")}>{t("REGISTER.CLICK_TO_SIGN_IN")} </a></div>
            </div>
            <div className={Classnames("neonLight col-12 col-sm-12 col-md-6 col-lg-6 col-xl-7 d-none d-lg-flex align-items-center justify-content-center")} style={{textAlign: "center"}}>
               <img src={pac_player} className={Classnames(Styles.imgSize)}/>
            </div>
         </div>
         {/*{*/}
         {/*    showModal ? <VerifyCodeV2 email={email} password={password} onCloseModal={handleModalClose}/> : <></>*/}
         {/*}*/}

      </div>
   );
};
const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
   };
};
export default connect(mapStateToProps)(Register);
