import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

const Timeout = ({restartKey, resetTimer}) => {
    const [timer, setTimer] = useState(localStorage.getItem('timer') || 180);
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);


    const resetTimerFunc = (input) => {
        resetTimer(input)
    }
    useEffect(() => {
        let interval;

        if (timer > 0) {
            interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
                localStorage.setItem('timer', timer - 1);
            }, 1000);
        } else {
            resetTimerFunc(false);
        }

        return () => clearInterval(interval);
    }, [timer]);


    useEffect(() => {
        if (restartKey) {
            resetTimerFunc(true);
        }

    }, [])
    useEffect(() => {
        if (restartKey) {
            setTimer(180);
            localStorage.setItem('timer', 180);
        }
    }, [restartKey]);

    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;

    return (
        <div>
            <font>{`${t("TIMEOUT.PLEASE_WAIT")} ${minutes}:${seconds < 10 ? '0' : ''}${seconds} ${t("TIMEOUT.FOR_RESEND_EMAIL")}`}</font>
        </div>
    );
};

export default Timeout
