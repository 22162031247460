import React, {useEffect, useState} from "react";
import PubNub from "pubnub";
import {PUBNUB_KEYS} from "../../utilities/Constants";
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import {pubNubConfigConstants} from "../../utilities/PubNubConfigConstants";


const TournamentPubNubHandler = (props) => {

   const [channels, setChannels] = useState([`${pubNubConfigConstants.TOURNAMENT_REWARD}-${props.tournamentId}`,`${pubNubConfigConstants.LEADERBOARD}-${props.tournamentId}-${props.userName}`,`${pubNubConfigConstants.LEADERBOARD}-${props.tournamentId}-${props.page}-${props.size}`,`${pubNubConfigConstants.TOURNAMENT_TEAM_POINTS}-${props.tournamentId}`]);
   //TODO userName inject
   let pubnub;

   // Create the PubNub instance outside the useEffect
   if (props.isAuthenticated) {
      pubnub = new PubNub({
         publishKey: PUBNUB_KEYS.PUBLISH_KEY,
         subscribeKey: PUBNUB_KEYS.SUBSCRIBE_KEY,
         uuid: props.userName ?? '',
         authKey: props.token ?? '', // Initialize with token, which may be null initially
      });
   } else {
      props.onLogout();
   }


   useEffect(() => {
      const listenerParams = {message: handleMessage};
      if (pubnub) {
         pubnub.addListener(listenerParams);
         pubnub.subscribe({channels});
         console.log("subscribed to channel " + channels);
      }

      return () => {
         if (pubnub) {
            console.log("unsub");
            pubnub.unsubscribe({channels});
            pubnub.removeListener(listenerParams);
         }
      };
   }, [channels, props.isAuthenticated, props.userName]);


   const handleMessage = event => {
      let message = event.message;
      if (event.channel == `rewards-${props.tournamentId}`) {
         console.log(message);
         props.onDataReceived(message)
      }else if (event.channel == `Leaderboard-${props.tournamentId}-${props.userName}`){
         console.log(message);
         props.onUserLeaderboardReceived(message)
      }else if (event.channel == `Leaderboard-${props.tournamentId}-${props.page}-${props.size}`){
         console.log(message);
         props.onLeaderboardReceived(message)
      }else if(event.channel == `tournament_team_points-${props.tournamentId}`){
         props.onTeamPoints(message)
      }
   }

   const pubNubSubscriber = (channel) => {
      pubnub.subscribe(channel)
      setChannels(prevState => [...prevState, channel]);
      console.log(pubnub);
   }

   const pubNubUnSubscriber = (channel) => {
      pubnub.unsubscribe(channel)
   }

   useEffect(() => {
      if (props.subscribe != null) {
         pubNubSubscriber(props.subscribe)
      }
   }, [props.subscribe]);

   useEffect(() => {
      if (props.unSubscribe != null) {
         pubNubUnSubscriber(props.unSubscribe)
      }
   }, [props.unSubscribe]);

   return (<></>)
}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      refresh: state.auth.refreshToken,
      Token: state.auth.accessToken,
      userId: state.auth.userId,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      email: state.auth.email
   };
}

const mapDispatchToProps = (dispatch) => {
   return {
      onLogout: () => dispatch(actions.logout()), onRefreshAuth: (accessToken, refreshToken) => dispatch(actions.refreshSession(accessToken, refreshToken)),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(TournamentPubNubHandler);
