import {ApiEndpoints} from "./ApiConstants";
import AxiosInstance from "../helpers/AxiosInstance";

export const GetNotificationsByUserName = (userId, token, page, sort, size,lang) => {
    const url = `${ApiEndpoints.GET_NOTIFICATIONS_BY_USERID}/${userId}?lang=${lang}&page=${page}&size=${size}&sort=${sort}`;
    return AxiosInstance()
        .get(url, {
            headers: {
                "AuthToken": token
            }
        })
        .then((response) => {
            return {
                status: response.status,
                data: response.data
            };
        })
        .catch((error) => {
            return error.response;
        });
};
