import React, {useEffect, useState} from "react";
import Styles from "./RegistrationSuccess.module.scss"
import Classnames from "classnames"
import {Link, useNavigate} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import styles from "../Matches/Matches.module.scss";
const RegistrationSuccess = (props) => {
    let navigate = useNavigate();

    const { t } = useTranslation();
    const [translationsLoaded, setTranslationsLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setTranslationsLoaded(true);
        }, 1000);
    }, []);

    const loadingIndicator = (
        <div className="w-100 text-center">
            <div className={styles.loaderContainer}>
                <div className="lds-facebook">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>);

    if (!translationsLoaded) {
        return loadingIndicator;
    }

    return (
        <React.Fragment>
            <div className={Classnames(Styles.Radial1)}></div>
            <div className={Classnames(Styles.Radial2)}></div>
            <div className="row col-11">
                <div className="row mt-4">
                    <Link to={"/"}>
                        <img src="https://storage.googleapis.com/meta11/serviceplatform/M11FantasyBetaLogo.webp" alt=""
                             className={Classnames(Styles.loginLogo, "ms-auto", "me-auto")}/>
                    </Link>
                </div>

                <div className="ms-auto me-auto p-0" style={{width: "780px"}}>


                    <div className={Classnames(Styles.CorrectIcon, Styles.TopBotGaps, 'mb-5')}></div>

                    <div>
                        <div className={Classnames("text-center heading2 gradient-color", 'p-0')}>{t("REGISTRATION_SUCCESS.YOUR_ACCOUNT_HAS_BEEN")} <br/>
                            {t("REGISTRATION_SUCCESS.SUCCESSFULLY_CREATED")}
                        </div>
                    </div>
                    <br/>

                    <div
                        className={Classnames("body-text2 color-gray2 mt-2", "col-8", "pb-2", "text-center", "ms-auto", "me-auto")}>
                        {t("REGISTRATION_SUCCESS.NOW_YOU_CAN_USE_YOUR_META_11_ACCOUNT_TO_EXPERIENCE_CRICKET_FANTASY_GAMING")}
                    </div>

                    <div className="justify-content-start mt-5 ms-auto me-auto" style={{maxWidth: "496px"}}>


                        <input type="submit" onClick={() => navigate("/")} value={t("REGISTRATION_SUCCESS.BACK_TO_HOME_PAGE")}
                               className={Classnames(Styles.LoginButton)}/>

                        {/* <input type="submit"  disabled value="back to home page" className={Classnames(Styles.LoginButton)}/>*/}


                    </div>

                </div>
            </div>

        </React.Fragment>
    );
};

export default RegistrationSuccess;

