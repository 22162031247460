import classNames from "classnames";
import styles from "../../containers/Leaderboard/LeaderBoard.module.scss";
import {Link} from "react-router-dom";
import {formatNumberToK, getPlayerShowingName, toolTipProvider} from "../../helpers/CommonFunctions";
import React, {useEffect, useState} from "react";
import {getPosition, getProfileImage, getUpDownSameIconByDirection, pointsPerGame} from "../../helpers/LeaderBoardHelpers";
import {useTranslation} from "react-i18next";
import ViewFantasyTeamModal from "../Modals/ViewFantasyTeam/ViewFantasyTeam";

const LeaderBoardRow = ({leader, userNameOfCurrentUser, getGreenOrPurpleCap, location, handleViewTeamBtn}) => {
   const [showMain, setShowMain] = useState(false);
   const [playerName, setPlayerName] = useState("");

   const toggleMain = () => {
      setShowMain(!showMain);
   };
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   useEffect(() => {
      setPlayerName(getPlayerShowingName(leader))
   }, [leader])

   const getPoints = () => {
      setShowMain(!showMain);
   };

   const positionToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.POSITION"), "top")
   const namePlayersToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.NAME_PLAYERS"), "top")
   const totalPointsToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.TOTAL_POINTS"), "top")
   const gamesPlayedToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.GAMES_PLAYED"), "top")
   const fantasyPointsToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.FANTASY_POINTS"), "top")
   const pointsForMatchToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.POINTS_FOR_MATCH"), "top")
   // const questionAnsweredToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.QBOT_QUESTION_ANSWERED"), "top")
   // const questionCorrectToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.QBOT_QUESTION_CORRECT"), "top")
   const questionIncorrectToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.QBOT_QUESTION_INCORRECT"), "top")
   const ratioToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.RATIO"), "top")
   // const totalPredictionsToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.TOTAL_PREDICTIONS_TOOLTIP"), "top")
   // const correctPredictionsToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.CORRECT_PREDICTIONS_TOOLTIP"), "top")

   return (
      <tr key={leader.userName} className={leader?.userName === userNameOfCurrentUser ? classNames(styles.leaderBoardHigh) : undefined} style={{position: "relative"}}>
         <td className={classNames(styles.multirow)}>
            <div>
               {positionToolTip(<label className="CorrectPresent"><>&nbsp;&nbsp;&nbsp;&nbsp;<i>{getPosition(leader?.position)}</i></>
               </label>, true)}
            </div>
         </td>
         <td className={classNames(styles.multirow)}>
            <div>
               {toolTipProvider(t("LEADER_BOARD_ROW.YOU_CANN'T_VISIT_A_PRIVATE_USER'S_PROFILE"), 'right', <div>
                  <Link to={leader.isPrivate ? '' : {
                     pathname: `/user/${btoa(leader.userName)}`,
                     search: `returnUrl=${encodeURIComponent(location.pathname)}`
                  }} style={{textDecoration: 'none', cursor: `${leader?.isPrivate ? "default" : "pointer"}`}}>
                     {getProfileImage(leader?.profilePicUrl, playerName.split(' ')[0], playerName.split(' ')[1])}
                  </Link>
                  {toolTipProvider(playerName, 'top', <label className="CorrectPresent"><>{playerName}</>
                  </label>, true)}
               </div>, leader.isPrivate)}

            </div>
         </td>
         <td className={classNames(styles.multirow)}>
            <div className="row p-0">
               <div className="col-2 p-0">
                  <i className="fa-sharp fa-solid fa-trophy"
                     style={{fontSize: "13px"}}></i>
               </div>
               <div className="float-right text-end col-5 p-0 greenText">
                  {leader?.points > 999 ?
                     <>
                        {toolTipProvider(leader?.points, 'top', <label className="CorrectPresent">{formatNumberToK(leader?.points, 2)}
                        </label>, true)}</> : <label className="CorrectPresent">{formatNumberToK(leader?.points, 2)}</label>}
               </div>
               <div className="col-2 p-0">
                  {getUpDownSameIconByDirection(leader?.direction)}
               </div>
            </div>
         </td>
         <td className={classNames(styles.multirow)}>
            {fantasyPointsToolTip(<label className="CorrectPresent">
               {leader?.userName === userNameOfCurrentUser ? <div className={'flex-row gap-2'}>
                  <label className="CorrectPresent">{leader?.fantasyPoints || 0}</label>
                  <button className={classNames(styles.ViewTeamBtn)}
                          onClick={handleViewTeamBtn}>{t("LEADER_BOARD_ROW.TEAM")}</button>
               </div> : <label className="CorrectPresent">{leader?.fantasyPoints || 0}</label>}
            </label>, true)}
         </td>
         <td className={classNames(styles.multirow)}>
            {gamesPlayedToolTip(<label className="CorrectPresent"><>{leader?.matches || 0}</>
            </label>, true)}
         </td>
         <td className={classNames(styles.multirow)}>
            {pointsForMatchToolTip(<label className="CorrectPresent">{formatNumberToK(pointsPerGame(leader?.matches, leader?.points))}</label>, true)}
         </td>
         {/*<td className={classNames(styles.multirow)}>*/}
         {/*   {questionAnsweredToolTip(<label className="CorrectPresent">{getPosition(leader?.questionFaced) || 0}</label>, true)}*/}
         {/*</td>*/}
         {/*<td className={classNames(styles.multirow)}>*/}
         {/*   {questionCorrectToolTip(<label className="CorrectPresent"><>{getPosition(leader?.rightAnswers) || 0}</>*/}
         {/*   </label>, true)}*/}
         {/*</td>*/}
         {/*<td className={classNames(styles.multirow)}>*/}
         {/*   {totalPredictionsToolTip(<label className="CorrectPresent"><>{getPosition(leader?.predictionsFaced) || 0} </>*/}
         {/*   </label>, true)}*/}
         {/*</td>*/}
         {/*<td className={classNames(styles.multirow)}>*/}
         {/*   {correctPredictionsToolTip(<label className="CorrectPresent"><>{getPosition(leader?.predictionsCorrect) || 0} </>*/}
         {/*   </label>, true)}*/}
         {/*</td>*/}
         {/*<div className={classNames(styles.accordionBtnWrapper)}>*/}
         {/*   {showMain && (*/}
         {/*      <i onClick={toggleMain} className={classNames(styles.accordionBtnUp, "fa fa-caret-up")} aria-hidden="true"></i>*/}
         {/*   )}*/}
         {/*   {!showMain && (*/}
         {/*      <i onClick={toggleMain} className={classNames(styles.accordionBtnDown, "fa fa-caret-down")} aria-hidden="true"></i>*/}
         {/*   )}*/}
         {/*</div>*/}
      </tr>
   )
}
export default LeaderBoardRow;
