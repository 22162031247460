import React, {useEffect, useState} from "react";
import Footer from "../../../components/Footer/Footer";
import {connect} from "react-redux";
import Styles from "./KnockoutTournament.module.scss";
import Classnames from "classnames";
import classNames from "classnames";
import NavbarSecondary from "../../../components/NavigationBar/NavigationBar";
import FirstPlace from "../../../assets/images/backgrounds/tournaments/1st_Prize.svg";
import SecondPlace from "../../../assets/images/backgrounds/tournaments/2nd_Prize.svg";
import ThirdPlace from "../../../assets/images/backgrounds/tournaments/3rd_Prize.svg";
import Line from "../../../assets/images/line.webp";
import Credit from "../../../assets/images/icons/24_sprts_token.webp";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import KnockoutMatchDraw from "../../../components/Tournament/KnockoutMatchDraw/KnockoutMatchDraw";
import TournamentMatches from "../../../components/Tournament/TournamentMatches";
import {useTranslation} from "react-i18next";
import * as StatisticsService from "../../../services/StatisticsService";
import Calendar from "../../../assets/images/fi_calendar.webp";
import {formatDate, formatTime} from "../../../helpers/CommonFunctions";
import WinPrizesSection from "../../../components/Tournament/WinPrizesSection/WinPrizesSection";
import TournamentLeaderBoard from "../../../components/Tournament/Leaderboard/TournamentLeaderBoard";
import Modal from "react-bootstrap/Modal";
import HowToWinRewards from "../../../components/Modals/HowToWinRewards/HowToWinRewards";
import * as TournamentService from "../../../services/TournamentService";
import {MATCH_CARD_TYPES, SOCKET_MESSAGES} from "../../../utilities/Constants";
import * as PubNubService from "../../../services/PubNubService";
import PubNubMatchesHandler from "../../../components/Tournament/PubNubMatchesHandler";
import LatestMatch from "../LatestMatch";
import TournamentPubnubHandler from "../../../services/PubNubHandlers/TournamentPubNubHandler";
import * as actions from "../../../store/actions";
import TournamentFantasySection from "../../../components/Tournament/FantasyTeamSection/TournamentFantasySection";

const KnockoutTournament = (props) => {

   //Local State Management
   const [toggleDetails, setToggleDetails] = useState(true);
   const [selectedOption, setSelectedOption] = useState(sessionStorage?.getItem("SelectedOption")||'Matches');
   const [latestMatch, setLatestMatch] = useState(null);
   const [tournamentDetails, setTouranamentDetails] = useState(null);
   let {tournamentId} = useParams();
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);
   const [isHowToModalOpen, setIsHowToModalOpen] = useState(false);
   const location = useLocation();
   const [lang, setLang] = useState(localStorage.getItem("language"));
   const [drawData, setDrawData] = useState([]);
   const [rewardsCategory, setRewardsCategory] = useState()
   const [currentPage, setCurrentPage] = useState(0);
   const [postsPerPage, setpostsPerPage] = useState(5);
   const [connectPubnub, setConnectPubnub] = useState(false);
   const [token, setToken] = useState(null);
   const [selectedRadioBtnValue, setSelectedRadioBtnValue] = useState('all');
   const [searchQuery, setSearchQuery] = useState('');
   const [subscribeChannel, setSubscribeChannel] = useState(null);
   const [unSubscribeChannel, setUnsubscribeChannel] = useState(null);
   let uuid = localStorage.getItem("uuid");
   const [loadingInProgress, setLoading] = useState(true);
   const pathname = window.location.pathname;
   let navigate = useNavigate();
   const currentTime = new Date();
   const matchStartTime = new Date(latestMatch?.matchStartTime);
   const [pubnubRewardsToken, setPubnubRewardsToken] = useState(null)
   const [connectRewardsPubnub, setConnectRewardsPubnub] = useState(null);
   const [leaderBoardData, setLeaderBoardData] = useState();
   const [leaderBoardUserData, setLeaderBoardUserData] = useState();


   useEffect(() => {
      setToggleDetails(selectedOption === 'Details' ? true : false);
   }, [selectedOption]);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   useEffect(() => {
      const fetchData = async () => {
         if (props.isAuthenticated) {
            try {
               console.log(props.userName, ": username token ")
               PubNubService.requestAccessTokenQuiz(props.userName, '123455555', Number(tournamentId), props.Token).then(response => {
                  if (response != null) {
                     setPubnubRewardsToken(response);
                     setConnectRewardsPubnub(true);
                     localStorage.setItem("pubnubRewardToken", response);
                  }
               });
            } catch (error) {
               console.error("Error:", error);
            }
         }
      }

      fetchData();
   }, [props.userName, loadingInProgress]);

   useEffect(() => {
      const fetchData = async () => {
         if (localStorage.getItem("pubnubToken") == null) {
            try {
               await PubNubService.requestAccessTokenMatches(uuid, props.Token).then(response => {
                  if (response != null) {
                     setToken(response);
                     setConnectPubnub(true);
                     console.log(response);
                     localStorage.setItem("pubnubToken", response);
                  }
               })
            } catch (error) {
               console.error("Error:", error);
            }
         } else {
            setConnectPubnub(true);
            setToken(localStorage.getItem("pubnubToken"));

         }
      };
      fetchData();
   }, [uuid]);

   // useEffect(() => {
   //    const checkUserRegistration = async () => {
   //       await TournamentService.checkTournamentRegistration(tournamentId,props.userName )
   //          .then((response) => {
   //             if (response.apiStatus ) {
   //                if (!response.available) {
   //                   authRedirectHandler(navigate);
   //                }
   //             } else {
   //                authRedirectHandler(navigate);
   //             }
   //          });
   //    };
   //    checkUserRegistration();
   // }, []);


   useEffect(() => {
      setTranslationsLoaded(false);
      const fetchData = async () => {
         await StatisticsService.getAllMatchCardsByTournamentId("", 0, 1, "isLive,isScheduled,matchId,desc", "", tournamentId, lang, props.Token)
            .then(async (response) => {
               if (response.apiStatus && response.totalElements != 0) {
                  setTranslationsLoaded(true);
                  if (response?.matches[0]) {
                     setLatestMatch(response?.matches[0]);
                     console.log(response.matches[0]);
                  } else {
                     setLatestMatch(null);
                  }
               } else if (response.totalElements == 0) {
                  setTranslationsLoaded(true);
                  await StatisticsService.getAllMatchCardsByTournamentId("", 0, 1, "isLive,isScheduled,matchId,desc", "completed", tournamentId, lang, props.Token)
                     .then((response) => {
                        if (response.apiStatus && response.totalElements != 0) {
                           if (response?.matches[0]) {
                              setLatestMatch(response?.matches[0])
                           } else {
                              setLatestMatch(null);
                           }
                        }

                     })
               }
            });
      };
      fetchData();
   }, [selectedOption]);

   useEffect(() => {
      const fetchTournamentDetails = async () => {
         await TournamentService.getKnockoutTournamentById(props.Token, tournamentId, lang).then((response) => {
            if (response.apiStatus) {
               setTouranamentDetails(response?.knockoutTournament?.tournament);
               setDrawData([response?.knockoutTournament]);
            } else {
               navigate("/login");
            }
         });
      };

      fetchTournamentDetails();
   }, [selectedOption, rewardsCategory]);


   const overCount = (teamOvers, teamBalls) => {
//TODO : handle null here
      const overs = teamBalls !== 6
         ? teamOvers - 1
         : teamOvers

      const balls = teamBalls !== 6
         ? teamBalls
         : 0
      return overs + "." + balls;

   }

   useEffect(() => {
      const fetchData = async () => {
         await TournamentService.getUserRewardCategory(tournamentId)
            .then((response) => {
               if (response.apiStatus && response.rewardCategoryList) {
                  setRewardsCategory(response.rewardCategoryList);
               }
            })
      };
      fetchData();
   }, [tournamentId]);

   function getRewardForPosition(position, rewardCategoryList) {

      if (!Array.isArray(rewardCategoryList)) {
         return 0;
      }
      for (const category of rewardCategoryList) {
         const {minRank, maxRank, reward} = category.userRanking;
         if (position >= minRank && position <= maxRank) {
            if (tournamentDetails.tournamentTicketOmniCredits) {
               return reward;
            } else {
               return (reward /250).toFixed(3);
            }
         }
      }
      return 0;
   }

   useEffect(() => {
      const checkTournamentRegistration = async () => {
         sessionStorage.setItem('redirectPath', pathname)
         if (!localStorage.getItem('accessToken')) {
            navigate("/login");
         } else {
            try {
               const response = await TournamentService.checkTournamentRegistration(tournamentId, props.userName);
               if (response.apiStatus) {
                  if (!response.available) {
                     navigate("/tournaments");
                  }
               } else {
                  navigate("/tournaments");
               }

            } catch (error) {
               console.log(error);
               throw error;
            }
         }

      }
      checkTournamentRegistration();
   }, [tournamentId, props.userName]);

   const winPrices = (winPriceData) => {
      if (winPriceData?.tournamentRank == 1) {
         return (
            <>
               <div className={Classnames(Styles.FirstPlace)}>
                  <img src={FirstPlace}/>
                  <div className={Classnames(Styles.CreditWrapper)}>
                     {/*<div>*/}
                     {/*   <img src={Ticket}/>*/}
                     {/*   <sapan>5</sapan>*/}
                     {/*</div>*/}
                     <div>
                        <img src={Credit}/>&nbsp;
                        {getRewardForPosition(winPriceData?.tournamentRank, rewardsCategory)}
                     </div>
                  </div>
               </div>
               <div className={Classnames(Styles.LineWrapper)}>
                  <img src={Line}/>
               </div>
            </>

         );
      } else if (winPriceData?.tournamentRank == 2) {
         return (
            <>
               <div className={Classnames(Styles.SecondPlace)}>
                  <img src={SecondPlace} alt=''/>
                  <div className={Classnames(Styles.CreditWrapper)}>
                     {/*<div>*/}
                     {/*   <img src={Ticket}/>*/}
                     {/*   <sapan>5</sapan>*/}
                     {/*</div>*/}
                     <div>
                        <img src={Credit} alt=''/>
                        {getRewardForPosition(winPriceData?.tournamentRank, rewardsCategory)}
                     </div>
                  </div>
               </div>
               <div className={Classnames(Styles.LineWrapper)}>
                  <img src={Line} alt=''/>
               </div>
            </>

         );
      } else if (winPriceData?.tournamentRank == 3) {
         return (
            <div className={Classnames(Styles.ThirdPlace)}>
               <img src={ThirdPlace} alt=''/>
               <div className={Classnames(Styles.CreditWrapper)}>
                  {/*<div>*/}
                  {/*   <img src={Ticket}/>*/}
                  {/*   <sapan>5</sapan>*/}
                  {/*</div>*/}
                  <div>
                     <img src={Credit} alt=''/>
                     {getRewardForPosition(winPriceData?.tournamentRank, rewardsCategory)}
                  </div>
               </div>
            </div>
         )
      }
   }

   const openHowtoWinRewardsModal = () => {
      setIsHowToModalOpen(true);
   }

   function handleMoreInfoClose() {
      setIsHowToModalOpen(false);
   }

   const loadingIndicator = (
      <div style={{textAlign: "center"}}>
         <div className="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
         </div>
      </div>
   );
   const messageFromMatchesCallback = (message) => {
      try {
         let json = JSON.parse(message);

         if (currentPage === 0 && searchQuery === "") {

            if (selectedRadioBtnValue == MATCH_CARD_TYPES.ALL || selectedRadioBtnValue == MATCH_CARD_TYPES.LIVE) {

               if (json.bodyType == SOCKET_MESSAGES.MATCH_CARD_DETAILS) {
                  // filterMatches(json.body);
                  setLatestMatch(json.body.matches[0]);
               } else {
                  setLoading(false);
               }
            }
         } else if (currentPage != 0) {
            if (selectedRadioBtnValue != MATCH_CARD_TYPES.ALL || selectedRadioBtnValue != MATCH_CARD_TYPES.LIVE) {
               setUnsubscribeChannel("Matches-" + tournamentId);
               setUnsubscribeChannel("Live-" + tournamentId)
            }
         }

      } catch (error) {
         console.error("JSON parsing error:", error);
      }
   }

   const returnLatestMatch = () => {
      return (
         <LatestMatch tournamentDetails={tournamentDetails} latestMatch={latestMatch}/>
      )
   }

   useEffect(() => {
      returnLatestMatch();
   }, [latestMatch]);

   const messageFromRewardsCallback = (message) => {
      if (message) {
         setRewardsCategory(message.rewardCategoryList);
         console.log(message.rewardCategoryList)
      } else {
         console.log(message);
      }
   }
   const messageFromUserLeaderboardCallback = (message) => {
      if (message) {
         console.log(message)
         let json = JSON.parse(message);
         setLeaderBoardUserData(json.body)
      } else {
         console.log(message);
      }
   }
   const messageFromLeaderboardCallback = (message) => {
      if (message) {
         console.log(message)
         let json = JSON.parse(message);
         setLeaderBoardData(json.body)
      } else {
         console.log(message);
      }
   }
   // const callbackFromTeamPoints = (message) => {
   //    if (message) {
   //       console.log(message)
   //       setTournamentTeams(message?.teams);
   //    } else {
   //       console.log(message);
   //    }
   // }
   const pageAndSizeFromLeaderboard = (data) => {
      console.log(data)
      setCurrentPage(data.page);
      setpostsPerPage(data.size)
   }
   const changeSelectedOption = (data) =>{
      sessionStorage.setItem("SelectedOption", data);
      setSelectedOption(data);
   }
   return (
      <React.Fragment>
         {(connectPubnub && (token != null)) ?
            <PubNubMatchesHandler onRecieved={messageFromMatchesCallback} token={token} subscribe={subscribeChannel}
                                  onUnsubscribe={unSubscribeChannel} tournamentId={tournamentId}/>
            : <></>
         }

         {(connectRewardsPubnub && pubnubRewardsToken != null && props.isAuthenticated) ?
            <TournamentPubnubHandler onDataReceived={messageFromRewardsCallback} onUserLeaderboardReceived={messageFromUserLeaderboardCallback} onLeaderboardReceived={messageFromLeaderboardCallback} token={pubnubRewardsToken} tournamentId={tournamentId} userName={props.userName} page={currentPage}
                                     size={postsPerPage}/>
            : <></>
         }

         <div className={Classnames(Styles.Radial1)}></div>
         <div className={Classnames(Styles.Radial2)}></div>
         <div className={Classnames(Styles.KnockoutTournamentPage)}>
            <div className={Classnames(Styles.bodyTournaments)}>
               <div
                  className={Classnames(Styles.ResponceCont, "col-12", "row", "pb-2", "ps-2", "pe-2", "ps-xl-0", "pe-xl-0")}>
                  <div className="col-12 p-0 row">
                     <NavbarSecondary page="events"/>
                  </div>

                  {translationsLoaded ?
                     <div className={Classnames(Styles.pageWrapper, "col-12 p-0")}>
                        <h1>{tournamentDetails?.name}</h1>
                        <div className={Classnames(Styles.contnetWrapper)}>
                           <div className="row">
                              <div className="col-md-12 p-0 float-left pe-lg-4">
                                 {/* Daily MatchesWrapper */}
                                 <div className={Classnames(Styles.DailyMatchesWrapper, Styles.MatchWrapper)}>
                                    <div className="row">
                                       <div className="col-md-12 p-0">
                                          <div className="row">
                                             <div className="col p-0">
                                                <div className={Classnames(Styles.LimitedTournamentHeader)} style={{background: `url(${tournamentDetails?.logoUrl})`, backgroundRepeat: 'no-repeat'}}>
                                                   <div className="row">
                                                      <div className="col-md-12">
                                                         {returnLatestMatch()}
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    <div className="row">
                                       <div className="col-md-4 p-0 mb-2">
                                          <div className={Classnames(Styles.MatchOrDetailsWrapper)}>
                                             <div className={Classnames(Styles.RadioBtnWrapper)}>
                                                <label className="radioButtonlbl m-0 pe-2 w-100 p-0 mb-2 mb-sm-0">
                                                   <input type="radio" id="Fantasy" name="Details_or_Matches"
                                                          checked={selectedOption === "Fantasy"} value="Fantasy"
                                                          onChange={() => changeSelectedOption('Fantasy')}/>
                                                   <span>{t("KNOCKOUT_TOURNAMENT.FANTASY")}</span>
                                                </label>
                                             </div>
                                             <div className={Classnames(Styles.RadioBtnWrapper)}>
                                                <label className="radioButtonlbl m-0 pe-2 w-100 p-0 mb-2 mb-sm-0">
                                                   <input type="radio" id="Details" name="Details_or_Matches"
                                                          checked={selectedOption == "Details" ? true : false} value="Details"
                                                          onChange={() => changeSelectedOption('Details')}/>
                                                   <span>{t("KNOCKOUT_TOURNAMENT.DETAILS")}</span>
                                                </label>

                                             </div>
                                             <div className={Classnames(Styles.RadioBtnWrapper)}>
                                                <label className="radioButtonlbl m-0 pe-2 w-100 p-0 mb-2 mb-sm-0">
                                                   <input type="radio" id="Matches" name="Details_or_Matches" value="Matches"
                                                          checked={selectedOption == "Matches" ? true : false}
                                                          onChange={() => changeSelectedOption('Matches')}/>
                                                   <span>{t("KNOCKOUT_TOURNAMENT.MATCHES")}</span>
                                                </label>

                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    <div className="row">
                                       <div className="col-xl-9 col-lg-12 p-0 float-left">

                                          {(selectedOption === "Fantasy") &&
                                             <TournamentFantasySection tournamentDetails={tournamentDetails}/>
                                          }

                                          {!toggleDetails && (selectedOption !== "Fantasy") &&
                                             <TournamentMatches tournamentId={tournamentId}/>}

                                          {selectedOption == "Details" ?
                                             <>
                                                <KnockoutMatchDraw tournamentDetails={tournamentDetails} drawData={drawData}/>
                                                <TournamentLeaderBoard leaderboardData={leaderBoardData} userLeaderBoardData={leaderBoardUserData} tournamentDetails={tournamentDetails} onPageAndSize={pageAndSizeFromLeaderboard}/>
                                             </> : <></>}
                                       </div>

                                       <div className={Classnames(Styles.rightCol, "col-xl-3 p-0 d-xl-block")}>
                                          {/* Rewards Details */}
                                          {tournamentDetails?.tournamentTicketOmniCredits ?
                                             (rewardsCategory?.[0]?.userRanking?.reward > 0) ?
                                                (tournamentDetails?.active === false && tournamentDetails?.toStart === true) ?
                                                   <div className="row p-0">
                                                      <div className="col p-0">
                                                         <div className={Classnames(Styles.WinPrizes)}>
                                                            <div className={Classnames(Styles.Conner1)}></div>
                                                            <div className={Classnames(Styles.Conner2)}></div>
                                                            <div className={Classnames(Styles.Conner3)}></div>
                                                            <div className={Classnames(Styles.Conner4)}></div>
                                                            <div className={Classnames(Styles.Shade1)}></div>
                                                            <div className={Classnames(Styles.Shade2)}></div>
                                                            <div className={Classnames(Styles.Shade3)}></div>
                                                            <div className={Classnames(Styles.Shade4)}></div>
                                                            {tournamentDetails?.winPrices?.length > 0 ? <>
                                                                  <div className={Classnames(Styles.WinPrizesWrapper)}>
                                                                     {tournamentDetails?.winPrices?.map((price, i) => winPrices(price))}
                                                                     <button onClick={openHowtoWinRewardsModal} className={Classnames(Styles.playNow, 'mt-4')}>{t("LEAGUE_TOURNAMENT.HOW_TO_WIN_REWARDS")}</button>
                                                                  </div>
                                                               </>
                                                               : <div className={Classnames(Styles.WinPrizesWrapper)}>
                                                                  {t("LEAGUE_TOURNAMENT.TAKE_PART_IN_THE_TOURNAMENT_TO_WIN_REWARDS")}
                                                                  <button onClick={openHowtoWinRewardsModal} className={Classnames(Styles.playNow, 'mt-4')}>{t("LEAGUE_TOURNAMENT.HOW_TO_WIN_REWARDS")}</button>
                                                               </div>
                                                            }
                                                         </div>
                                                      </div>
                                                   </div>
                                                   : <></>
                                                : <></>
                                             : <></>
                                          }

                                          {/* Winners Section */}
                                          {(tournamentDetails?.active === false && tournamentDetails?.toStart === false) || (tournamentDetails?.active === true && tournamentDetails?.toStart === false) ?
                                             <WinPrizesSection tournament={tournamentDetails}/>
                                             : <></>}

                                          {/* Overview */}
                                          {(tournamentDetails?.active === false && tournamentDetails?.toStart === true) || (tournamentDetails?.active === true && tournamentDetails?.toStart === false) ?
                                             <div className="row p-0">
                                                <div className="col p-0">
                                                   <div className={classNames(Styles.OverviewWrapper)}>
                                                      <div className={classNames(Styles.WinPrizes)}>
                                                         <div className={classNames(Styles.Conner1)}></div>
                                                         <div className={classNames(Styles.Conner2)}></div>
                                                         <div className={classNames(Styles.Conner3)}></div>
                                                         <div className={classNames(Styles.Conner4)}></div>
                                                         <div className={classNames(Styles.Shade1)}></div>
                                                         <div className={classNames(Styles.Shade2)}></div>
                                                         <div className={classNames(Styles.Shade3)}></div>
                                                         <div className={classNames(Styles.Shade4)}></div>
                                                         <div className={classNames(Styles.WinPrizesWrapper)}>
                                                            <div className="row">
                                                               <h4>{t("LEAGUE_TOURNAMENT.OVERVIEW")}</h4>
                                                               <span className="body-text4 color-gray2"> {t("LEAGUE_TOURNAMENT.START")}: </span>
                                                               <div className={classNames(Styles.Date)}><img src={Calendar}/>
                                                                  &nbsp;{formatDate(tournamentDetails?.startTime) || '--'}&nbsp;at&nbsp;{formatTime(tournamentDetails?.startTime) || '--'}
                                                               </div>
                                                               <p className="body-text4 color-gray2">
                                                                  {tournamentDetails?.bio ? tournamentDetails?.bio : t("LEAGUE_TOURNAMENT.NO_DESCRIPTION_AVAILABLE")}
                                                               </p>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div> : <></>}


                                          {(tournamentDetails?.active === false && tournamentDetails?.toStart === false) || (tournamentDetails?.active === true && tournamentDetails?.toStart === false) ?
                                             <div className="row p-0">
                                                <div className={classNames(Styles.bannerWrapper, "col p-0")}>
                                                   <img
                                                      src="https://storage.googleapis.com/meta11/serviceplatform/limitedTournamentBanner.png"/>
                                                </div>
                                             </div> : <></>}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div> : loadingIndicator}
                  <Footer/>
               </div>
            </div>
         </div>

         <Modal show={isHowToModalOpen} onHide={handleMoreInfoClose}>
            <Modal.Body>
               <HowToWinRewards handleMoreInfoClose={handleMoreInfoClose}/>
            </Modal.Body>
         </Modal>

      </React.Fragment>
   );
};

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      email: state.auth.email,
      Token: state.auth.accessToken,
      RefreshToken: state.auth.refreshToken,
      timeOut: state.auth.sessionTimeout,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      onLogout: () => dispatch(actions.logout()), onRefreshAuth: (accessToken, refreshToken) => dispatch(actions.refreshSession(accessToken, refreshToken)),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(KnockoutTournament);
