import {ApiEndpoints} from "./ApiConstants";
import AxiosInstance from "../helpers/AxiosInstance";

export const getAllTestimonials = (page, sort, size, lang) => {
   const url = `${ApiEndpoints.GET_ALL_TESTIMONIALS}?lang=${lang}&page=${page}&size=${size}&sort=${sort}`;
   return AxiosInstance()
      .get(url)
      .then((response) => {
         return {
            status: response.status,
            data: response.data
         };
      })
      .catch((error) => {
         return error.response;
      });
};

