export const guideObj = {
  'match': [
    {
      'title': 'QBOT Chat',
      'position': [-75, 0, 0, -450],
      'toolTipPosition': "qbotQuestions",
      'arrowStyle': "arrow-right",
      'contents': [
        'Answer the Quiz Bot questions and earn point to win prizes.',
        'To play, when QBOT asks if you would like to play, select yes. When the game has started, you will be asked 7 questions. Each question answered correctly will score you points!',
        'Be sure to answer the question before the time runs out! Once the timer is finished you cannot answer the question.',
        'At the end of each live match, your points will be displayed here.']
    }, {
      'title': 'Notifications',
      'position': [-11, 0, 0, -450],
      'arrowStyle': "arrow-right",
      'contents': ['Follow your notifications. For authorized users only.']
    }, {
      'title': 'Leaderboard',
      'position': [100, 0, 0, 232],
      'arrowStyle': "arrow-up",
      'contents': ['See where you fall among other META11 players',
        'View all the other users’ averages of right answers, the number of matches answered, and the total marks scored.',
        'The highlighted column has been allocated to the user who is logged into the system. So, the user can check his/her current position along with the QBOT history of the last five matches, the average of right answers, the number of matches answered, and the total marks scored.',
        'Also, if the account is public, user can view any user on the leaderboard. Registered user can view all the other user profiles by clicking on the username in the leaderboard.',
        'Show All – Displays all the users who have answered the quiz bot.']
    }, {
      'title': 'Overview',
      'position': [-10, 0, 0, -459],
      'arrowStyle': "arrow-right",
      'contents': ['Get the full low down of the players and the matches.']
    }, {
      'title': 'Athlete Сard',
      'position': [-10, 0, 0, -459],
      'arrowStyle': "arrow-up",
      'contents': ['View all important information about the athlete. Its strengths and weaknesses. Number of lost and won matches.']
    }],

  'matches': [{
    'title': 'Search Field',
    'position': [0, 50, 0, -434],
    'arrowStyle': "arrow-right",
    'contents': [
      "Search for available matches by typing the player's name."]
  }, {
    'title': 'User Profile',
    'position': [-10, 100, 20, 17],
    'arrowStyle': "arrow-up",
    'contents': [
      'Create your profile and level up your skills 💪',
      'View all personal details, including favorite players, match-ups, notifications, and leaderboard scores.']
  }, {
    'title': 'Matches',
    'position': [8, 0, 0, 15],
    'arrowStyle': "arrow-up",
    'contents': [
      "Don't miss a thing! Be ahead of the game and prepare for Upcoming and Live matches. Here you can also review and analyse the scores of completed matches."]
  },],

  'home': [{
    'title': 'Matches Page',
     'position': {
        'desktop': [120, 0, 0, 480],
        'tablg': [-75, 0, 0, -450],
        'tabsm': [-75, 0, 0, -450],
        'mobilesm': [-75, 0, 0, -450],
        'mobilelg': [-75, 0, 0, -450],
     },
    'arrowStyle': "arrow-up",
    'contents': ['View all the Live, Upcoming and Completed matches.']
  }, {
    'title': 'Match-Ups Page',
     'position': {
        'desktop': [120, 0, 0, 590],
        'tablg': [-75, 0, 0, -450],
        'tabsm': [-75, 0, 0, -450],
        'mobilesm': [-75, 0, 0, -450],
        'mobilelg': [-75, 0, 0, -450],
     },
    'arrowStyle': "arrow-up",
    'contents': ["It's easy to track all the statistics of the AI Athletes, study the players to win more points!"]
  }, {
    'title': 'Leaderboard Page',
     'position': {
        'desktop': [90, 0, 0, 815],
        'tablg': [-75, 0, 0, -450],
        'tabsm': [-75, 0, 0, -450],
        'mobilesm': [-75, 0, 0, -450],
        'mobilelg': [-75, 0, 0, -450],
     },
    'arrowStyle': "arrow-up",
    'contents': ["View all the current rankings of the other users. Keep yourself on the top."]
  }, {
    'title': 'MetaLux Countdown Timer',
     'position': {
        'desktop': [2, 0, 0, 1000],
        'tablg': [-75, 0, 0, -450],
        'tabsm': [-75, 0, 0, -450],
        'mobilesm': [-75, 0, 0, -450],
        'mobilelg': [-75, 0, 0, -450],
     },
    'arrowStyle': "arrow-up",
    'contents': ['Get ready for the next META11 Tournament. Mark your calendars and prepare to play!']
  },],

   'matchups': [{
      'title': 'Search Field',
      'position': [4, 0, 0,-430],
      'arrowStyle': "arrow-right",
      'contents': ['Search match-ups through a simple search.']
   }, {
      'title': 'Match Ups',
      'position': [-909, 0, 0,-2],
      'arrowStyle': "arrow-up",
      'contents': ["View all the match-ups, including all the details."]
   }, {
      'title': 'Match Ups',
      'position': [-614, 0, 0,-2],
      'arrowStyle': "arrow-up",
      'contents': ["Users can mark or unmark favorite match-ups."]
   }, ],

   'playerProfile': [{
      'title': 'Athlete Information',
      'position': [-24, 0, 0,316],
      'arrowStyle': "arrow-up",
      'contents': ['View all important information about the athlete. Its strengths and weaknesses. Number of lost and won matches.']
   }, {
      'title': 'Statistics',
      'position': [-75, 0, 0,203],
      'arrowStyle': "arrow-left",
      'contents': ["View the athlete's statistics."]
   }, {
      'title': 'Latest Runs',
      'position': [-70, 0, 0,218],
      'arrowStyle': "arrow-left",
      'contents': ["The athlete's last runs. Points and opponents. You can always see the whole picture."]
   },{
      'title': 'Popular Players',
      'position': [-64, 0, 0,400],
      'arrowStyle': "arrow-left",
      'contents': ["Check out the most popular athletes and their results. Choose your favorite and add him to your list so you don't miss an important match."]
   }, ],
   'userProfile': [{
      'title': 'User Profile',
      'position': [103, 0, 0,371],
      'arrowStyle': "arrow-up",
      'contents': ['View all your personal details, including favorite players, match-ups, notifications, and leaderboard scores.']
   }, {
      'title': 'Edit Personal Details',
      'position': [2, 0, 0,63],
      'arrowStyle': "arrow-up",
      'contents': ["Click on the Edit button and edit your own personal details. After editing all the personal details, you can click on the save button to save all the edited details, or else the user can cancel the window without saving the edited details by clicking on the cancel button."]
   }, {
      'title': 'Overview',
      'position': [-44, 0, 0,-22],
      'arrowStyle': "arrow-up",
      'contents': ["You can view your overview section including:\n" +
      "·  \tPreview of total tokens have been earned by answering the questions\n" +
      "·  \tPreview of how many questions have been faced\n" +
      "·  \tPreview of how many answers are wrong and correct among the questions that have been answered"]
   },{
      'title': 'Notification',
      'position': [-14, 0, 0,-10],
      'arrowStyle': "arrow-up",
      'contents': ["All the Actions have been taken by the user will be displayed. such as when the players & match-ups are marked as favorites."]
   },{
      'title': 'My Favorites',
      'position': [0, 0, 0,-70],
      'arrowStyle': "arrow-left",
      'contents': ["View their favorite matchups & players in the ‘My Favorites’ section. All the match-ups & players that have been marked as favorites will be displayed in this section. Also, you can click on their favorite match-ups & players, so it will automatically navigates to those pages."]
   },{
      'title': 'Leaderboard',
      'position': [-381, 0, 0,903],
      'arrowStyle': "arrow-left",
      'contents': ["You can view the leaderboard on their profile page. Leaderboard includes a preview of the username and user ranking, along with the respective token scores. If the account is public, then the user can view other user profiles by clicking on their usernames in the leaderboard."]
   }, ],
   'leaderboard': [{
      'title': 'Top Leaders',
      'position': [-96, 0, 0,505],
      'arrowStyle': "arrow-up",
      'contents': ['An easy way to view the top three leaders and try to be among them.']
   }, {
      'title': 'Leaderboard',
      'position': [-65, 0, 0,410],
      'arrowStyle': "arrow-left",
      'contents': ["See where you fall among other META11 players."]
   }, {
      'title': 'Leaderboard',
      'position': [1040, 0, 0,335],
      'arrowStyle': "arrow-left",
      'contents': ["View all the other users’ averages of right answers, the number of matches answered, and the total marks scored."]
   },{
      'title': 'Leaderboard',
      'position': [1040, 0, 0,335],
      'arrowStyle': "arrow-up",
      'contents': ["The highlighted column has been allocated to the user who is logged into the system. So, the user can check his/her current position along with the QBOT history of the last five matches, the average of right answers, the number of matches answered, and the total marks scored."]
   },{
      'title': 'Leaderboard',
      'position': [1040, 0, 0,335],
      'arrowStyle': "arrow-left",
      'contents': ["Also, if the account is public, user can view any user on the leaderboard. Registered user can view all the other user profiles by clicking on the username in the leaderboard."]
   },{
      'title': 'Leaderboard',
      'position': [1040, 0, 0,335],
      'arrowStyle': "arrow-left",
      'contents': ["Show All – Displays all the users who have answered the quiz bot."]
   }, ]
}

export const LBPrivateUserNavigateMessage = "You can't visit a private user's profile.";
