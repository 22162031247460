import {useEffect, useState} from "react";
import PubNub from "pubnub";
import {PUBNUB_KEYS, SITE_LANGUAGE} from "../../utilities/Constants";
import {connect} from "react-redux";
import * as PubNubService from "../PubNubService";
import {pubNubConfigConstants} from "../../utilities/PubNubConfigConstants";

const UserNotificationHandler = (props) => {
   const pageLanguage = localStorage.getItem("language");
   const [uuid, setUuid] = useState(props.userName);
   const [pubNub, setPubNub] = useState(null);
   const [pubNubToken, setPubNubToken] = useState("");
   const [channels, setChannels] = useState([]);

   useEffect(() => {
      if (!props.userName) return;
      setUuid(props.userName);

      // Set the channels based on the language
      if (pageLanguage === SITE_LANGUAGE.HINDI) setChannels([`${pubNubConfigConstants.USER_NOTIFICATION_HI}-${props.userName}`]);
      else setChannels([`${pubNubConfigConstants.USER_NOTIFICATION_EN}-${props.userName}`]);
   }, [pageLanguage, props.userName]);

   useEffect(() => {
      const fetchData = async () => {
         try {
            // 1234 is a dummy matchId and tournamentId to get the token
            PubNubService.requestAccessTokenQuiz(uuid, 1234, 1234, props.Token).then(response => {
               if (response != null) {
                  setPubNubToken(response);
                  console.log("PubNub token set");
               }
            });
         } catch (error) {
            console.error("Error:", error);
         }

      };
      if (uuid && props.Token) {
         fetchData();
      }
   }, [uuid, props.Token]);

   useEffect(() => {
      if (uuid && pubNubToken) {
         let pubNub = new PubNub({
            publishKey: PUBNUB_KEYS.PUBLISH_KEY,
            subscribeKey: PUBNUB_KEYS.SUBSCRIBE_KEY,
            uuid: uuid,
            authKey: pubNubToken,
         });
         console.log("PubNub instance created");
         setPubNub(pubNub)
      }
   }, [uuid, pubNubToken]);

   useEffect(() => {
      if (props.isAuthenticated && props.userName && pubNub) {
         const handleMessage = (event) => {
            let message = event.message;

            // convert the message to json
            if (typeof message === "string") {
               try {
                  message = JSON.parse(message);
               } catch (error) {
                  console.error("Error parsing message:", error);
               }
            }

            props?.onReceived(message);
         };

         const handleStatus = (statusEvent) => {
            if (statusEvent.category === "PNConnectedCategory") {
               console.log("Successfully subscribed to channels:", channels);
            } else if (statusEvent.category === "PNAccessDeniedCategory") {
               console.log("Access denied to channels:", channels);
            }
            if (statusEvent.error) {
               console.error("PubNub status error:", statusEvent.errorData.message, statusEvent);
            }
         };

         const listenerParams = {
            message: handleMessage, status: handleStatus,
         };

         pubNub.addListener(listenerParams);

         console.log("Attempting to subscribe to channels:", channels);
         pubNub.subscribe({channels});

         return () => {
            console.log("Unsubscribing from channels:", channels);
            pubNub.unsubscribe({channels});
            pubNub.removeListener(listenerParams);
         };
      } else {
         console.log("PubNub not initialized");
      }
   }, [channels, props.isAuthenticated, props.userName, pubNub]);
}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      Token: state.auth.accessToken,
      userId: state.auth.userId,
      userName: state.auth.userName,
      email: state.auth.email,
   };
}

export default connect(mapStateToProps)(UserNotificationHandler);
