import classNames from "classnames";
import React, {useEffect, useReducer, useState} from "react";
import styles from "./LeaderBoard.module.scss";
import * as LeaderBoardService from "../../services/LeaderBoardService";
import {BsBoxArrowUp} from "react-icons/bs";
import NavbarSecondary from "../../components/NavigationBar/NavigationBar"
import Footer from "../../components/Footer/Footer";
import {formatNumberToK, getPlayerShowingName, toolTipProvider} from "../../helpers/CommonFunctions";
import {Link, useLocation} from "react-router-dom";
import {connect} from "react-redux";
import LeaderBoardRow from "../../components/LeaderBoardRow/LeaderBoardRow";
import LeaderBoardModal from "../../components/LeaderBoardRow/LeaderBoardModal";
import Paginator from "../../components/Paginator/Paginator";
import {getCorrectWrongIconByHistoryStatus, getDisNameForFirst3Users, getGreenOrPurpleCap, getPosition, getProfileImage, getUpDownSameIconByDirection, pointsPerGame} from "../../helpers/LeaderBoardHelpers";
import {getConf} from "../../services/ConfigService";
import {LEADERBOARD_PAGE} from "../../utilities/ConfigConstants";
import {useTranslation} from "react-i18next";
import SocialShareModal from "../../components/Modals/SocialShareModal/SocialShareModal";
import GuidanceTip from "../../components/GuidanceTip/GuidanceTip";
import ViewFantasyTeamModal from "../../components/Modals/ViewFantasyTeam/ViewFantasyTeam";
import GlobalLeaderBoardHandler from "../../services/PubNubHandlers/GlobalLeaderBoardHandler";
import * as PubNubService from "../../services/PubNubService";
import TournamentPubnubHandler from "../../services/PubNubHandlers/TournamentPubNubHandler";
import PlayerInfoModal from "../../components/Modals/PlayerInfoModal/PlayerInfoModal";
import FantasyMatchHistoryModal from "../../components/Modals/FantasyMatchHistory/FantasyMatchHistory";

const LeaderBoard = (props) => {

   const location = useLocation();
   const [showMain, setShowMain] = useState(false);
   const [currentPage, setCurrentPage] = useState(0);
   const [postsPerPage, setPostsPerPage] = useState(9);
   const [loading, setLoading] = useState(false);
   const [topPlayer, setTopPlayer] = useState([]);
   const [selectedRadio, setSelectedRadio] = useState('tournament');
   const [lastTournamentId, setLastTournamentId] = useState(-1);
   const [tournamentCards, setTournamentCards] = useState([]);
   const [nextGotItIndex, setNextGotItIndex] = useState(0);
   const [tipIndexCounter, setTipIndexCounter] = useState(1);
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);
   const [isSorted, setisSorted] = useState(false);

   const [showFantasyTeamModal, setShowFantasyTeamModal] = useState(false);
   const [isDisplayHiddenFantasyTeamModal, setIsDisplayHiddenFantasyTeamModal] = useState(false);
   const [showPlayerInfoModal, setShowPlayerInfoModal] = useState(false);
   const [showFantasyMatchHistoryModal, setShowFantasyMatchHistoryModal] = useState(false);
   const [selectedPlayerId, setSelectedPlayerId] = useState(null); // player id for match history modal
   const [selectedPlayerImage, setSelectedPlayerImage] = useState(null); // player image for match history modal
   const [selectedPlayerName, setSelectedPlayerName] = useState(null); // player name for match history modal

   const [rejectMsg, setRejectMsg] = useState();
   let uuid = localStorage.getItem("uuid");
   const [subscribeChannel, setSubscribeChannel] = useState(null);
   const [unSubscribeChannel, setUnsubscribeChannel] = useState(null);
   const [pubNubToken, setPubNubToken] = useState(null);
   const [connectPubnub, setConnectPubnub] = useState(false);
   const [pubnubRewardsToken, setPubnubRewardsToken] = useState(null);
   const [connectRewardsPubnub, setConnectRewardsPubnub] = useState(null);

   const initialState = {
      isGettingLeaderBoardUsers: false,
      leaderBoardUsers: [],
      currentUser: null,
      maxAvgRightAnsUser: {},
      isShowingModal: false,
      currentPage: 0,
      totalElements: 0,
      isShowingSocialModal: false,
      leaderboardSnapshot: null,
      sortingEnabledPage: 'rank',
      isAscOrder: true,
      isClickedColumnHandler: false
   }

   const handleRadioChange = (event) => {
      const selectedValue = event.target.value;
      setSelectedRadio(selectedValue);
      setCurrentPage(0);
   };

   const leaderBoardReducer = (state, action) => {
      switch (action.type) {
         case 'IS_GETTING_LEADERBOARD_USERS':
            return {...state, isGettingLeaderBoardUsers: action.payload};
         case 'LEADERBOARD_USERS':
            return {...state, leaderBoardUsers: action.payload};
         case 'CURRENT_USER':
            return {...state, currentUser: action.payload};
         case 'MAX_AVG_RIGHT_ANS_USER':
            return {...state, maxAvgRightAnsUser: action.payload};
         case 'IS_SHOWING_MODAL':
            return {...state, isShowingModal: action.payload};
         case 'CURRENT_PAGE':
            return {...state, currentPage: action.payload};
         case 'TOTAL_ELEMENTS':
            return {...state, totalElements: action.payload};
         case 'IS_SHOWING_SOCIAL_SHARE_MODAL':
            return {...state, isShowingSocialModal: action.payload};
         case 'SORTING_ENABLED_PAGE':
            return {...state, sortingEnabledPage: action.payload}
         case 'IS_ASC_ORDER':
            return {...state, isAscOrder: action.payload}
         case 'IS_CLICKED_COLUMN_HANDLER':
            return {...state, isClickedColumnHandler: action.payload}

      }
   }

   const [state, dispatch] = useReducer(leaderBoardReducer, initialState);

   const messageFromTournamentHandlerCallback = (message) => {
      try {
         let json = JSON.parse(message);
         if (currentPage == 0) {
            dispatch({type: 'LEADERBOARD_USERS', payload: json.body?.results});
            dispatch({type: 'TOTAL_ELEMENTS', payload: json.body?.totalElements});
         } else {
            getGlobalUserData();
         }
         getCurrentUserData();

      } catch (error) {
         console.error("JSON parsing error:", error);
      }
   }

   const getCurrentUserData = () => {
      if (selectedRadio === 'global') {
         LeaderBoardService.getUserForGlobalLB(props.userName).then((res) => {
            if (res?.apiStatus) {
               dispatch({type: 'CURRENT_USER', payload: res?.position});
            }
         })
      } else if (lastTournamentId !== -1) {
         LeaderBoardService.getLBUserByUserNameAndTournamentId(props.userName, lastTournamentId).then((res) => {
            if (res?.apiStatus) {
               dispatch({type: 'CURRENT_USER', payload: res?.position});
            }
         })
      }
   }

   const fetchTournamentData = async () => {
      try {
         if (lastTournamentId === -1)
            return;
         const leaderBoardResponse = await LeaderBoardService.getTournamentLeaderBoard(currentPage, 9, lastTournamentId, state?.sortingEnabledPage, state?.isAscOrder, props.token);

         if (leaderBoardResponse.apiStatus) {
            dispatch({type: 'LEADERBOARD_USERS', payload: leaderBoardResponse?.results});
            dispatch({type: 'TOTAL_ELEMENTS', payload: leaderBoardResponse?.totalElements});
            setRejectMsg("")
         } else {
            dispatch({type: 'LEADERBOARD_USERS', payload: []});
            dispatch({type: 'TOTAL_ELEMENTS', payload: 0});
            if (leaderBoardResponse.data.rejectMessages) {
               if (lastTournamentId == 0) {
                  setRejectMsg(t("LEADER_BOARD.NO_ACTIVE_TOURNAMENTS_AVAILABLE"));
               } else {
                  setRejectMsg(leaderBoardResponse.data.rejectMessages);
               }
            }
         }

         dispatch({type: 'IS_GETTING_LEADERBOARD_USERS', payload: false});
         setLoading(false);
      } catch (error) {
         console.error("An error occurred:", error);
      }
   };

   const getGlobalUserData = () => {
      LeaderBoardService.getGlobalLeaderBoard(currentPage, 9, state?.sortingEnabledPage, state?.isAscOrder).then((res) => {
         if (res.apiStatus) {
            dispatch({type: 'LEADERBOARD_USERS', payload: res?.results});
            dispatch({type: 'TOTAL_ELEMENTS', payload: res?.totalElements});
         }
         dispatch({type: 'IS_GETTING_LEADERBOARD_USERS', payload: false})
         setLoading(false);
      })
         .catch((err) => console.error("Error occurred when getting Leaderboard users.", err));
   }

   const callbackFromTeamPoints = (message) => {
      if (message) {
         fetchTournamentData();
      } else {
         console.log(message);
      }
   }

   const messageFromRewardsCallback = (message) => {
      if (message) {
         fetchTournamentData();
      } else {
         console.log(message);
      }
   }

   const messageFromUserLeaderboardCallback = (message) => {
      if (message) {
         fetchTournamentData();
      } else {
         console.log(message);
      }
   }

   const messageFromLeaderboardCallback = (message) => {
      if (message) {
         fetchTournamentData();
      } else {
         console.log(message);
      }
   }

   // function for View Fantasy Team Modal
   const onCalendarClick = (playerId, playerImage, playerName) => {
      setSelectedPlayerId(playerId);
      setSelectedPlayerImage(playerImage);
      setSelectedPlayerName(playerName);
      setIsDisplayHiddenFantasyTeamModal(true);
      setShowFantasyMatchHistoryModal(true);
   }

   // function for View Fantasy Team Modal
   const onInfoClick = (playerId) => {
      setSelectedPlayerId(playerId);
      setIsDisplayHiddenFantasyTeamModal(true);
      setShowPlayerInfoModal(true);
   }

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   useEffect(() => {

      const fetchData = async () => {
         try {
            const response = await PubNubService.requestAccessTokenMatches(uuid, props.Token).then(response => {
               if (response != null) {
                  setPubNubToken(response);
                  setConnectPubnub(true);
                  localStorage.setItem("pubnubToken", response);
               }
            });

         } catch (error) {
            console.error("Error:", error);
         }

      };
      fetchData();
   }, [uuid]);

   useEffect(() => {
      const fetchData = async () => {
         if (props.isAuthenticated) {
            try {
               PubNubService.requestAccessTokenQuiz(props.userName, '1234', Number(lastTournamentId), props.token).then(response => {
                  if (response != null) {
                     setPubnubRewardsToken(response);
                     setConnectRewardsPubnub(true);
                     localStorage.setItem("pubnubRewardToken", response);
                  }
               });
               // setToken(response);
               // pubnub.setAuthKey(response); // Update PubNub authKey
            } catch (error) {
               console.error("Error:", error);
            }
         }
      }

      fetchData();
   }, [props.userName, lastTournamentId]);

   useEffect(() => {

      if (selectedRadio === 'tournament') {
         LeaderBoardService.getActiveTournamentList(0, 4, "id", "desc", props?.token)
            .then((res) => {
               if (lastTournamentId === -1) {
                  if (res?.apiStatus && res?.data?.[0] && res?.data[0]?.id) {
                     setTournamentCards(res?.data);
                     setLastTournamentId(res?.data[0]?.id);
                  } else {
                     setTournamentCards(0);
                     setLastTournamentId(0);
                  }
               }
            })
      }
   }, [selectedRadio]);

   useEffect(() => {

      dispatch({type: 'IS_GETTING_LEADERBOARD_USERS', payload: true});

      if (selectedRadio == 'global') {
         getGlobalUserData();
      } else {
         fetchTournamentData();
      }
   }, [currentPage, selectedRadio, lastTournamentId, state?.sortingEnabledPage, state?.isAscOrder]);


   useEffect(() => {
      if (props.isAuthenticated && props.userName) {
         getCurrentUserData();
      }
   }, [props.userName, selectedRadio, lastTournamentId]);

   useEffect(() => {
      if (state.isGettingLeaderBoardUsers == false) {
         state.leaderboardSnapshot = document.querySelector("#leaderboardCapture");
      }
   }, [state.isGettingLeaderBoardUsers]);

   const [firstThreeLBUsers, setFirstThreeLBUsers] = useState([]);
   const [typicalLBUsers, setTypicalLBUsers] = useState([]);

   useEffect(() => {
      if (selectedRadio == 'global') {
         if (state?.leaderBoardUsers && state?.leaderBoardUsers[0]?.position === 1) {
            setFirstThreeLBUsers(state?.leaderBoardUsers?.slice(0, 4));
            setTypicalLBUsers(state?.leaderBoardUsers);
            setTopPlayer(state?.leaderBoardUsers?.slice(0, 4));
         } else {
            setFirstThreeLBUsers(topPlayer);
            setTypicalLBUsers(state?.leaderBoardUsers);
         }
      } else {

         setFirstThreeLBUsers(state?.leaderBoardUsers?.slice(0, 4));
         setTypicalLBUsers(state?.leaderBoardUsers);
      }
   }, [state?.leaderBoardUsers, selectedRadio]);


   const paginate = (pageNumber, postsPerPage) => {
      dispatch({type: 'IS_CLICKED_COLUMN_HANDLER', payload: true});
      setPostsPerPage(Number(postsPerPage));
      dispatch({type: 'CURRENT_PAGE', payload: pageNumber});
      setLoading(true);
      setCurrentPage(pageNumber);
   };

   const toggleMain = () => {
      setShowMain(!showMain);
   };

   const userNameOfCurrentUser = state?.currentUser?.userName;
   const isPresentCurrentUserInLBUsers = state.leaderBoardUsers?.find(leader =>
      leader.userName?.toLowerCase() === state?.currentUser?.userName?.toLowerCase());

   const getLeaderboard = (loadingIndicator, leaderboard, noRecordsText) => {
      if (loading || (state?.isGettingLeaderBoardUsers && state?.isClickedColumnHandler)) {
         return loadingIndicator;
      }
      if (selectedRadio === 'tournament' && !(state?.leaderBoardUsers?.length)) {
         return noRecordsText;
      } else {
         return leaderboard;
      }
   }

   const leaderBoardTypicalUserTableRow = (leader) => {
      return <LeaderBoardRow leader={leader} userNameOfCurrentUser={userNameOfCurrentUser}
                             getCorrectWrongIconByHistoryStatus={getCorrectWrongIconByHistoryStatus}
                             getGreenOrPurpleCap={getGreenOrPurpleCap} location={location}
                             handleViewTeamBtn={() => setShowFantasyTeamModal(true)}/>;
   }

   const resultShareModalHandler = (value) => {
      dispatch({type: 'IS_SHOWING_SOCIAL_SHARE_MODAL', payload: value});
   }

   const changeTournamentId = (value) => {
      setLastTournamentId(value);
      setCurrentPage(0);
   }
   let prevPage = state.sortingEnabledPage;

   const columnHandler = (page) => {
      setisSorted(true);
      dispatch({type: 'IS_CLICKED_COLUMN_HANDLER', payload: true});
      dispatch({type: 'SORTING_ENABLED_PAGE', payload: page});
      if (prevPage === page) {
         dispatch({type: 'IS_ASC_ORDER', payload: !(state.isAscOrder)});
      } else {
         dispatch({type: 'IS_ASC_ORDER', payload: true});
      }
   }

   const showAllCloseHandler = (value) => {
      dispatch({type: 'IS_SHOWING_MODAL', payload: value});
   }
   if (!translationsLoaded) {
      return;
   }
   const avgRightAnsToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.AVERAGE_RIGHT_ANSWERS"), "top")
   const numOfMatchesAnsToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.NUMBER_OF_MATCHES_ANSWERED"), "top")
   const totalPointsToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.TOTAL_POINTS"), "top")
   const positionToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.POSITION"), "top")
   const gamesPlayedToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.GAMES_PLAYED"), "top")
   const totalPredictionsToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.TOTAL_PREDICTIONS_TOOLTIP"), "top")
   const correctPredictionsToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.CORRECT_PREDICTIONS_TOOLTIP"), "top")
   const pointsForMatchToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.POINTS_FOR_MATCH"), "top")
   const questionAnsweredToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.QBOT_QUESTION_ANSWERED"), "top")
   const questionCorrectToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.QBOT_QUESTION_CORRECT"), "top")
   const fantasyPointsToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.FANTASY_POINTS"), "top")

   const currentUserScoreTableRow = () => {
      const currentUserShowingName = getPlayerShowingName(state?.currentUser);

      return (
         <>
            <tr className={classNames(styles.leaderBoardHigh)} style={{position: "relative"}} key={state?.currentUser?.position}>
               <td style={{width: 75}}>
                  <div className="d-flex">
                     {positionToolTip(<label className="CorrectPresent"><>&nbsp;&nbsp;&nbsp;&nbsp;<i>{getPosition(state?.currentUser?.position)}</i></>
                     </label>, true)}
                  </div>
               </td>
               <td className={classNames(styles.multirow)}>
                  <div className="d-flex">
                     {toolTipProvider(t("LEADER_BOARD_ROW.YOU_CANN'T_VISIT_A_PRIVATE_USER'S_PROFILE"), 'right', <div>
                        <Link to={state?.currentUser?.isPrivate ? '' : {
                           pathname: `/user/${btoa(state?.currentUser?.userName)}`,
                           search: `returnUrl=${encodeURIComponent(location.pathname)}`
                        }} style={{textDecoration: 'none', cursor: `${state?.currentUser?.isPrivate ? "default" : "pointer"}`}}>
                           {getProfileImage(state?.currentUser?.profilePicUrl, currentUserShowingName.split(' ')[0], currentUserShowingName.split(' ')[1])}
                        </Link>
                        {toolTipProvider(currentUserShowingName, 'top', <label className="CorrectPresent"><>{currentUserShowingName}</>
                        </label>, true)}
                     </div>, state?.currentUser?.isPrivate)}
                  </div>
               </td>
               <td className={classNames(styles.multirow)}>
                  <div className="row p-0">
                     <div className="col-2 p-0">
                        <i className="fa-sharp fa-solid fa-trophy"
                           style={{fontSize: "13px"}}></i>
                     </div>
                     <div className="float-right col-5 text-end p-0 greenText">
                        {state?.currentUser?.points > 999 ?
                           <>
                              {toolTipProvider(state?.currentUser?.points, 'top',
                                 <label className="CorrectPresent">{formatNumberToK(state?.currentUser?.points, 2)}</label>
                                 , true)}
                           </> : <label className="CorrectPresent">{formatNumberToK(state?.currentUser?.points, 2)}</label>}
                     </div>
                     <div className="col-2 p-0">
                        {getUpDownSameIconByDirection(state?.currentUser?.direction)}
                     </div>
                  </div>
               </td>
               <td className={classNames(styles.multirow)}>
                  {fantasyPointsToolTip(<label className="CorrectPresent">
                     {state?.currentUser?.userName === userNameOfCurrentUser ? <div className={'flex-row gap-2'}>
                        <label className="CorrectPresent">{state?.currentUser?.fantasyPoints || 0}</label>
                        {(selectedRadio == 'tournament') && <button className={classNames(styles.ViewTeamBtn)}
                                 onClick={() => setShowFantasyTeamModal(true)}>{t("LEADER_BOARD_ROW.TEAM")}</button>}
                     </div> : <label className="CorrectPresent">{state?.currentUser?.fantasyPoints || 0}</label>}
                  </label>, true)}
               </td>
               <td className={classNames(styles.multirow)}>
                  {gamesPlayedToolTip(<label className="CorrectPresent"><>{state?.currentUser?.matches || 0}</>
                  </label>, true)}
               </td>
               <td>
                  {pointsForMatchToolTip(<label className="CorrectPresent">{formatNumberToK(pointsPerGame(state?.currentUser?.matches, state?.currentUser?.points))}</label>, true)}
               </td>
               {/*<td className={classNames(styles.multirow)}>*/}
               {/*   {questionAnsweredToolTip(<label className="CorrectPresent">{getPosition(state?.currentUser?.questionFaced) || 0}</label>, true)}*/}
               {/*</td>*/}
               {/*<td className={classNames(styles.multirow)}>*/}
               {/*   {questionCorrectToolTip(<label className="CorrectPresent"><>{getPosition(state?.currentUser?.rightAnswers) || 0}</>*/}
               {/*   </label>, true)}*/}
               {/*</td>*/}
               {/*<td className={classNames(styles.multirow)}>*/}
               {/*   {totalPredictionsToolTip(<label className="CorrectPresent"><>{getPosition(state?.currentUser?.predictionsFaced) || 0} </>*/}
               {/*   </label>, true)}*/}
               {/*</td>*/}
               {/*<td className={classNames(styles.multirow)}>*/}
               {/*   {correctPredictionsToolTip(<label className="CorrectPresent"><>{getPosition(state?.currentUser?.predictionsCorrect) || 0} </>*/}
               {/*   </label>, true)}*/}
               {/*</td>*/}
               {/*<div className={classNames(styles.accordionBtnWrapper)}>*/}
               {/*   {showMain && (*/}
               {/*      <i onClick={toggleMain} className={classNames(styles.accordionBtnUp, "fa fa-caret-up")} aria-hidden="true"></i>*/}
               {/*   )}*/}
               {/*   {!showMain && (*/}
               {/*      <i onClick={toggleMain} className={classNames(styles.accordionBtnDown, "fa fa-caret-down")} aria-hidden="true"></i>*/}
               {/*   )}*/}
               {/*</div>*/}
            </tr>
            {showMain ? (
               <tr id="main" className={classNames(styles.mobileCellRow)}>
                  <td className={classNames(styles.mobileCell)}>
                     <div className={classNames(styles.mobileCelltitle)}>{t("LEADER_BOARD.LAST_5_QUESTIONS")}</div>
                     <div className="">
                        {state.currentUser?.history?.slice(0, 5).reverse().map((hisObj) => getCorrectWrongIconByHistoryStatus(hisObj?.status))}
                        {getGreenOrPurpleCap(state.currentUser)}
                     </div>
                  </td>
                  <td className={classNames(styles.mobileCell)}>
                     <div className="">
                        <div className={classNames(styles.mobileCelltitle)}>{t("LEADER_BOARD.AVERAGE_POINTS")}</div>
                        {avgRightAnsToolTip(<label className="CorrectPresent">
                           <>{(state.currentUser?.average && state.currentUser?.average?.toFixed(2)) || 0}<span>(AVG.)</span></>
                        </label>, true)}
                     </div>
                  </td>
                  <td className={classNames(styles.mobileCell)}>
                     <div className={classNames(styles.mobileCelltitle)}>{t("LEADER_BOARD.GAMES_PLAYED")}</div>
                     {numOfMatchesAnsToolTip(<label className="CorrectPresent">
                        <>{state.currentUser?.matches || 0} <span className={classNames(styles.tableText)}>(MTS.)</span></>
                     </label>, true)}
                  </td>
               </tr>
            ) : <></>}
         </>)
   };

   const gotItHandler = () => {
      setNextGotItIndex(nextGotItIndex + 1)
      setTipIndexCounter(tipIndexCounter + 1)
   }

   const tipCancelHandler = () => {
      setNextGotItIndex(-1)
   }
   return (
      <div className={classNames(styles.bodyLeaderBoardOld, "ps-2 pe-2 ps-xxl-0 pe-xxl-0")}>
         {(selectedRadio == 'global' && connectPubnub && pubNubToken != null && props.isAuthenticated) ?
            <GlobalLeaderBoardHandler onRecieved={messageFromTournamentHandlerCallback} pubNubToken={pubNubToken} subscribe={subscribeChannel}
                                      onUnsubscribe={unSubscribeChannel}/> : <></>}
         {(selectedRadio == 'tournament' && lastTournamentId != -1 && connectRewardsPubnub && pubnubRewardsToken != null && props.isAuthenticated) ?
            <TournamentPubnubHandler
               onTeamPoints={callbackFromTeamPoints}
               onDataReceived={messageFromRewardsCallback}
               onUserLeaderboardReceived={messageFromUserLeaderboardCallback}
               onLeaderboardReceived={messageFromLeaderboardCallback}
               token={pubnubRewardsToken}
               tournamentId={lastTournamentId}
               userName={props.userName}
               page={0}
               size={5}/>
            : <>
               {console.log(
                  selectedRadio == 'tournament',
                  lastTournamentId != -1,
                  connectRewardsPubnub,
                  pubnubRewardsToken != null,
                  props.isAuthenticated
               )}
            </>}

         <GuidanceTip page="leaderboard" gotItIndex={2} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler} tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>
         <GuidanceTip page="leaderboard" gotItIndex={3} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler} tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>
         <GuidanceTip page="leaderboard" gotItIndex={4} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler} tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>
         <GuidanceTip page="leaderboard" gotItIndex={5} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler} tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>

         <div className={classNames(styles.pageWrapper)}>
            <div className={classNames(styles.ResponceCont, "col-12", "row", "pb-2", "ps-2", "pe-2", "ps-xl-0", "pe-xl-0")}>
               <div className="col-12 p-0 row">
                  <NavbarSecondary page="events"/>
               </div>

               {(state?.isGettingLeaderBoardUsers && !(state?.isClickedColumnHandler)) ? <div className="text-center">
                  <div className={styles.loaderContainer}>
                     <div className="lds-facebook">
                        <div></div>
                        <div></div>
                        <div></div>
                     </div>
                  </div>
               </div> : <div className="container p-0">
                  <div className="row gx-0">
                     <div className="col-md-12 col-xl-12">
                        {(!firstThreeLBUsers?.[0] && selectedRadio === 'global') ? <div
                              className={classNames(styles.notFound, "gray-text", "font18")}>{t("LEADER_BOARD.LEADERBOARD_NOT_AVAILABLE")}</div>
                           : <>

                              <div className={classNames(styles.topLeadersRow, "row gx-0")}>
                                 <div className="col-md-12">

                                    <div className="row gx-0">
                                       <div className="col-12 ps-3 pe-3">
                                          {/* <div className={classNames(styles.topBtnWrapper)}>
                                            <button className="blackButton" onClick={goBackHandler} style={{float: "left"}}>
                                                  <BsChevronLeft style={{right: 12}} />
                                                  BACK
                                            </button>

                                          </div> */}
                                          <div className="fc-wrapper matches-radio-wrapper row mb-4 mb-sm-0 d-flex justify-content-start">
                                             <label className="radioButtonlbl order-sm-2 order-2 m-0 pe-2 col-md-auto col-6 p-0 mb-2 mb-sm-0">
                                                <input
                                                   type="radio"
                                                   name="radio"
                                                   value="global"
                                                   checked={selectedRadio === 'global'}
                                                   onChange={handleRadioChange}
                                                />
                                                <span>{t("LEADER_BOARD.GLOBAL")}</span>
                                             </label>
                                             <label className="radioButtonlbl order-sm-2 order-2 m-0 pe-0 pe-sm-2 col-md-auto col-6 p-0 mb-2 mb-sm-0">
                                                <input
                                                   type="radio"
                                                   name="radio"
                                                   value="tournament"
                                                   checked={selectedRadio === 'tournament'}
                                                   onChange={handleRadioChange}
                                                />
                                                <span>{t("LEADER_BOARD.TOURNAMENT")}</span>
                                             </label>
                                          </div>
                                       </div>
                                       {selectedRadio == "global" ?
                                          <div className="col-12">
                                             <h2 className={classNames(styles.sectionTitle)}>{t("LEADER_BOARD.TOP_LEADERS")}</h2>

                                             {(getConf(LEADERBOARD_PAGE.SOCIAL_SHARE_BUTTON) && props.isAuthenticated) ? <button onClick={() => {
                                                resultShareModalHandler(true)
                                             }} className={classNames(styles.leaderBoardShareBtn, "blackBtn")}>
                                                <BsBoxArrowUp/>{t("LEADER_BOARD.SHARE_RESULT")}
                                             </button> : <></>}
                                          </div> : <></>}
                                    </div>
                                    {/* Top Leaders UI */}

                                    {selectedRadio == "global" ?
                                       <div className="col-12" id="leaderboardCapture">
                                          <div className={classNames(styles.horizontalDividerGradient)}></div>
                                          <div className={classNames(styles.OverviewBox, "p-0")}>
                                             <div className={classNames(styles.SplashBox, "col-11", "p-0 p-sm-3 pt-3", "row")}>

                                                {/* 2nd place user */}
                                                <div className={classNames(styles.leaderBox, "col-3", "p-0")}>
                                                   <div className={classNames(styles.curcleOut1)}>
                                                      <div className={classNames(styles.upChevron)}></div>
                                                      <div className={classNames(styles.outCurcleWrapper)}>
                                                         <div className={classNames(styles.OutCurcle)}>
                                                            {getProfileImage(firstThreeLBUsers?.[1]?.profilePicUrl, firstThreeLBUsers?.[1]?.firstName, firstThreeLBUsers?.[1]?.lastName, true)}
                                                         </div>
                                                         <div className={classNames(styles.curcleCount)}>2</div>
                                                      </div>

                                                   </div>
                                                   <Link to={firstThreeLBUsers?.[1]?.private ? '' : {
                                                      pathname: `/user/${btoa(firstThreeLBUsers?.[1]?.userName)}`,
                                                      search: `returnUrl=${encodeURIComponent(location.pathname)}`
                                                   }}>
                                                      <div
                                                         className={classNames("body-text2 color-gray2", "text-center", "mt-3", "mb-3")}>{getDisNameForFirst3Users(firstThreeLBUsers?.[1]?.firstName, firstThreeLBUsers?.[1]?.lastName, firstThreeLBUsers?.[1]?.userName)[0]}<br/>
                                                         {getDisNameForFirst3Users(firstThreeLBUsers?.[1]?.firstName, firstThreeLBUsers?.[1]?.lastName, firstThreeLBUsers?.[1]?.userName)[1]} {firstThreeLBUsers?.[1]?.private ?
                                                            <i className="fa-sharp fa-solid fa-lock"
                                                               style={{
                                                                  fontSize: "12px",
                                                                  color: "FF0000"
                                                               }}></i> : <></>}
                                                      </div>
                                                   </Link>
                                                   <div className={classNames(styles.marks, "text-center")} style={{lineHeight: "25px"}}>
                                                      <i className="fa-sharp fa-solid fa-trophy"></i>
                                                      <span>
                                                                    {formatNumberToK(firstThreeLBUsers?.[1]?.points, 2)}
                                                                </span>
                                                   </div>
                                                </div>

                                                {/*1st place user*/}
                                                <div className={classNames(styles.leaderBox, "col-6", "p-0")}>
                                                   <div className={classNames(styles.curcleOut2)}>
                                                      <div className={classNames(styles.upDown)}><img
                                                         src="https://storage.googleapis.com/meta11/serviceplatform/CrownVector.webp"
                                                         alt="Crown Icon"/></div>
                                                      <div className={classNames(styles.outCurcleWrapper)}>
                                                         <div className={classNames(styles.OutCurcle)}>
                                                            {getProfileImage(firstThreeLBUsers?.[0]?.profilePicUrl, firstThreeLBUsers?.[0]?.firstName, firstThreeLBUsers?.[0]?.lastName, true)}
                                                         </div>
                                                         <div className={classNames(styles.curcleCount)}>1</div>
                                                      </div>

                                                   </div>
                                                   <Link to={firstThreeLBUsers?.[0]?.private ? '' : {
                                                      pathname: `/user/${btoa(firstThreeLBUsers?.[0]?.userName)}`,
                                                      search: `returnUrl=${encodeURIComponent(location.pathname)}`
                                                   }}>
                                                      <div
                                                         className={classNames(styles.trophyTextW, "text-center", "mt-3", "mb-3")}>{getDisNameForFirst3Users(firstThreeLBUsers?.[0]?.firstName, firstThreeLBUsers?.[0]?.lastName, firstThreeLBUsers?.[0]?.userName)[0]}<br/>
                                                         {getDisNameForFirst3Users(firstThreeLBUsers?.[0]?.firstName, firstThreeLBUsers?.[0]?.lastName, firstThreeLBUsers?.[0]?.userName)[1]} {firstThreeLBUsers?.[0]?.private ?
                                                            <i className="fa-sharp fa-solid fa-lock"
                                                               style={{
                                                                  fontSize: "12px",
                                                                  color: "FF0000"
                                                               }}></i> : <></>}
                                                      </div>
                                                   </Link>
                                                   <div className={classNames(styles.marks, "text-center")} style={{lineHeight: "25px"}}>
                                                      <i className="fa-sharp fa-solid fa-trophy"></i>
                                                      <span>
                                                                    {formatNumberToK(firstThreeLBUsers?.[0]?.points, 2)}
                                                                </span>
                                                   </div>
                                                </div>

                                                {/*3rd place user*/}
                                                <div className={classNames(styles.leaderBox, "col-3", "p-0")}>
                                                   <div className={classNames(styles.curcleOut1)}>
                                                      <div className={classNames(styles.downChevron)}></div>
                                                      <div className={classNames(styles.outCurcleWrapper)}>
                                                         <div className={classNames(styles.OutCurcle)}>
                                                            {getProfileImage(firstThreeLBUsers?.[2]?.profilePicUrl, firstThreeLBUsers?.[2]?.firstName, firstThreeLBUsers?.[2]?.lastName, true)}
                                                         </div>
                                                         <div className={classNames(styles.curcleCount)}>3</div>
                                                      </div>

                                                   </div>
                                                   <Link to={firstThreeLBUsers?.[2]?.private ? '' : {
                                                      pathname: `/user/${btoa(firstThreeLBUsers?.[2]?.userName)}`,
                                                      search: `returnUrl=${encodeURIComponent(location.pathname)}`
                                                   }}>
                                                      <div
                                                         className={classNames("body-text2 color-white", "text-center", "mt-3", "mb-3")}>{getDisNameForFirst3Users(firstThreeLBUsers?.[2]?.firstName, firstThreeLBUsers?.[2]?.lastName, firstThreeLBUsers?.[2]?.userName)[0]}<br/>
                                                         {getDisNameForFirst3Users(firstThreeLBUsers?.[2]?.firstName, firstThreeLBUsers?.[2]?.lastName, firstThreeLBUsers?.[2]?.userName)[1]} {firstThreeLBUsers?.[2]?.private ?
                                                            <i className="fa-sharp fa-solid fa-lock"
                                                               style={{
                                                                  fontSize: "12px",
                                                                  color: "FF0000"
                                                               }}></i> : <></>}
                                                      </div>
                                                   </Link>
                                                   <div className={classNames(styles.marks, "text-center")} style={{lineHeight: "25px"}}>
                                                      <i className="fa-sharp fa-solid fa-trophy"></i>
                                                      <span>
                                                                    {formatNumberToK(firstThreeLBUsers?.[2]?.points, 2)}
                                                                </span>
                                                   </div>
                                                </div>
                                                <GuidanceTip page="leaderboard" gotItIndex={0} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler} tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>

                                             </div>

                                          </div>
                                          <div className={classNames(styles.horizontalDividerGradient)}></div>
                                       </div> : <></>}
                                 </div>
                              </div>

                              <div className="row gx-0 mt-4">
                                 <div className="col-12 ">
                                    {/* <h2 className={classNames(styles.sectionTitle, "w-auto  mt-4 mb-4")} style={{display:"none"}}>Global Leaderboard
                                        Tournament Leaderboard
                                      </h2> */}
                                    <h1 className={classNames(styles.sectionTitle, "mt-4 mb-4 w-100 mainHeaderH1")}>
                                       {selectedRadio == "global" ? <>
                                          {t("LEADER_BOARD.GLOBAL")} {t("LEADER_BOARD.LEADERBOARD")}</> : <>{t("LEADER_BOARD.TOURNAMENT")} {t("LEADER_BOARD.LEADERBOARD")} </>}
                                    </h1>
                                    <div className="col-12 p-0 mt-3">
                                       {selectedRadio == "global" ? <></> : <>
                                          {tournamentCards && tournamentCards[0]?.id ?
                                             <>
                                                {tournamentCards.map(tournament => (
                                                   <button
                                                      style={{border: (tournament.id === lastTournamentId) ? '1px solid #FAFF00' : undefined}}
                                                      key={tournament.id}
                                                      className={classNames("blackBtn roundBtn me-3 mb-2 mt-2 mt-sm-0 capitalize ", lastTournamentId == tournament.id ? "selectedBtn" : "")}
                                                      onClick={() => {
                                                         changeTournamentId(tournament.id)
                                                      }}
                                                   >
                                                      {tournament.description}
                                                   </button>
                                                ))}
                                             </> :
                                             <></>
                                          }
                                       </>
                                       }
                                    </div>

                                    <GuidanceTip page="leaderboard" gotItIndex={1} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler} tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>

                                 </div>
                              </div>
                              <div className={classNames(styles.leaderboardRow, styles.Globalleaderboard, "row gx-4")}>
                                 {getLeaderboard(<div className="text-center">
                                       <div style={{width: '500px', height: '500px', paddingTop: "150px", paddingLeft: "350px"}}>
                                          <div className="lds-facebook">
                                             <div></div>
                                             <div></div>
                                             <div></div>
                                          </div>
                                       </div>
                                    </div>, <>
                                       <div className={classNames(styles.LeaderBoardTblWrapper,"col-md-12 overflow-auto")}>
                                          {/* Leaderboard UI */}
                                          {/*table*/}
                                          <table border="0" cellPadding="0" cellSpacing="0"
                                                 className={classNames(styles.leaderBoard)} style={{border: "0"}}>
                                             <thead>
                                             <tr>
                                                <th onClick={() => {
                                                   columnHandler('rank')
                                                }}>&nbsp;&nbsp;&nbsp;&nbsp;{t("LEADER_BOARD.NAME")}</th>
                                                <th style={{cursor: 'default'}}>{t("LEADER_BOARD.NAME_PLAYERS")}</th>
                                                <th className={classNames((state.sortingEnabledPage === 'rank') && isSorted ? styles.selectedcol : '')} onClick={() => {
                                                   columnHandler('points')
                                                }}>
                                                   <div className={classNames(styles.titleWrapper)}>
                                                      <div>{t("LEADER_BOARD.TOTAL_POINTS")}</div>
                                                      <div className={classNames(styles.sort)}></div>
                                                   </div>
                                                </th>
                                                <th onClick={() => {
                                                   columnHandler('fantasyPoints')
                                                }}>
                                                   <div className={classNames(styles.titleWrapper)}>
                                                      <div>{t("LEADER_BOARD.FANTASY_POINTS")}</div>
                                                      <div className={classNames(styles.sort)}></div>
                                                   </div>
                                                </th>
                                                <th className={classNames((state.sortingEnabledPage === 'games_played') && isSorted ? styles.selectedcol : '')} onClick={() => {
                                                   columnHandler('games_played')
                                                }}>
                                                   <div className={classNames(styles.titleWrapper)}>
                                                      <div>{t("LEADER_BOARD.GAMES_PLAYED")}</div>
                                                      <div className={classNames(styles.sort)}></div>
                                                   </div>
                                                </th>
                                                <th className={classNames((state.sortingEnabledPage === 'avg') && isSorted ? styles.selectedcol : '')} onClick={() => {
                                                   columnHandler('average')
                                                }}>
                                                   <div className={classNames(styles.titleWrapper)}>
                                                      <div>{t("LEADER_BOARD.POINTS_PER_GAME")}</div>
                                                      <div className={classNames(styles.sort)}></div>
                                                   </div>
                                                </th>
                                             </tr>
                                             </thead>
                                             {typicalLBUsers && typicalLBUsers.length > 0 && (
                                                typicalLBUsers.map((leader) => leaderBoardTypicalUserTableRow(leader))
                                             )}
                                             {(!isPresentCurrentUserInLBUsers && props.isAuthenticated && state.currentUser) ? currentUserScoreTableRow() : <></>}
                                          </table>
                                       </div>
                                       <div className={classNames(styles.PaginatorWrapper,"p-0")}>
                                          <div className="pageWrap" style={{marginTop: "45px"}}>
                                             <Paginator postsPerPage={postsPerPage} totalElements={state?.totalElements} currentPage={currentPage} paginate={paginate} style/>
                                          </div>
                                       </div>
                                    </>,
                                    <div className={classNames(styles.notFound, "gray-text", "font18")}>{rejectMsg ? rejectMsg : t("LEADER_BOARD.NO_ACTIVE_TOURNAMENTS_AVAILABLE")}</div>)}
                              </div>
                           </>}
                     </div>
                     {/* <div className="col-md-12 col-xl-3">
                                <div className={classNames(styles.rightBannerRow, "row")}>
                                    <div className="col-md-12">
                                        <img
                                            src="https://storage.googleapis.com/meta11/serviceplatform/StaticBanner4.webp"
                                            alt="Banner Image"/>
                                    </div>
                                </div>
                                <div className={classNames(styles.rightBannerRow, "row")}>
                                    <div className="col-md-12">
                                        <img
                                            src="https://storage.googleapis.com/meta11/serviceplatform/StaticBanner1.webp"
                                            alt="Banner Image"/>
                                    </div>
                                </div>
                            </div> */}
                  </div>
               </div>}
            </div>

            {/* <div className={classNames(styles.topBgGradientGreen-)}></div>
                <div className={classNames(styles.centerBgGradientRed-)}></div>
                <div className={classNames(styles.rightBgGradientGreen-)}></div> */}

            <div className={state.isGettingLeaderBoardUsers ? classNames(styles.Footer) : undefined}>
               <Footer/>

            </div>
            <LeaderBoardModal
               isShow={state.isShowingModal}
               setShow={showAllCloseHandler}
               initialUsers={firstThreeLBUsers}
               totalElements={state.totalElements}
               maxAvgRightAnsUser={state.maxAvgRightAnsUser}
               currentUser={state.currentUser}
               location={location}
            />
            <SocialShareModal
               isShowModal={state.isShowingSocialModal}
               modalCloseHandler={resultShareModalHandler}
               sharePath={'/leaderboard'}
               currentUser={state?.currentUser}
               isAuthenticated={props.isAuthenticated}
               isSharingPoints={true}
            />
         </div>
         {showFantasyTeamModal && <ViewFantasyTeamModal tournamentId={lastTournamentId}
                                                        isDisplayHidden={isDisplayHiddenFantasyTeamModal}
                                                        handleModalClose={() => setShowFantasyTeamModal(false)}
                                                        onCalendarClick={onCalendarClick} onInfoClick={onInfoClick}/>}
         {showPlayerInfoModal && <PlayerInfoModal playerId={selectedPlayerId}
                                                  tournamentId={lastTournamentId}
                                                  handleModalClose={() => {
                                                     setShowPlayerInfoModal(false);
                                                     setIsDisplayHiddenFantasyTeamModal(false);
                                                     setSelectedPlayerId(null);
                                                  }}/>}
         {showFantasyMatchHistoryModal && <FantasyMatchHistoryModal playerId={selectedPlayerId}
                                                                    tournamentId={lastTournamentId}
                                                                    playerImage={selectedPlayerImage}
                                                                    playerName={selectedPlayerName}
                                                                    handleModalClose={() => {
                                                                       setShowFantasyMatchHistoryModal(false);
                                                                       setIsDisplayHiddenFantasyTeamModal(false);
                                                                       setSelectedPlayerId(null);
                                                                       setSelectedPlayerImage(null);
                                                                       setSelectedPlayerName(null);
                                                                    }}/>}
      </div>
   );
}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null && state.auth.accessToken !== undefined,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      token: state.auth.accessToken,
   };
};

export default connect(mapStateToProps)(LeaderBoard);
