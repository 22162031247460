import React, {useEffect, useState} from "react";
import Styles from "./SocialButton.module.scss"
import * as UserService from '../../services/UserService'
import {useNavigate} from "react-router-dom";
import Classnames from "classnames";
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import {useGoogleLogin} from "@react-oauth/google";
import axios from "axios";
import {toast} from "react-toastify";
import {AUTHENTICATION_TOASTS} from "../../utilities/ToastMessages";
import {authRedirectHandler} from "../../helpers/CommonFunctions";
import {AUTHENTICATION_TYPES} from "../../utilities/Constants";


function GoogleLoginButton(props) {
   const [user, setUser] = useState(null);
   const [click, setClick] = useState(false);
   const [profile, setProfile] = useState(null);
   let navigate = useNavigate();
   const login = useGoogleLogin({
      onError: (error) => console.log('Login Failed:', error),
      onSuccess: (codeResponse) => setUser(codeResponse),
      onNonOAuthError: () => handleClickClose()
   });
   useEffect(
      () => {
         setClick(props.disable)
         if (user) {
            axios
               .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                  headers: {
                     Authorization: `Bearer ${user.access_token}`,
                     Accept: 'application/json'
                  }
               })
               .then((res) => {
                  setProfile(res.data);
                  onResponse(res.data, user.access_token)
               }).then(

            )
               .catch((err) => console.log(err));

         }
      },
      [user, props.disable]
   );
   const handleDisableClick = () => {
      setClick(true);
      props.disableClick(true)
   }
   const handleClickClose = () => {
      setClick(false);
      props.disableClick(false)
   }
   const onResponse = async (profile, user) => {
      const data = {
         "accessToken": user,
         "oAuthProviderType": "google",
         "profilePicURL": profile.picture,
         "userName": profile.email,
      }

      const socialResponse = await UserService.socialLogin(data)

      if (socialResponse.loginStatus === true) {
         // console.log(socialResponse.sessionExpireTime)
         props.onAuth(socialResponse.accessToken, socialResponse.refreshToken, "GOOGLE", socialResponse.user?.profilePicURL, socialResponse.userName, (socialResponse.firstName + " " + socialResponse.lastName ?? ""), profile.email)
         localStorage.setItem("previouslyLogged", !socialResponse?.newUser)
         if (props.type == AUTHENTICATION_TYPES.REGISTER) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
               'event': 'signup',
               'type': 'google',
               'timeStamp': Date.now()
            });
         }
         authRedirectHandler(navigate)
      } else {
         toast.error(AUTHENTICATION_TOASTS.INVALID_GOOGLE_LOGIN);
         return navigate("/login");
      }
   };

   return (
      <button disabled={click}
              className={Classnames(Styles.BlackSkBtn)}
              onClick={() => {
                 handleDisableClick()
                 login()
              }}>
         <text>GOOGLE</text>
      </button>
   )
}

const mapStateToProps = state => {
   return {
      isAuthenticated: state.auth.token !== null,
   }
}

const mapDispatchToProps = dispatch => {
   return {
      onAuth: (accessToken, refreshToken, oAuthProviderType, imageURL, userName, name, email) => dispatch(actions.auth(accessToken, refreshToken, oAuthProviderType, imageURL, userName, name, email))
   }
}
export default connect(mapStateToProps, mapDispatchToProps)(GoogleLoginButton);
