//user service constants
export const ART_WORK_TYPES = Object.freeze({
   UPLOADED: 'UPLOADED',
   OWNED: 'OWNED',
})

export const VOTE_TYPES = Object.freeze({
   UP: 'UP',
   DOWN: 'DOWN',
})

export const TEAM_KIT_SPONSORS = Object.freeze({
   KIT: 'KIT',
   SPONSOR: 'SPONSOR',
})

export const NOTIFICATION_TYPES = Object.freeze({
   COMMUNITY: 'COMMUNITY',
   FAVORITE_PLAYER: 'FAVORITE_PLAYER',
   FAVOURITE_TEAM: 'FAVOURITE_TEAM',
   PASSWORD_CHANGE: 'PASSWORD_CHANGE',
   QUESTIONNAIRE: 'QUESTIONNAIRE',
   MATCH_START: 'MATCH_START',
   OMNI_PURCHASE: 'OMNI_PURCHASE',
})

export const MATCH_CARD_TYPES = Object.freeze({
   ALL: 'all',
   LIVE: 'live',
   UPCOMING: 'upcoming',
   COMPLETED: 'completed',
   PURCHASED: 'user_purchased',
})

export const PROFILE_PRIVACY = Object.freeze({
   PRIVATE: 'Private',
   PUBLIC: 'Public',
})

export const SOCIAL_MEDIA_TYPES = Object.freeze({
   FACEBOOK: 'Facebook',
   INSTAGRAM: 'Instagram',
   TWITTER: 'Twitter',
   TIKTOK: 'Tiktok'
})

export const ASSET_UPLOAD_TYPES = Object.freeze({
   COMMUNITY: 'community',
   PROFILE: 'profile',
   COVER: 'cover'
})

export const SOCKET_MESSAGES = Object.freeze({
   MATCH_STATUS: 'MATCH_STATUS',
   AUTH_RESPONSE: 'AUTH_RESPONSE',
   SUBSCRIPTION_RESPONSE: 'SUBSCRIPTION_RESPONSE',
   MATCH_CARD_STATUS: 'MATCH_CARD_STATUS',
   MATCH_CARD_DETAILS: 'MATCH_CARD_DETAILS',
})

export const SOCIAL_APP_ID = Object.freeze({

      GOOGLE: process.env.REACT_APP_GOOGLE_APP_ID,
      FACEBOOK: process.env.REACT_APP_FACEBOOK_APP_ID,

      // Prod
      // GOOGLE: '361788613743-ar35nneug8lauc81nhnb4j3tqt0nhgqg',
      // FACEBOOK: '503928771827305',

      // QA
      // GOOGLE: '299111371636-1lm77t1aviagpuee7g418gg6knp45cjc',
      // FACEBOOK: '5876277815754951',
   }
)
export const ENCRYPTION_KEY = Object.freeze({
   SECRET: "meta11qatestsecretkey"
})

export const BOT_STATUS = Object.freeze({
   INITIALIZED: 'INITIALIZED',
   ANSWERED: 'ANSWERED',
   EXPIRED: 'EXPIRED',
   RESPONSE: 'RESPONSE'
})

export const USER_PROFILE_CONSTANT = Object.freeze({
   MAX_USER_AGE: 100,
   MIN_USER_AGE: 18
})

export const PIC_UPLOAD_SIZES = Object.freeze({
   COVER: 20,
   PROFILE: 5
})
export const PUBNUB_KEYS = Object.freeze({
   PUBLISH_KEY: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
   SUBSCRIBE_KEY: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY
})

export const METALUX_TIMER = Object.freeze({
   TIMESTAMP: '2023-10-23T00:00:00.000+00:00'
})

export const AUTHENTICATION_TYPES = Object.freeze({
   LOGIN: 'login',
   REGISTER: 'register'
})

export const SITE_LANGUAGE = Object.freeze({
   ENGLISH: 'en',
   HINDI: 'hi'
})

export const TRANSACTION_STATUS = Object.freeze({
   SUCCESS: 'SUCCESS',
   ERROR: 'ERROR'
})

export const TOURNAMENT_TYPES = Object.freeze({
   LEAGUE: 'LEAGUE',
   KNOCKOUT: 'KNOCKOUT',
   LEAGUE_KNOCKOUT: 'LEAGUE_KNOCKOUT'
})

export const AUTHTOKEN_ERRORS = Object.freeze({
   AUTHENTICATION_TOKEN_HAS_EXPIRED: 'Authentication Token has Expired'
})

export const SCREEN_SIZES = Object.freeze({
   DESKTOP: 'desktop',
   TABLG: 'tablg',
   TABSM: 'tabsm',
   MOBILESM: 'mobilesm',
   MOBILELG: 'mobilelg'
})
