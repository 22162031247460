import React, {useEffect, useState} from "react";
import Footer from "../../../components/Footer/Footer";
import {connect} from "react-redux";
import Styles from "./LeagueKnockoutTournament.module.scss";
import Classnames from "classnames";
import NavbarSecondary from "../../../components/NavigationBar/NavigationBar";
import FirstPlace from "../../../assets/images/backgrounds/tournaments/1st_Prize.svg";
import SecondPlace from "../../../assets/images/backgrounds/tournaments/2nd_Prize.svg";
import ThirdPlace from "../../../assets/images/backgrounds/tournaments/3rd_Prize.svg";
import Line from "../../../assets/images/line.webp";
import Credit from "../../../assets/images/icons/24_sprts_token.webp";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import KnockoutMatchDraw from "../../../components/Tournament/KnockoutMatchDraw/KnockoutMatchDraw";
import TournamentMatches from "../../../components/Tournament/TournamentMatches";
import {useTranslation} from "react-i18next";
import * as StatisticsService from "../../../services/StatisticsService";
import Calendar from "../../../assets/images/fi_calendar.webp";
import {formatDate, formatTime} from "../../../helpers/CommonFunctions";
import WinPrizesSection from "../../../components/Tournament/WinPrizesSection/WinPrizesSection";
import TournamentLeaderBoard from "../../../components/Tournament/Leaderboard/TournamentLeaderBoard";
import Modal from "react-bootstrap/Modal";
import HowToWinRewards from "../../../components/Modals/HowToWinRewards/HowToWinRewards";
import * as TournamentService from "../../../services/TournamentService";
import {BsCheck2, BsDash, BsX} from "react-icons/bs";
import {MATCH_CARD_TYPES, SOCKET_MESSAGES} from "../../../utilities/Constants";
import * as PubNubService from "../../../services/PubNubService";
import PubNubMatchesHandler from "../../../components/Tournament/PubNubMatchesHandler";
import LatestMatch from "../LatestMatch";
import TournamentPubnubHandler from "../../../services/PubNubHandlers/TournamentPubNubHandler";
import PointTable from "../../../components/Tournament/PointTable/PointTable";
import * as actions from "../../../store/actions";
import TournamentFantasySection from "../../../components/Tournament/FantasyTeamSection/TournamentFantasySection";

const LeagueKnockoutTournament = (props) => {

   //Local State Management
   const [toggleDetails, setToggleDetails] = useState(true);
   const [selectedOption, setSelectedOption] = useState(sessionStorage?.getItem("SelectedOption")||'Matches');
   const [latestMatch, setLatestMatch] = useState(null);
   const [tournamentDetails, setTouranamentDetails] = useState(null);
   const [pointsLoaded, setPointsLoaded] = useState("loading");
   const [tournamentTeams, setTournamentTeams] = useState(null);
   const location = useLocation();
   let {tournamentId} = useParams();
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);
   const [isHowToModalOpen, setIsHowToModalOpen] = useState(false);
   const [currentTeamOne, setcurrentTeamOne] = useState();
   const [currentTeamTwo, setcurrentTeamTwo] = useState();
   const [currentTeamOneData, setCurrentTeamOneData] = useState();
   const [currentTeamTwoData, setCurrentTeamTwoData] = useState();
   const [lang, setLang] = useState(localStorage.getItem("language"));
   const [drawData, setDrawData] = useState([]);
   const [rewardsCategory, setRewardsCategory] = useState()
   const [currentPage, setCurrentPage] = useState(0);
   const [postsPerPage, setpostsPerPage] = useState(5);
   const [connectPubnub, setConnectPubnub] = useState(false);
   const [token, setToken] = useState(null);
   const [selectedRadioBtnValue, setSelectedRadioBtnValue] = useState('all');
   const [searchQuery, setSearchQuery] = useState('');
   const [subscribeChannel, setSubscribeChannel] = useState(null);
   const [subscribeToRewardsChannel, setSubscribeToRewardsChannel] = useState('rewards-' + tournamentId)
   let navigate = useNavigate();
   const pathname = window.location.pathname;
   const currentTime = new Date();
   const matchStartTime = new Date(latestMatch?.matchStartTime);
   const [pubnubRewardsToken, setPubnubRewardsToken] = useState(null)
   const [connectRewardsPubnub, setConnectRewardsPubnub] = useState(null);
   const [unSubscribeChannel, setUnsubscribeChannel] = useState(null);
   let uuid = localStorage.getItem("uuid");
   const [loadingInProgress, setLoading] = useState(true);
   const [leaderBoardData, setLeaderBoardData] = useState();
   const [leaderBoardUserData, setLeaderBoardUserData] = useState();

   useEffect(() => {
      setToggleDetails(selectedOption === 'Details' ? true : false);
   }, [selectedOption]);

   useEffect(() => {
      const checkTournamentRegistration = async () => {
         sessionStorage.setItem('redirectPath', pathname)
         if (!localStorage.getItem('accessToken')) {
            navigate("/login");
         } else {
            try {
               const response = await TournamentService.checkTournamentRegistration(tournamentId, props.userName);
               if (response.apiStatus) {
                  if (!response.available) {
                     navigate("/tournaments");
                  }
               } else {
                  navigate("/tournaments");
               }

            } catch (error) {
               console.log(error);
               throw error;
            }
         }

      }
      checkTournamentRegistration();
   }, [tournamentId, props.userName]);

   useEffect(() => {
      const fetchData = async () => {
         if (props.isAuthenticated) {
            try {
               PubNubService.requestAccessTokenQuiz(props.userName, '1234', Number(tournamentId), props.Token).then(response => {
                  if (response != null) {
                     setPubnubRewardsToken(response);
                     setConnectRewardsPubnub(true);
                     localStorage.setItem("pubnubRewardToken", response);
                  }
               });
            } catch (error) {
               console.error("Error:", error);
            }
         }
      }

      fetchData();
   }, [props.userName, loadingInProgress]);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   useEffect(() => {
      const fetchData = async () => {
         console.log("run1");
         if (localStorage.getItem("pubnubToken") == null) {
            console.log("run");
            try {
               const response = await PubNubService.requestAccessTokenMatches(uuid, props.Token).then(response => {
                  if (response != null) {
                     setToken(response);
                     setConnectPubnub(true);
                     console.log(response);
                     localStorage.setItem("pubnubToken", response);
                  }
               })

            } catch (error) {
               console.error("Error:", error);
            }
         } else {
            setConnectPubnub(true);
            setToken(localStorage.getItem("pubnubToken"));
         }
      };
      fetchData();
   }, [uuid]);

   useEffect(() => {
      const fetchTournamentTeams = async () => {
         return await TournamentService.getTournamentTeams(props.Token, tournamentId, lang).then((response) => {
            if (response.apiStatus) {
               setTournamentTeams(response.teams);
               if (response?.teams) {
                  setPointsLoaded(true);
               } else {
                  setPointsLoaded(false);
               }
            } else {
               setPointsLoaded(true);
            }
         });
      };

      fetchTournamentTeams();

   }, []);

   const getMatchStatus = (matchStatus) => {
      if (matchStatus) {
         switch (matchStatus) {
            case "WIN":
               return (<div className="matchIndicator_tournament greenInd"><BsCheck2/></div>);
               break;
            case "LOST":
               return (<div className="matchIndicator_tournament purpleInd"><BsX/></div>);
               break;
            case "DRAW":
               return (<div className="matchIndicator_tournament grayInd"><BsDash/></div>);
               break;
            default:
               return (<div className="matchIndicator_tournament grayInd"><BsDash/></div>);
               break;
         }
      } else {
         return (<div className="matchIndicator_tournament grayInd"><BsDash/></div>);
      }
   };

   useEffect(() => {
      const fetchData = async (teamOneId, teamTwoId) => {
         if (teamOneId) {
            await StatisticsService.getTeamMatchesHistoryById(tournamentId, teamOneId)
               .then((response) => {
                  if (response.apiStatus) {
                     setCurrentTeamOneData(response);
                  }
               });
         }

         if (teamTwoId) {
            await StatisticsService.getTeamMatchesHistoryById(tournamentId, teamTwoId)
               .then((response) => {
                  if (response.apiStatus) {
                     setCurrentTeamTwoData(response);
                  }
               });
         }
      };
      fetchData(currentTeamOne, currentTeamTwo);
   }, [currentTeamOne, currentTeamTwo]);

   useEffect(() => {
      console.log(tournamentId)
      const fetchData = async () => {
         await TournamentService.getUserRewardCategory(tournamentId)
            .then((response) => {
               if (response.apiStatus && response.rewardCategoryList) {
                  setRewardsCategory(response.rewardCategoryList);
               }
            })
      };
      fetchData();
   }, [tournamentId]);

   function getRewardForPosition(position, rewardCategoryList) {

      if (!Array.isArray(rewardCategoryList)) {
         return 0;
      }
      console.log("rewardCategoryList", rewardCategoryList);
      for (const category of rewardCategoryList) {
         const {minRank, maxRank, reward} = category.userRanking;
         if (position >= minRank && position <= maxRank) {
            if (tournamentDetails.tournamentTicketOmniCredits) {
               return reward;
            } else {
               return (reward / 250).toFixed(3);
            }
         }
      }
      return 0;
   }

   useEffect(() => {
      setTranslationsLoaded(false);
      const fetchData = async () => {

         await StatisticsService.getAllMatchCardsByTournamentId("", 0, 1, "isLive,isScheduled,matchId,desc", "", tournamentId, lang, props.Token)
            .then(async (response) => {
               if (response.apiStatus && response.totalElements != 0) {
                  setTranslationsLoaded(true);
                  if (response?.matches?.[0]) {
                     setLatestMatch(response?.matches[0])
                     if (response?.matches[0]) {
                        setcurrentTeamOne(response.matches[0].team1Id);
                        setcurrentTeamTwo(response.matches[0].team2Id);
                     }
                  } else {
                     setLatestMatch(null);
                  }
               } else if (response.totalElements == 0) {
                  setTranslationsLoaded(true);
                  await StatisticsService.getAllMatchCardsByTournamentId("", 0, 1, "isLive,isScheduled,matchId,desc", "completed", tournamentId, lang, props.Token)
                     .then((response) => {
                        if (response.apiStatus && response.totalElements != 0) {
                           if (response?.matches[0]) {
                              setLatestMatch(response?.matches[0])
                              if (response.matches[0]) {
                                 setcurrentTeamOne(response.matches[0].team1Id);
                                 setcurrentTeamTwo(response.matches[0].team2Id);
                              }
                           } else {
                              setLatestMatch(null);
                           }
                        }

                     })
               }
            });
      };
      fetchData();
   }, [selectedOption]);

   useEffect(() => {
      const fetchTournamentDetails = async () => {
         await TournamentService.getKnockoutTournamentById(props.Token, tournamentId, lang).then((response) => {
            if (response.apiStatus) {
               setTouranamentDetails(response?.knockoutTournament?.tournament);
               setDrawData([response?.knockoutTournament]);
            }
         });
      };

      fetchTournamentDetails();
   }, [selectedOption, rewardsCategory]);

   const returnLatestMatch = () => {
      return (
         <LatestMatch tournamentDetails={tournamentDetails} latestMatch={latestMatch}/>
      )
   }

   useEffect(() => {
      returnLatestMatch();
   }, [latestMatch]);


   const overCount = (teamOvers, teamBalls) => {

      const overs = teamBalls !== 6
         ? teamOvers - 1
         : teamOvers

      const balls = teamBalls !== 6
         ? teamBalls
         : 0
      return overs + "." + balls;

   }

   const winPrices = (winPriceData) => {
      if (winPriceData?.tournamentRank == 1) {
         return (
            <>
               <div className={Classnames(Styles.FirstPlace)}>
                  <img src={FirstPlace}/>
                  <div className={Classnames(Styles.CreditWrapper)}>
                     {/*<div>*/}
                     {/*   <img src={Ticket}/>*/}
                     {/*   <sapan>5</sapan>*/}
                     {/*</div>*/}
                     <div>
                        <img src={Credit}/>
                        {getRewardForPosition(winPriceData?.tournamentRank, rewardsCategory)}
                     </div>
                  </div>
               </div>
               <div className={Classnames(Styles.LineWrapper)}>
                  <img src={Line}/>
               </div>
            </>

         );
      } else if (winPriceData?.tournamentRank == 2) {
         return (
            <>
               <div className={Classnames(Styles.SecondPlace)}>
                  <img src={SecondPlace}/>
                  <div className={Classnames(Styles.CreditWrapper)}>
                     {/*<div>*/}
                     {/*   <img src={Ticket}/>*/}
                     {/*   <sapan>5</sapan>*/}
                     {/*</div>*/}
                     <div>
                        <img src={Credit}/>
                        {getRewardForPosition(winPriceData?.tournamentRank, rewardsCategory)}
                     </div>
                  </div>
               </div>
               <div className={Classnames(Styles.LineWrapper)}>
                  <img src={Line}/>
               </div>
            </>

         );
      } else if (winPriceData?.tournamentRank == 3) {
         return (
            <div className={Classnames(Styles.ThirdPlace)}>
               <img src={ThirdPlace}/>
               <div className={Classnames(Styles.CreditWrapper)}>
                  {/*<div>*/}
                  {/*   <img src={Ticket}/>*/}
                  {/*   <sapan>5</sapan>*/}
                  {/*</div>*/}
                  <div>
                     <img src={Credit}/>
                     {getRewardForPosition(winPriceData?.tournamentRank, rewardsCategory)}
                  </div>
               </div>
            </div>
         )
      }
   }

   const openHowtoWinRewardsModal = () => {
      setIsHowToModalOpen(true);
   }

   function handleMoreInfoClose() {
      setIsHowToModalOpen(false);
   }

   const loadingIndicator = (
      <div className={Classnames(Styles.loaderContainer)}>
         <div className="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
         </div>
      </div>
   );

   const messageFromMatchesCallback = (message) => {
      // console.log("from matches callback", JSON.parse(message))

      try {
         let json = JSON.parse(message);
         console.log(json);

         if (currentPage == 0 && searchQuery == "") {

            if (selectedRadioBtnValue == MATCH_CARD_TYPES.ALL || selectedRadioBtnValue == MATCH_CARD_TYPES.LIVE) {

               if (json.bodyType == SOCKET_MESSAGES.MATCH_CARD_DETAILS) {
                  setLatestMatch(json.body.matches[0]);
               } else {
                  setLoading(false);
               }
            }
         } else if (currentPage != 0) {
            if (selectedRadioBtnValue != MATCH_CARD_TYPES.ALL || selectedRadioBtnValue != MATCH_CARD_TYPES.LIVE) {
               setUnsubscribeChannel("Matches-" + tournamentId);
               setUnsubscribeChannel("Live-" + tournamentId)
            }
         }

      } catch (error) {
         console.error("JSON parsing error:", error);
      }
   }

   const messageFromRewardsCallback = (message) => {
      if (message) {
         setRewardsCategory(message.rewardCategoryList);
      } else {
         console.log(message);
      }
   }
   const messageFromUserLeaderboardCallback = (message) => {
      if (message) {
         console.log(message)
         let json = JSON.parse(message);
         setLeaderBoardUserData(json.body)
      } else {
         console.log(message);
      }
   }
   const messageFromLeaderboardCallback = (message) => {
      if (message) {
         console.log(message)
         let json = JSON.parse(message);
         setLeaderBoardData(json.body)
      } else {
         console.log(message);
      }
   }
   const callbackFromTeamPoints = (message) => {
      if (message) {
         console.log(message)
         setTournamentTeams(message?.teams);
      } else {
         console.log(message);
      }
   }
   const pageAndSizeFromLeaderboard = (data) => {
      console.log(data)
      setCurrentPage(data.page);
      setpostsPerPage(data.size)
   }
   const changeSelectedOption = (data) =>{
      setSelectedOption(data);
      sessionStorage.setItem("SelectedOption", data);
   }
   return (
      <React.Fragment>
         {(connectPubnub && (token != null)) ?
            <PubNubMatchesHandler onRecieved={messageFromMatchesCallback} token={token} subscribe={subscribeChannel}
                                  onUnsubscribe={unSubscribeChannel} tournamentId={tournamentId}/>
            : <></>}

         {(connectRewardsPubnub && pubnubRewardsToken != null && props.isAuthenticated) ?
            <TournamentPubnubHandler onTeamPoints={callbackFromTeamPoints} onDataReceived={messageFromRewardsCallback} onUserLeaderboardReceived={messageFromUserLeaderboardCallback} onLeaderboardReceived={messageFromLeaderboardCallback} token={pubnubRewardsToken} tournamentId={tournamentId}
                                     userName={props.userName} page={currentPage} size={postsPerPage}/>
            : <></>}

         <div className={Classnames(Styles.Radial1)}></div>
         <div className={Classnames(Styles.Radial2)}></div>
         <div className={Classnames(Styles.LeagueKnockoutTournament)}>
            <div className={Classnames(Styles.bodyTournaments)}>
               <div
                  className={Classnames(Styles.ResponceCont, "col-12", "row", "pb-2", "ps-2", "pe-2", "ps-xl-0", "pe-xl-0")}>
                  <div className="col-12 p-0 row">
                     <NavbarSecondary page="events"/>
                  </div>

                  {translationsLoaded ?
                     <div className={Classnames(Styles.pageWrapper, "col-12 p-0")}>
                        <h1>{tournamentDetails?.name}</h1>
                        <div className={Classnames(Styles.contnetWrapper)}>
                           <div className="row">
                              <div className="col-md-12 p-0 float-left pe-lg-4-">
                                 {/* Daily MatchesWrapper */}
                                 <div className={Classnames(Styles.DailyMatchesWrapper, Styles.MatchWrapper)}>
                                    <div className="row">
                                       <div className="col-md-12 p-0">
                                          <div className="row">
                                             <div className="col p-0">
                                                <div className={Classnames(Styles.LimitedTournamentHeader,"LimitedTournamentHeader")} style={{background: `url(${tournamentDetails?.logoUrl})`, backgroundRepeat: 'no-repeat'}}>
                                                   <div className="row">
                                                      <div className="col-md-12">
                                                         {returnLatestMatch()}
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    <div className="row">
                                       <div className="col p-0 mb-2">
                                          <div className={Classnames(Styles.matchTypeWrapper)}>

                                             <label className="radioButtonlbl order-sm-2 order-2 m-0 p-0 mb-2 mb-sm-0">
                                                <input type="radio" id="Fantasy" name="Details_or_Matches"
                                                       checked={selectedOption === "Fantasy"}
                                                       value="Fantasy"
                                                       onChange={() => changeSelectedOption('Fantasy')}/>
                                                <span className="p-3"
                                                      htmlFor={"Fantasy"}>{t("KNOCKOUT_TOURNAMENT.FANTASY")}</span>
                                             </label>

                                             <label className="radioButtonlbl order-sm-2 order-2 m-0 p-0 mb-2 mb-sm-0">
                                                <input type="radio" id="Details" name="Details_or_Matches"
                                                       checked={selectedOption == "Details" ? true : false} value="Details"
                                                       onChange={() => changeSelectedOption('Details')}/>
                                                <span className="p-3" htmlFor={"Details"}>{t("KNOCKOUT_TOURNAMENT.DETAILS")}</span>
                                             </label>

                                             <label className="radioButtonlbl order-sm-2 order-2 m-0 pe-2 p-0 mb-2 mb-sm-0 ">
                                                <input type="radio" id="Matches" name="Details_or_Matches" value="Matches"
                                                       checked={selectedOption == "Matches" ? true : false}
                                                       onChange={() => changeSelectedOption('Matches')}/>
                                                <span className="p-3 w-100 me-4" htmlFor={"Matches"}>{t("KNOCKOUT_TOURNAMENT.MATCHES")} </span>
                                             </label>

                                          </div>

                                       </div>
                                    </div>

                                    <div className="row">
                                       <div className="col-xl-9 col-lg-12 p-0 float-left">

                                          {selectedOption === "Fantasy" &&
                                             <TournamentFantasySection tournamentDetails={tournamentDetails}/>
                                          }

                                          {selectedOption === "Details" ?
                                             <>
                                                <KnockoutMatchDraw tournamentDetails={tournamentDetails} drawData={drawData}/>
                                                <PointTable tournamentTeams={tournamentTeams} pointsLoaded={pointsLoaded}/>
                                                <TournamentLeaderBoard leaderboardData={leaderBoardData} userLeaderBoardData={leaderBoardUserData} tournamentDetails={tournamentDetails} onPageAndSize={pageAndSizeFromLeaderboard}/>
                                             </> : <></>
                                          }

                                          {!toggleDetails && (selectedOption !== "Fantasy") &&
                                             <TournamentMatches tournamentId={tournamentId}/>}

                                       </div>
                                       <div className={Classnames(Styles.rightCol, Styles.RightColMatchDetails, "col-xl-3 p-0 d-xl-block")}>
                                          {/* Rewards Details */}
                                          {tournamentDetails?.tournamentTicketOmniCredits ?
                                             (rewardsCategory?.[0]?.userRanking?.reward > 0) ?
                                                (tournamentDetails?.active === false && tournamentDetails?.toStart === true) ?
                                                   <div className="row p-0">
                                                      <div className="col p-0">
                                                         <div className={Classnames(Styles.WinPrizes)}>
                                                            <div className={Classnames(Styles.Conner1)}></div>
                                                            <div className={Classnames(Styles.Conner2)}></div>
                                                            <div className={Classnames(Styles.Conner3)}></div>
                                                            <div className={Classnames(Styles.Conner4)}></div>
                                                            <div className={Classnames(Styles.Shade1)}></div>
                                                            <div className={Classnames(Styles.Shade2)}></div>
                                                            <div className={Classnames(Styles.Shade3)}></div>
                                                            <div className={Classnames(Styles.Shade4)}></div>
                                                            {tournamentDetails?.winPrices?.length > 0 ?
                                                               <div className={Classnames(Styles.WinPrizesWrapper)}>
                                                                  {tournamentDetails?.winPrices?.map((price, i) => winPrices(price))}
                                                                  <button onClick={openHowtoWinRewardsModal} className={Classnames(Styles.playNow, 'mt-4')}>{t("LEAGUE_TOURNAMENT.HOW_TO_WIN_REWARDS")}</button>
                                                               </div>
                                                               : <div className={Classnames(Styles.WinPrizesWrapper)}>
                                                                  {t("LEAGUE_TOURNAMENT.TAKE_PART_IN_THE_TOURNAMENT_TO_WIN_REWARDS")}
                                                                  <button onClick={openHowtoWinRewardsModal} className={Classnames(Styles.playNow, 'mt-4')}>{t("LEAGUE_TOURNAMENT.HOW_TO_WIN_REWARDS")}</button>
                                                               </div>
                                                            }
                                                         </div>
                                                      </div>
                                                   </div>
                                                   : <></>
                                                : <></>
                                             : <></>
                                          }

                                          {/* Winners Section */}
                                          {(tournamentDetails?.active === false && tournamentDetails?.toStart === false) || (tournamentDetails?.active === true && tournamentDetails?.toStart === false) ?
                                             <WinPrizesSection tournament={tournamentDetails}/>
                                             : <></>}

                                          {/* Overview */}
                                          {(tournamentDetails?.active === false && tournamentDetails?.toStart === true) || (tournamentDetails?.active === true && tournamentDetails?.toStart === false) ?
                                             <div className="row p-0">
                                                <div className="col p-0">
                                                   <div className={Classnames(Styles.OverviewWrapper)}>
                                                      <div className={Classnames(Styles.WinPrizes)}>
                                                         <div className={Classnames(Styles.Conner1)}></div>
                                                         <div className={Classnames(Styles.Conner2)}></div>
                                                         <div className={Classnames(Styles.Conner3)}></div>
                                                         <div className={Classnames(Styles.Conner4)}></div>
                                                         <div className={Classnames(Styles.Shade1)}></div>
                                                         <div className={Classnames(Styles.Shade2)}></div>
                                                         <div className={Classnames(Styles.Shade3)}></div>
                                                         <div className={Classnames(Styles.Shade4)}></div>
                                                         <div className={Classnames(Styles.WinPrizesWrapper)}>
                                                            <div className="row">
                                                               <h4>{t("LEAGUE_TOURNAMENT.OVERVIEW")}</h4>
                                                               <span className="body-text4 color-gray2"> {t("LEAGUE_TOURNAMENT.START")}: </span>
                                                               <div className={Classnames(Styles.Date)}><img src={Calendar}/>
                                                                  &nbsp;{formatDate(tournamentDetails?.startTime) || '--'}&nbsp;at&nbsp;{formatTime(tournamentDetails?.startTime) || '--'}
                                                               </div>
                                                               <p className="body-text4 color-gray2">
                                                                  {tournamentDetails?.bio ? tournamentDetails?.bio : t("LEAGUE_TOURNAMENT.NO_DESCRIPTION_AVAILABLE")}
                                                               </p>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div> : <></>}

                                          {(tournamentDetails?.active === false && tournamentDetails?.toStart === false) || (tournamentDetails?.active === true && tournamentDetails?.toStart === false) ?
                                             <div className="row p-0">
                                                <div className={Classnames(Styles.bannerWrapper, "col p-0")}>
                                                   <img
                                                      src="https://storage.googleapis.com/meta11/serviceplatform/limitedTournamentBanner.png"/>
                                                </div>
                                             </div> : <></>}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div> : loadingIndicator}
                  <Footer/>
               </div>
            </div>
         </div>

         <Modal show={isHowToModalOpen} onHide={handleMoreInfoClose}>
            <Modal.Body>
               <HowToWinRewards handleMoreInfoClose={handleMoreInfoClose}/>
            </Modal.Body>
         </Modal>

      </React.Fragment>
   );
};

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      email: state.auth.email,
      Token: state.auth.accessToken,
      RefreshToken: state.auth.refreshToken,
      timeOut: state.auth.sessionTimeout,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      onLogout: () => dispatch(actions.logout()), onRefreshAuth: (accessToken, refreshToken) => dispatch(actions.refreshSession(accessToken, refreshToken)),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeagueKnockoutTournament);
