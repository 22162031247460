import React, {useEffect, useState} from "react";
import Styles from "./Login.module.scss"
import Classnames from "classnames"
import {Link, useNavigate} from "react-router-dom";
import * as UserService from "../../services/UserService";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import FacebookLoginButton from "../../components/SocialLogins/FacebookLogin";
import GoogleLoginButton from "../../components/SocialLogins/GoogleLogin";
import ResetPasswordSender2 from "./ResetPasswordSender2";
import {toast} from "react-toastify";
import {AUTHENTICATION_TOASTS} from "../../utilities/ToastMessages";
import {decryptData, encryptData} from "../../helpers/SecureLocalStorage";
import {authRedirectHandler} from "../../helpers/CommonFunctions";
import chopraImgMain from "../../assets/images/chopraImg.webp";
import {useTranslation} from "react-i18next";
import {AUTHENTICATION_TYPES} from "../../utilities/Constants";


const Login = (props) => {
   let navigate = useNavigate();
   const [userName, setUserName] = useState("");
   const [userNameError, setUserNameError] = useState("");
   const [password, setPassword] = useState("");
   const [userNameValid, setUserNameValid] = useState(false)
   const [modalOpen, setModalOpen] = useState(false);
   let [passwordValid, setPasswordValid] = useState(false)
   const [loginError, setLoginError] = useState("");
   const [disable, setDisable] = useState(false)
   const [showPassword, setShowPassword] = useState(false);
   const [disableFromValidation, setDisableFromValidation] = useState(true);
   const [errorOutline, setErrorOutline] = useState(false)
   const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe'));
   const [lang, setLang] = useState(localStorage.getItem("language"));

   const handleDisableClick = (input) => {
      setDisable(input)
   }

   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   const loadingIndicator = (
      <div className="w-100 text-center">
         <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
         }}>
            <div className="lds-facebook">
               <div></div>
               <div></div>
               <div></div>
            </div>
         </div>
      </div>);

   useEffect(() => {

      if (localStorage.getItem('accessToken') != null) {
         navigate("/home")
      }

      setUserNameValid(true);
      setPasswordValid(true);

      //remember me configuration
      const isPreviouslyLogged = localStorage.getItem('previouslyLogged');
      const rememberMeToken = decryptData(localStorage.getItem('rememberMeToken'));
      if (isPreviouslyLogged && rememberMe && rememberMeToken) {
         const credArr = rememberMeToken?.split('-');
         setUserName(credArr[0]);
         setPassword(credArr[1]);
      }
   }, [])

   useEffect(() => {
      setLoginError("");
      if (userName && password) {
         setDisableFromValidation(false);
      }
   }, [userName, password])

   const handleUserNameChange = (e) => {
      setRememberMe(false);
      setErrorOutline(false)
      setUserNameError("")
      const emailValid = e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
      setUserNameValid(emailValid !== null)
      setDisableFromValidation(emailValid == null)
      setUserName(e.target.value)
   }
   const handlePasswordChange = (e) => {
      setRememberMe(false);
      setErrorOutline(false)
      setPasswordValid(true);
      passwordChecker(e.target.value)
      setDisableFromValidation(false)
      setPassword(e.target.value)
   }
   useEffect(() => {
      if (userName == "" || password == "") {
         setDisableFromValidation(true)
      }
   }, [userName, password])
   const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
   };

   useEffect(() => {
      localStorage.setItem('rememberMe', rememberMe);
      if (rememberMe && userNameValid && userName && password) {
         const rememberMeToken = `${userName}-${password}`;
         localStorage.setItem('rememberMeToken', encryptData(rememberMeToken))
      } else if (!rememberMe) {
         localStorage.removeItem('rememberMeToken')
      }
   }, [rememberMe]);

   const passwordChecker = (password) => {
      if (password === null) {
         setLoginError("")
         setPasswordValid(false)
         setDisableFromValidation(true)
      } else if (password === "") {
         setPasswordValid(false)
         setDisableFromValidation(true)
      } else {
         setLoginError("");
         setPasswordValid(true)
         setDisableFromValidation(false)
      }
   }

   const userNameChecker = async (userName) => {
      if (userName === null) {
         setUserNameError("")
         setUserNameValid(false)
         setDisable(false)
      } else if (userName === "") {
         setUserNameValid(false)
         setDisable(false)
      } else {
         setUserNameError("")
         const userNameAvailability = UserService.userNameAvailability(
            {
               "attribute": userName
            }
         )
         userNameAvailability.then(
            (response) => {
               if (response.apiStatus == true && response.available == false) {
                  setUserNameError("");
                  setDisable(false)
                  setUserNameValid(true);
               } else {
                  setUserNameValid(false);
                  setUserNameError(t("LOGIN_PAGE.ENTER_A_VALID_USER_NAME_OR_EMAIL"))
               }
            }
         ).catch(e => {
            toast.error(AUTHENTICATION_TOASTS.ERROR_CHECKING_EMAIL_AVAILABILITY)
         })
      }
   }

   if (!translationsLoaded) {
      return loadingIndicator;
   }
   const loginUser = () => {

      setDisable(true)
      if (passwordValid && userNameValid) {
         const toastId = toast.loading(t("LOGIN_PAGE.LOGGING_IN"))
         UserService.loginUser({
            "password": password,
            "userName": userName
         }).then(
            (response) => {
               if (response.loginStatus == true) {
                  toast.update(toastId, {
                     render: t("LOGIN_PAGE.WELCOME_BACK_TO_M_11_OMNIVERSE"),
                     type: toast.TYPE.SUCCESS,
                     autoClose: 5000,
                     closeButton: null,
                     isLoading: false
                  })
                  props.onAuth(response.accessToken, response.refreshToken, "email", response.user.profilePicURL, response.userName, (response.user.firstName + " " + response.user.lastName), response.userName)
                  localStorage.setItem("previouslyLogged", !response?.newUser)
                  authRedirectHandler(navigate)

               } else {
                  toast.update(toastId, {
                     render: t("LOGIN_PAGE.SORRY_LOGIN_FAILED_PLEASE_TRY_AGAIN"),
                     type: toast.TYPE.ERROR,
                     autoClose: 5000,
                     closeButton: null,
                     isLoading: false
                  })

                  setDisable(false)
                  if (lang == "hi"){
                     if (response?.data?.message == "USER_NOT_AVAILABLE") {
                        setLoginError("उपयोगकर्ता मौजूद नहीं है, साइन अप करने का प्रयास करें");
                     } else if (response?.data?.message == "INCORRECT_USERNAME_OR_PASSWORD") {
                        setLoginError("गलत उपयोगकर्ता नाम या पासवर्ड");
                     } else if (response?.data?.message == "CREDENTIAL_MISMATCH") {
                        setLoginError("क्रेडेंशियल बेमेल");
                     }
                  } else {
                     if (response?.data?.message == "USER_NOT_AVAILABLE") {
                        setLoginError("User does not exist, try signing up");
                     } else if (response?.data?.message == "INCORRECT_USERNAME_OR_PASSWORD") {
                        setLoginError("Incorrect Username or Password");
                     } else if (response?.data?.message == "CREDENTIAL_MISMATCH") {
                        setLoginError("Credential Mismatch");
                     }
                  }

                  setErrorOutline(true)

                  setDisableFromValidation(false)
               }
            }
         ).catch((e) => {
            toast.error(AUTHENTICATION_TOASTS.ERROR_OCCURRED_WHILE_LOGIN)
         })
      }

   }

   const handleRememberMe = (event) => {
      setRememberMe(event.target.checked)
   }

   const handleModalClose = (childResponse) => {
      setModalOpen(childResponse)
   }
   //const chopraImg = "https://meta11.blob.core.windows.net/serviceplatform/Ajeed-Chopra.webp";
   return (
      <div className={Classnames(Styles.outterWrap)}>
         <div className={Classnames("row", Styles.midContent, "pt-0 pt-sm-0 pt-md-0 pt-lg-5", "pb-2")}>
            <div className={Classnames(Styles.Radial1)}></div>
            <div className={Classnames(Styles.Radial2)}></div>


            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 ">
               <Link to={"/"}>
                  <img src="https://storage.googleapis.com/meta11/serviceplatform/M11FantasyBetaLogo.webp" alt=""
                       className={Classnames(Styles.loginLogo, Styles.marginTopMd)} style={{width: '197px', height: '54px'}}/>
               </Link>
               <div className="col-12 p-0 d-flex">
                  <div className={Classnames(Styles.TopBotGaps, "mt-5")}>
                     <div className="heading2 gradient-color">{t("LOGIN_PAGE.WELCOME")}</div>
                  </div>
               </div>
               <div className={Classnames(Styles.FormBox, "justify-content-start")}>

                  <div className={Classnames(Styles.group)}>
                     <input type="text" name="name" required value={userName} style={{maxWidth: "none", width: "100%"}}
                            onChange={handleUserNameChange}
                            className={!userNameValid || errorOutline ? Classnames(Styles.Error) : ""}/>
                     <label>{t("LOGIN_PAGE.EMAIL_ADDRESS")}</label>
                  </div>
                  <div className={Classnames(Styles.group)}>
                     <input type={showPassword ? 'text' : 'password'} name="name" required value={password}
                            onChange={handlePasswordChange}
                            className={Classnames(!passwordValid || errorOutline ? Classnames(Styles.Error) : "", showPassword ? "" : Styles.asteriskInput)}/>
                     <label>{t("LOGIN_PAGE.PASSWORD")}</label>
                     <span className={!showPassword ? Styles.normalEye : Styles.slashEye}
                           onClick={togglePasswordVisibility}></span>
                  </div>
                  <div className="mb-2 mb-sm-0 mb-md-0 mb-lg-4 p-0 ps-0 mt-3 align-middle row p-0 col-12" style={{color: '#95A6B7'}}>
                     <div className="col-1 p-0 float-start"><input type="checkbox" className={Classnames(Styles.FormCheckInput, "form-check-input")}
                                                                   checked={rememberMe}
                                                                   defaultValue={false}
                                                                   disabled={!userName || !password || !userNameValid}
                                                                   onChange={handleRememberMe}
                                                                   id="exampleCheck1" style={{margin: "0"}}/></div>
                     <div className="col-11 p-0 float-start body-text4 color-gray2" style={{lineHeight: '18px'}}>{t("LOGIN_PAGE.REMEMBER_ME")}
                        <input value={t("LOGIN_PAGE.FORGOT_PASSWORD?")} type={"submit"} className="float-end"
                               style={{color: '#ffffff', background: 'none', border: '0px'}} onClick={() => {
                           setModalOpen(true)
                        }}/></div>
                  </div>
                  <text className={Classnames(Styles.TextError)}>{userNameError} {loginError}</text>

                  <input onClick={loginUser} type="submit"
                         disabled={!passwordValid || !userNameValid || disable || disableFromValidation}
                         className={Classnames(Styles.LoginButton, (!passwordValid || !userNameValid || disable || disableFromValidation) ? "" : Styles.buttonColor)} value={t("LOGIN_PAGE.SIGN_IN")}></input>
                  <div className={Classnames(Styles.OrSpace)}>
                     <text>{t("LOGIN_PAGE.OR")}</text>
                  </div>
                  <div className={Classnames("mt-1 mb-3  body-text3 color-gray2")}>
                     {t("LOGIN_PAGE.BY_SIGNING_UP_USING_GOOGLE/ FACEBOOK, YOU_AGREE_TO")}
                     <a href="/terms-and-conditions" className={Classnames("color-textturquiose")}>{t("LOGIN_PAGE.TERMS_AND_CONDITIONS")}</a>
                     &nbsp;{t("LOGIN_PAGE.AND")}&nbsp;
                     <a href="/privacy-policy" className="color-textturquiose">{t("LOGIN_PAGE.PRIVACY_POLICY")}</a>&nbsp;{t("LOGIN_PAGE.OF_META_11")}
                  </div>
                  <GoogleLoginButton disable={disable} disableClick={handleDisableClick} type={AUTHENTICATION_TYPES.LOGIN}/>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <FacebookLoginButton disable={disable} disableClick={handleDisableClick} type={AUTHENTICATION_TYPES.LOGIN}/>
               </div>
               <div className={Classnames("pt-3 body-text3 color-gray2 d-flex", "pt-1")} style={{width: "100%"}}>{t("LOGIN_PAGE.DON’T_HAVE_AN_ACCOUNT?")}<a
                  href="/register" className="ps-2 color-textturquiose">
                  {t("LOGIN_PAGE.CLICK_TO_SIGN_UP")}
               </a></div>
            </div>
            <div className={Classnames("neonLight col-12 col-sm-12 col-md-6 col-lg-6 col-xl-7 d-none d-lg-flex align-items-center justify-content-center")}>
               <img src={chopraImgMain} className={Classnames(Styles.imgSize, "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10")}/>
            </div>
            {
               modalOpen ? <ResetPasswordSender2 onCloseModal={handleModalClose}/> : ""
            }

         </div>

         <div className="fixed-bottom">
            {/*<Footer/>*/}
         </div>
      </div>
   );
};


const mapStateToProps = state => {
   return {
      isAuthenticated: state.auth.accessToken !== null || undefined,

   }
}

const mapDispatchToProps = dispatch => {
   return {
      onAuth: (accessToken, refreshToken, oAuthProviderType, imageURL, userName, name, email) => dispatch(actions.auth(accessToken, refreshToken, oAuthProviderType, imageURL, userName, name, email))
   }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
