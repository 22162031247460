import CryptoJS from 'crypto-js';
import {ENCRYPTION_KEY} from "../utilities/Constants";

export const encryptData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data).replace(/"/g, ''), ENCRYPTION_KEY.SECRET).toString();
}

export const decryptData = (data) => {
    try {
        if (data === null) {

        } else {
            return CryptoJS.AES.decrypt(data, ENCRYPTION_KEY.SECRET).toString(CryptoJS.enc.Utf8);
        }
    } catch (e) {
        console.log("error decrypting data - Logging out user", e);
        return false;
    }


}
