import {connect} from "react-redux";
import classNames from "classnames";
import Styles from "./TournamentFantasySection.module.scss";
import React, {useEffect, useState} from "react";
import MoreInfoIcon from "../../../assets/images/icons/more_info_icon.svg";
import SprtsToken from "../../../assets/images/icons/24_sprts_token.webp";
import OmniPoints from "../../../assets/images/icons/24_xp.webp";
import {checkUserFantasySubscription, getFantasyTeamByUsernameAndTournamentId} from "../../../services/FantasyService";
import {Link, useNavigate, useParams} from "react-router-dom";
import {getConf} from "../../../services/ConfigService";
import {WALLET} from "../../../utilities/ConfigConstants";
import {toast} from "react-toastify";
import FantasyMatchHistoryModal from "../../Modals/FantasyMatchHistory/FantasyMatchHistory";
import HowFantasyWorksModal from "../../Modals/HowFantasyWorks/HowFantasyWorks";
import PlayerInfoModal from "../../Modals/PlayerInfoModal/PlayerInfoModal";
import {t} from "i18next";
import Calendar from "../../../assets/images/fi_calendar.webp";
import {getShowingFantasyPoints} from "../../../helpers/CommonFunctions";
import FantasyPointCalculation from "../../Modals/HowFantasyWorks/FantasyPointCalculation";
import PowerBoosterPurchaseModal from "../../Modals/PowerBoosterPurchase/PowerBoosterPurchase";

const TournamentFantasySection = (props) => {
   const navigate = useNavigate();
   let {tournamentId} = useParams();
   tournamentId = tournamentId || props?.tournamentDetails?.id;
   const XP_TO_SPRTS_RATIO = getConf(WALLET.XP_TO_SPRTS_RATIO)

   const [checkingSubscription, setCheckingSubscription] = useState(true);
   const [isFetchingFantasyTeam, setIsFetchingFantasyTeam] = useState(true);
   const [isSubscribed, setIsSubscribed] = useState(false);
   const [isTeamEditable, setIsTeamEditable] = useState(false);

   const [fantasyTeamName, setFantasyTeamName] = useState('');
   const [fantasyTeam, setFantasyTeam] = useState([]);
   const [teamCaptain, setTeamCaptain] = useState(null);
   const [teamViceCaptain, setTeamViceCaptain] = useState(null);
   const [totalPoints, setTotalPoints] = useState(0);
   const [activeStatus, setActiveStatus] = useState(false);

   const [showHowFantasyWorksModal, setShowHowFantasyWorksModal] = useState(false);
   const [showFantasyPointCalculationModal, setShowFantasyPointCalculationModal] = useState(false);
   const [showPlayerInfoModal, setShowPlayerInfoModal] = useState(false);
   const [showFantasyMatchHistoryModal, setShowFantasyMatchHistoryModal] = useState(false);
   const [selectedPlayerId, setSelectedPlayerId] = useState(null); // player id for player info modal
   const [selectedPlayerImage, setSelectedPlayerImage] = useState(null); // player image for match history modal
   const [selectedPlayerName, setSelectedPlayerName] = useState(null); // player name for match history modal

   const [showBoosterPurchaseModal, setShowBoosterPurchaseModal] = useState(false);
   const [boosterModalData, setBoosterModalData] = useState(null);

   const navigateToFantasyTeam = () => {
      navigate(`/fantasy-team/${tournamentId}`, {state: {tournamentDetails: props.tournamentDetails}})
   }

   // navigate to create fantasy team page
   const handleCreateFantasyTeam = () => {
      // check if the tournament is completed
      if (props.tournamentDetails?.active === false && props.tournamentDetails?.toStart === false) {
         toast.warning(t("FANTASY.YOU_CANT_CREATE_FANTASY_TEAM_FOR_A_COMPLETED_TOURNAMENT"));
      } else {
         navigateToFantasyTeam();
      }
   }

   const handleEditTeam = () => {
      // toast.info(t("FANTASY.THIS_OPTION_IS_SOON_TO_BE_DEPLOYED"))
      if (isTeamEditable) {
         navigateToFantasyTeam();
      } else {
         if (props.tournamentDetails?.active === true) {
            toast.warning(t("FANTASY.YOU_CANT_EDIT_YOUR_FANTASY_TEAM_ONCE_THE_TOURNAMENT_STARTED"))
         } else {
            toast.warning(t("FANTASY.YOU_CANT_EDIT_YOUR_FANTASY_TEAM_ONCE_THE_TOURNAMENT_HAS_COMPLETED"))
         }
      }
   }

   const handleShowHowFantasyWorks = () => {
      setShowHowFantasyWorksModal(true);
   }

   const handleShowPlayerInfo = (e, playerId) => {
      e.stopPropagation();
      setSelectedPlayerId(playerId);
      setShowPlayerInfoModal(true);
   }

   const handleShowFantasyMatchHistory = (e, player) => {
      setSelectedPlayerId(player?.playerId);
      setSelectedPlayerImage(player?.playerPictureURL);
      setSelectedPlayerName(player?.playerName?.split(' ')[0]);
      setShowFantasyMatchHistoryModal(true);
   }

   useEffect(() => {
      if (props.tournamentDetails?.active === false && props.tournamentDetails?.toStart === true) {
         setIsTeamEditable(true);
      }
   }, [props.tournamentDetails]);

   // check if user is subscribed to fantasy team
   useEffect(() => {
      const checkSubscription = async () => {
         setCheckingSubscription(true);
         checkUserFantasySubscription(tournamentId, props.userName, props.Token)
            .then((response) => {
               if (response.apiStatus && response.isSubscribed === true) {
                  setIsSubscribed(true);
                  setCheckingSubscription(false);
               } else if (response.apiStatus) {
                  setIsSubscribed(false);
                  setCheckingSubscription(false);
               }
            })
      }

      checkSubscription();
   }, [props.Token, props.userName, tournamentId]);

   // get fantasy team of the user
   useEffect(() => {
      const fetchFantasyTeam = async () => {
         getFantasyTeamByUsernameAndTournamentId(props.userName, tournamentId, props.Token)
            .then((response) => {
               if (response.apiStatus && response.team) {
                  let isCaptainAvailable = false;
                  let isViceCaptainAvailable = false;
                  response?.team.forEach(player => {
                     // set captain and vice captain
                     if (player.isCaptain) {
                        isCaptainAvailable = true;
                        setTeamCaptain(player);
                     } else if (player.isVcCaptain) {
                        isViceCaptainAvailable = true;
                        setTeamViceCaptain(player);
                     }

                     if (!isCaptainAvailable) {
                        setTeamCaptain(null);
                     }
                     if (!isViceCaptainAvailable) {
                        setTeamViceCaptain(null);
                     }
                  });

                  setTotalPoints(response?.totalPoints || 0)
                  setFantasyTeamName(response?.teamName || '')
                  setFantasyTeam(response?.team);
                  setActiveStatus(response?.activeStatus);
               }
            })
            .finally(() => {
               setIsFetchingFantasyTeam(false);
            });
      }

      if (isSubscribed) {
         // fetch fantasy team immediately
         setIsFetchingFantasyTeam(true);
         fetchFantasyTeam();

         // then fetch fantasy team every 30 seconds
         const interval = setInterval(() => {
            fetchFantasyTeam();
         }, 30000);
         return () => clearInterval(interval);
      }
   }, [isSubscribed, props.Token, props.userName, tournamentId]);

   const loadingIndicator = (<div style={{textAlign: "center"}}>
      <div className="lds-facebook">
         <div></div>
         <div></div>
         <div></div>
      </div>
   </div>);

   const playerCard = (player) => {
      const playerPoints = getShowingFantasyPoints(player?.points);

      const onPointsClick = () => {
         // setBoosterModalData({
         //    playerName: player?.playerName,
         // });
         // setShowBoosterPurchaseModal(true);
      }

      return (<div className={classNames(Styles.Card)}>
         <div className={classNames(Styles.CardBackground)}>
            <div className="row">
               <a className={classNames(Styles.HistoryInfoIconBtn)}
                  style={{cursor: 'pointer'}}
                  onClick={(e) => {
                     handleShowFantasyMatchHistory(e, player)
                  }}><img src={Calendar}/></a>
               <a className={classNames(Styles.MoreInfoIconBtn)}
                  style={{cursor: 'pointer'}}
                  onClick={(e) => {
                     handleShowPlayerInfo(e, player?.playerId)
                  }}>
                  <img src={MoreInfoIcon}/></a>
               <div className={classNames(Styles.ProfilePic)}
                    style={{backgroundImage: `url(${player?.playerPictureURL})`}}></div>
               <div className={classNames(Styles.PlayerPoints, "PlayerPoints")} role={'button'} onClick={onPointsClick} style={{cursor: "default"}}>
                  <span className={classNames(Styles.PointSplus, "PointSplus")}>{playerPoints.sign}</span>
                  <div
                     className={classNames(Styles.PointsWrapper, "PointsWrapper", playerPoints.intPart === 0 ? 'noPoints' : '')}>
                     <span className={classNames(Styles.Points, "Points")}>{playerPoints.intPart}</span>
                     <span className={classNames(Styles.DecimalPoints, "DecimalPoints")}>{playerPoints.decPart}</span>
                  </div>
               </div>
            </div>
            <div className={classNames(Styles.TitleRow, "row")}>
               <h4>{player?.playerName}</h4>
               {player?.isCaptain ? <span>{t("FANTASY.CAPTAIN")}</span> : player?.isVcCaptain &&
                  <span>{t("FANTASY.VICE_CAPTAIN")}</span>}
            </div>
            <div className={classNames(Styles.BtnRow, "row")}>
            </div>
         </div>
      </div>)
   }

   const fantasyTeamStatusToast = (activeStatus) => {
      if (activeStatus) {
         toast.success("Your fantasy team is Active")
      } else {
         toast.info("Team is still inactive, will get active from next match onwards.")
      }
   }

   return (<>
      <div className={classNames(Styles.TournamentFantasySection)}>
         <div className={classNames(Styles.TournamentFantasySectionRow1, "row")}>
            <div className="col">
               <h2>{t("FANTASY.FANTASY_TEAM")}&nbsp;
                  <img onClick={handleShowHowFantasyWorks} src={MoreInfoIcon} style={{cursor: 'pointer'}}/>
               </h2>
            </div>
            <div className="col">
               <Link to={`/user/${btoa(props.userName)}/#fantasyHistoryTitleRef`} className={classNames(Styles.PreviousFantasyTeams, "PreviousFantasyTeams")}>
                  <button>
                     {t("FANTASY.PREVIOUS_FANTASY_TEAMS")}
                  </button>
               </Link>
            </div>
         </div>

         {checkingSubscription || (isSubscribed && isFetchingFantasyTeam) || props?.parentLoading ? loadingIndicator : <>
            {!isSubscribed ? <div className="row p-0">
               <div className={classNames(Styles.MatchDetails, "col p-0 m-0")}>
                  <div className={classNames(Styles.WinPrizes)}>
                     <div className={classNames(Styles.Conner1)}></div>
                     <div className={classNames(Styles.Conner2)}></div>
                     <div className={classNames(Styles.Conner3)}></div>
                     <div className={classNames(Styles.Conner4)}></div>

                     <div className={classNames(Styles.Shade1)}></div>
                     <div className={classNames(Styles.Shade2)}></div>
                     <div className={classNames(Styles.Shade3)}></div>
                     <div className={classNames(Styles.Shade4)}></div>

                     <div className={classNames(Styles.WinPrizesWrapper)}>
                        <div className={classNames(Styles.Tournament)}>
                           <div className={classNames(Styles.NoDataWrapper)}>
                              <h3>{t("FANTASY.YOU_DONT_HAVE_A_FANTASY_TEAM_YET")}</h3>
                              <button className="lightGreenButton" onClick={handleCreateFantasyTeam}>
                                 {t("FANTASY.CREATE_FANTASY_TEAM")}
                              </button>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
            </div> : <div className="row p-0">
               <div className={classNames(Styles.MatchDetails, "col p-0 m-0")}>
                  <div className={classNames(Styles.WinPrizes)}>
                     <div className={classNames(Styles.Conner1)}></div>
                     <div className={classNames(Styles.Conner2)}></div>
                     <div className={classNames(Styles.Conner3)}></div>
                     <div className={classNames(Styles.Conner4)}></div>

                     <div className={classNames(Styles.Shade1)}></div>
                     <div className={classNames(Styles.Shade2)}></div>
                     <div className={classNames(Styles.Shade3)}></div>
                     <div className={classNames(Styles.Shade4)}></div>

                     <div className={classNames(Styles.WinPrizesWrapper)}>
                        <div className={classNames(Styles.FantacyTeamContainer, "row")}>
                           <div className="col-md-12">

                              <div id="SelectedPlayersCol" className={classNames(Styles.SelectedPlayersCol)}>
                                 <div className={classNames(Styles.ActiveStatusTagRow, "row")}>
                                    <div className="col">
                                       <div className={classNames(Styles.ActiveStatusTagWrapper, "ActiveStatusTagWrapper")}>
                                          {activeStatus ?
                                             <div className={classNames(Styles.TournamentStatus, Styles.ActiveStatus)} onClick={() => fantasyTeamStatusToast(activeStatus)}><span></span>{t("FANTASY.ACTIVE")}</div> :
                                             <div className={classNames(Styles.TournamentStatus, Styles.InactiveStatus)} onClick={() => fantasyTeamStatusToast(activeStatus)}><span></span>{t("FANTASY.INACTIVE")}</div>}
                                       </div>
                                    </div>
                                    <div className="col">
                                       <button className={classNames(Styles.EditTeamBtn)} onClick={handleEditTeam}>
                                          <div>{t("FANTASY.EDIT_TEAM")}</div>
                                       </button>
                                    </div>
                                 </div>

                                 <div className="row">
                                    <div className="col-md-12">
                                       <h4>{fantasyTeamName}</h4>
                                    </div>
                                 </div>

                                 {(teamCaptain || teamViceCaptain) &&
                                    <div className={classNames(Styles.TeamCaptains, "row")}>
                                       <div className="col-md-12">
                                          <div className={classNames(Styles.CaptainRow)}>
                                             {/* Team Captain */}
                                             {teamCaptain && playerCard(teamCaptain)}
                                             {teamViceCaptain && playerCard(teamViceCaptain)}
                                          </div>
                                       </div>
                                    </div>}

                                 <div className={classNames(Styles.OtherPlayers, "row")}>
                                    <div className="col-md-12">
                                       <div className={classNames(Styles.Wrapper)}>

                                          {fantasyTeam.map(player => {
                                             if (!player.isCaptain && !player.isVcCaptain) {
                                                return playerCard(player);
                                             } else {
                                                return null;
                                             }
                                          })}

                                       </div>
                                    </div>
                                 </div>

                                 <div className={classNames(Styles.TotalEarningRow, "row")}>
                                    <div className="col-md-12">
                                       <div className={classNames(Styles.TotalEarningWrapper)}>
                                          <div className={classNames(Styles.TotalEarning)}>
                                             <div className={classNames(Styles.Title)}>
                                                <span> {t("FANTASY.TOTAL_EARNING")}</span><span>Total Pts </span> :
                                             </div>
                                             <div className={classNames(Styles.OmniPoints)}>
                                                <img src={OmniPoints}/>{totalPoints}&nbsp;
                                             </div>
                                             <div className={classNames(Styles.SprtsToken)}>
                                                <img
                                                   src={SprtsToken}/>{totalPoints ? (totalPoints / XP_TO_SPRTS_RATIO).toFixed(3) : 0}
                                             </div>
                                          </div>

                                       </div>
                                    </div>
                                 </div>

                              </div>

                           </div>
                        </div>
                     </div>

                  </div>
               </div>
            </div>}
         </>}
      </div>
      {showHowFantasyWorksModal && <HowFantasyWorksModal
         handleModalClose={() => {
            setShowHowFantasyWorksModal(false);
         }}
         handleShowHowFantasyPoints={() => {
            setShowFantasyPointCalculationModal(true);
         }}/>}
      {showFantasyPointCalculationModal && <FantasyPointCalculation handleModalClose={() => {
         setShowFantasyPointCalculationModal(false);
      }}/>}
      {showPlayerInfoModal && <PlayerInfoModal playerId={selectedPlayerId}
                                               tournamentId={tournamentId}
                                               handleModalClose={() => {
                                                  setShowPlayerInfoModal(false);
                                                  setSelectedPlayerId(null);
                                               }}/>}
      {showFantasyMatchHistoryModal && <FantasyMatchHistoryModal playerId={selectedPlayerId}
                                                                 tournamentId={tournamentId}
                                                                 playerImage={selectedPlayerImage}
                                                                 playerName={selectedPlayerName}
                                                                 handleModalClose={() => {
                                                                    setShowFantasyMatchHistoryModal(false);
                                                                    setSelectedPlayerId(null);
                                                                    setSelectedPlayerImage(null);
                                                                    setSelectedPlayerName(null);
                                                                 }}/>}
      {showBoosterPurchaseModal &&
         <PowerBoosterPurchaseModal data={boosterModalData}
                                    handleModalClose={() => {
                                       setShowBoosterPurchaseModal(false)
                                    }}/>}
   </>)

}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      email: state.auth.email,
      Token: state.auth.accessToken,
      RefreshToken: state.auth.refreshToken,
      timeOut: state.auth.sessionTimeout,
   };
};

export default connect(mapStateToProps)(TournamentFantasySection);
