import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Classnames from "classnames";
import styles from "./ViewFantasyTeamModal.module.scss";

import SprtsToken from "../../../assets/images/icons/24_sprts_token.webp";
import OmniPoints from "../../../assets/images/icons/24_xp.webp";
import {getFantasyTeamByUsernameAndTournamentId} from "../../../services/FantasyService";
import {getConf} from "../../../services/ConfigService";
import {WALLET} from "../../../utilities/ConfigConstants";
import {t} from "i18next";
import {getShowingFantasyPoints} from "../../../helpers/CommonFunctions";
import MoreInfoIcon from "../../../assets/images/icons/more_info_icon.svg";
import Calendar from "../../../assets/images/fi_calendar.webp";
import classNames from "classnames";
import Styles from "../../Tournament/FantasyTeamSection/TournamentFantasySection.module.scss";

const ViewFantasyTeamModal = ({tournamentId, isDisplayHidden, handleModalClose, onCalendarClick, onInfoClick}) => {
   const userName = useSelector((state) => state.auth?.userName);
   const accessToken = useSelector((state) => state.auth?.accessToken);
   const XP_TO_SPRTS_RATIO = getConf(WALLET.XP_TO_SPRTS_RATIO)

   const [isFetchingFantasyTeam, setIsFetchingFantasyTeam] = useState(false);
   const [fantasyTeamObj, setFantasyTeamObj] = useState({});
   const [teamCaptain, setTeamCaptain] = useState(null);
   const [teamViceCaptain, setTeamViceCaptain] = useState(null);
   const [totalPoints, setTotalPoints] = useState(0);
   const [fantasyTeamName, setFantasyTeamName] = useState('');

   // get fantasy team of the user
   useEffect(() => {
      const fetchFantasyTeam = async () => {
         setIsFetchingFantasyTeam(true);
         getFantasyTeamByUsernameAndTournamentId(userName, tournamentId, accessToken)
            .then((response) => {
               if (response.apiStatus && response.team) {
                  const teamObj = response?.team.reduce((acc, player) => {
                     acc[player.playerId] = {...player, playerRole: player.playerRole};

                     // set captain and vice captain
                     if (player.isCaptain) {
                        setTeamCaptain(player);
                     } else if (player.isVcCaptain) {
                        setTeamViceCaptain(player);
                     }

                     return acc;
                  }, {});
                  setTotalPoints(response?.totalPoints || 0)
                  setFantasyTeamName(response?.teamName || '')
                  setFantasyTeamObj(teamObj);
               }
            })
            .finally(() => {
               setIsFetchingFantasyTeam(false);
            });
      }

      fetchFantasyTeam();
   }, [userName, tournamentId, accessToken]);

   const loadingIndicator = (<div style={{textAlign: "center"}}>
      <div className="lds-facebook">
         <div></div>
         <div></div>
         <div></div>
      </div>
   </div>);

   return (<div
      className={Classnames("modal fade show", styles.modalBlur, styles.PaymentConfirmmodal, styles.ViewFantacyTeam)}
      id="exampleModalCenter"
      tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
      style={isDisplayHidden ? {display: "none"} : {display: "block"}}>
      <div className={Classnames(styles.CreditPurchaseModal, "modal-dialog modal-dialog-centered")} role="document">
         <div className={Classnames("modal-content", styles.modalContentContainer)}>
            <button className={Classnames(styles.closeButton, "close z-3")} type="button" onClick={handleModalClose}
                    data-dismiss="modal" aria-label="Close">
                   <span className={styles.closeButton} aria-hidden="true">
                      <img alt='Close Icon' src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                   </span>
            </button>
            <div className={Classnames(styles.UserProfileModalWrapper, styles.NoFantasyTeamModalWrapper)}>
               <div className={Classnames(styles.ellipse, styles.eMulticolorHalf)}></div>
               <div className={Classnames(styles.ellipse, styles.e392)}></div>
               <div className="row p-0">
                  <div className={Classnames(styles.UserProfileModal, "col-md-12")}>
                     {isFetchingFantasyTeam ? loadingIndicator : (Object.keys(fantasyTeamObj).length < 1 ?
                           <h4 className={Classnames(styles.NoFantasyTeamTitle)}>{t("FANTASY.YOU_DONT_HAVE_A_FANTASY_TEAM")}</h4> : <div className={Classnames(styles.TitleWrapper)}>
                           <h4>{fantasyTeamName}</h4>

                           <div className={Classnames(styles.TeamCaptains, "row")}>
                              <div className="col-md-12">
                                 <div className={Classnames(styles.CaptainRow)}>

                                    {teamCaptain && <div className={Classnames(styles.Card)}>
                                       <div className={Classnames(styles.CardBackground)}>
                                          <div className="row">
                                             <a className={Classnames(styles.HistoryInfoIconBtn)}
                                                style={{cursor: 'pointer'}}
                                                onClick={() => onCalendarClick(teamCaptain?.playerId, teamCaptain?.playerPictureURL, teamCaptain?.playerName)}>
                                                <img src={Calendar}/></a>
                                             <a className={Classnames(styles.MoreInfoIconBtn)}
                                                style={{cursor: 'pointer'}}
                                                onClick={() => onInfoClick(teamCaptain?.playerId)}>
                                                <img src={MoreInfoIcon}/></a>
                                             <div className={Classnames(styles.ProfilePic)}
                                                  style={{backgroundImage: `url(${teamCaptain?.playerPictureURL})`}}>
                                                   {(() => {
                                                      const playerPoints = getShowingFantasyPoints(teamCaptain?.points);
                                                      return (
                                                         <div className={Classnames(styles.PlayerPoints)}>
                                                            <span className={classNames(Styles.PointSplus)}>{playerPoints.sign}</span>
                                                            <div className={classNames(Styles.PointsWrapper)}>
                                                               <span className={classNames(Styles.Points)}>{playerPoints.intPart}</span>
                                                               <span className={classNames(Styles.DecimalPoints)}>{playerPoints.decPart}</span>
                                                            </div>
                                                         </div>
                                                      );
                                                   })()}
                                             </div>
                                          </div>
                                          <div className={Classnames(styles.TitleRow, "row")}>
                                          <h4>{teamCaptain?.playerName}</h4>
                                             <span>{t("FANTASY.CAPTAIN")}</span>
                                          </div>
                                       </div>
                                    </div>}

                                    {teamViceCaptain && <div className={Classnames(styles.Card)}>
                                       <div className={Classnames(styles.CardBackground)}>
                                          <div className="row">
                                             <a className={Classnames(styles.HistoryInfoIconBtn)}
                                                style={{cursor: 'pointer'}}
                                                onClick={() => onCalendarClick(teamViceCaptain?.playerId, teamViceCaptain?.playerPictureURL, teamViceCaptain?.playerName)}>
                                                <img src={Calendar}/></a>
                                             <a className={Classnames(styles.MoreInfoIconBtn)}
                                                style={{cursor: 'pointer'}} onClick={() => onInfoClick(teamViceCaptain?.playerId)}>
                                                <img src={MoreInfoIcon}/></a>
                                             <div className={Classnames(styles.ProfilePic)}
                                                  style={{backgroundImage: `url(${teamViceCaptain?.playerPictureURL})`}}>
                                                   {(() => {
                                                      const playerPoints = getShowingFantasyPoints(teamViceCaptain?.points);
                                                      return (
                                                         <div className={Classnames(styles.PlayerPoints)}>
                                                            <span className={classNames(Styles.PointSplus)}>{playerPoints.sign}</span>
                                                            <div className={classNames(Styles.PointsWrapper)}>
                                                               <span className={classNames(Styles.Points)}>{playerPoints.intPart}</span>
                                                               <span className={classNames(Styles.DecimalPoints)}>{playerPoints.decPart}</span>
                                                            </div>
                                                         </div>
                                                      );
                                                   })()}
                                             </div>
                                          </div>
                                          <div className={Classnames(styles.TitleRow, "row")}>
                                          <h4>{teamViceCaptain?.playerName}</h4>
                                             <span>{t("FANTASY.VICE_CAPTAIN")}</span>
                                          </div>
                                       </div>
                                    </div>}

                                 </div>
                              </div>

                              <div className={Classnames(styles.OtherPlayers, "row")}>
                                 <div className="col-md-12">

                                    {Object.values(fantasyTeamObj).map(player => {
                                       if (!player.isCaptain && !player.isVcCaptain) {
                                          const playerPoints = getShowingFantasyPoints(player?.points);
                                          return (<div className={Classnames(styles.Wrapper)}>
                                             <div className={Classnames(styles.Card)}>
                                                <div className={Classnames(styles.CardBackground)}>
                                                   <div className="row">
                                                      <a className={Classnames(styles.HistoryInfoIconBtn)}
                                                         style={{cursor: 'pointer'}}
                                                         onClick={() => onCalendarClick(player?.playerId, player?.playerPictureURL, player?.playerName)}>
                                                         <img src={Calendar}/></a>
                                                      <a className={Classnames(styles.MoreInfoIconBtn)}
                                                         style={{cursor: 'pointer'}} onClick={() => onInfoClick(player?.playerId)}>
                                                         <img src={MoreInfoIcon}/></a>
                                                      <div className={Classnames(styles.ProfilePic)}
                                                           style={{backgroundImage: `url(${player?.playerPictureURL})`}}>
                                                         <div className={Classnames(styles.PlayerPoints)}>
                                                            <span className={classNames(Styles.PointSplus)}>{playerPoints.sign}</span>
                                                            <div className={classNames(Styles.PointsWrapper)}>
                                                               <span className={classNames(Styles.Points)}>{playerPoints.intPart}</span>
                                                               <span className={classNames(Styles.DecimalPoints)}>{playerPoints.decPart}</span>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div className={Classnames(styles.TitleRow, "row")}>
                                                      <h4>{player?.playerName}</h4>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>)
                                       } else {
                                          return null;
                                       }
                                    })}

                                 </div>
                              </div>

                              <div className={Classnames(styles.TotalEarningRow, "row")}>
                                 <div className="col-md-12">
                                    <div className={Classnames(styles.TotalEarningWrapper)}>
                                       <div className={Classnames(styles.TotalEarning)}>
                                          <div className={Classnames(styles.Title)}>
                                             {t("FANTASY.TOTAL_EARNING")}:
                                          </div>
                                          <div className={Classnames(styles.SprtsToken)}>
                                             <img
                                                src={SprtsToken}/>{totalPoints ? (totalPoints / XP_TO_SPRTS_RATIO).toFixed(3) : 0}
                                          </div>
                                          <div className={Classnames(styles.OmniPoints)}>
                                             <img src={OmniPoints}/>{totalPoints}
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                           </div>
                        </div>)}
                  </div>
               </div>

               <div className="row p-0">
                  <div className={Classnames(styles.ModalButtonRow, "row p-0")}>
                     <button className={Classnames(styles.NoFantasyCloseBtn,"lightGreenButton")} onClick={handleModalClose}>
                        <span>{t("FANTASY.CLOSE")}</span>
                     </button>
                  </div>
               </div>
               <div className={Classnames(styles.ellipse, styles.eMulticolorFull)}></div>
            </div>
         </div>
      </div>
   </div>);
}

export default ViewFantasyTeamModal;
