import {ApiEndpoints} from "./ApiConstants";
import AxiosInstance from "../helpers/AxiosInstance";
import {VOTE_TYPES} from "../utilities/Constants";

export const verifyEmail = async (name, data) => {
   let lowerCaseData = {
      ...data,
      "attribute": data.attribute.toLowerCase()
   }
   if (name != "") {
      try {
         const response = await AxiosInstance().put(`${ApiEndpoints.SEND_VERIFICATION_EMAIL}?name=${name}`, lowerCaseData);
         return response.data;
      } catch (error) {
         return error.response;
      }
   } else {
      try {
         const response = await AxiosInstance().put(`${ApiEndpoints.SEND_VERIFICATION_EMAIL}`, lowerCaseData);
         return response.data;
      } catch (error) {
         return error.response;
      }
   }

};
export const forgotPasswordReset = async (data) => {
   try {
      const response = await AxiosInstance().put(ApiEndpoints.SEND_RESET_EMAIL, data);
      return response.data;
   } catch (error) {
      return error.response;
   }
};
export const resetPassword = async (data) => {
   try {
      const response = await AxiosInstance().post(ApiEndpoints.RESET_PASSWORD, data);
      return response.data;
   } catch (error) {
      return error.response;
   }
};
export const resetPasswordCodeValidation = async (code) => {
   return AxiosInstance()
      .get(`${ApiEndpoints.RESET_PASSWORD_CODE}?code=${code}`)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });

};
export const emailAvailability = async (data) => {
   try {
      const response = await AxiosInstance().put(ApiEndpoints.CHECK_EMAIL, data);
      return response.data;
   } catch (error) {
      return error.response;
   }
};
export const userNameAvailability = async (data) => {
   try {
      const response = await AxiosInstance().put(ApiEndpoints.CHECK_USERNAME, data);
      return response.data;
   } catch (error) {
      return error.response;
   }
};
export const createNewUser = async (data) => {
   try {
      const response = await AxiosInstance().post(ApiEndpoints.CREATE_NEW_USER_BY_EMAIL, data);
      return response.data;
   } catch (error) {
      return error.response;
   }
};
export const loginUser = async (data) => {
   try {
      const response = await AxiosInstance().post(ApiEndpoints.LOGIN_USER, data);
      return response.data;
   } catch (error) {
      return error.response;
   }
};
export const socialLogin = async (data) => {
   try {
      const response = await AxiosInstance().post(ApiEndpoints.SOCIAL_LOGIN, data);
      return response.data;
   } catch (error) {
      return error.response;
   }
};

//TODO
export const validateToken = (token) => {
   const url = `${ApiEndpoints.TOKEN_VALIDATE}`
   return AxiosInstance()
      .put(url, "", {
         headers: {
            "AuthToken": token
         }
      })
      .then((response) => {
         return response;
      })
      .catch((error) => {
         return error.response;
      });
};
export const refreshToken = (userName, token) => {
   const url = `${ApiEndpoints.REFRESH_SESSION}/${userName}`
   return AxiosInstance()
      .post(url, "", {
         headers: {
            "AuthToken": token
         }
      })
      .then((response) => {
         return response;
      })
      .catch((error) => {
         return error.response;
      });
};
export const assetUploadCommunity = (file, onUploadProgress, type, token) => {


   const url = `${ApiEndpoints.UPLOAD_DESIGN_BLOB}/${type}`
   const formData = new FormData();
   formData.append('file', file);
   return AxiosInstance()
      .post(url, formData, {
         headers: {
            "content-type": "multipart/form-data",
            "AuthToken": token
         }, onUploadProgress: onUploadProgress
      })
      .then((response) => {
         return {
            status: response.status,
            data: response.data,
         };
      })
      .catch((error) => {
         console.error('Upload failed:', error);
         return error.response;
      });
};
export const assetUpload = (file, onUploadProgress, type, email) => {
   const url = `${ApiEndpoints.UPLOAD_DESIGN_BLOB}/${type}?email=${email}`
   const formData = new FormData();

   formData.append('file', file);
   return AxiosInstance()
      .post(url, formData, {
         headers: {
            "content-type": "multipart/form-data",
         }, onUploadProgress: onUploadProgress
      })
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         console.error('Upload failed:', error);
         return error.response;
      });
};

export const deleteAsset = (fileName, email) => {
   const url = `${ApiEndpoints.DELETE_DESIGN_BLOB}`
   let data = {
      "container": "community",
      "fileName": fileName,
      "email": email
   }
   return AxiosInstance().delete(url, {data})
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         console.error('delete failed:', error);
         return error.response;
      });
}
export const getUsers = () => {
   return AxiosInstance()
      .get(ApiEndpoints.GET_ALL_USERS)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};
export const getDesignTypes = () => {
   return AxiosInstance()
      .get(ApiEndpoints.GET_ARTWORK_TYPES)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};
export const getDesignTypeCategories = () => {
   return AxiosInstance()
      .get(`${ApiEndpoints.GET_ARTWORK_TYPES}/category`)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};
export const GetUserByUserName = (userName, token) => {
   const url = `${ApiEndpoints.GET_USER_BY_USER_NAME}/${userName}`;
   return AxiosInstance()
      .get(url, {
         headers: {
            "AuthToken": token
         }
      })
      .then((response) => {
         return response.data

      })
      .catch((error) => {
         return error.response;
      });
};
export const GetUserByUserNameWithoutToken = (userName) => {
   const url = `${ApiEndpoints.GET_USER_BY_USER_NAME}/${userName}`;
   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};
export const updateUser = (data, token) => {
   return AxiosInstance()
      .put(ApiEndpoints.UPDATE_USER, data,
         {
            headers: {
               "AuthToken": token
            }
         })
      .then((response) => {
         return {
            status: response.status,
            data: response.data,
         };
      })
      .catch((error) => {
         return error.response;
      });
};

export const createUser = (data) => {
   return AxiosInstance()
      .post(ApiEndpoints.USERS, data)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const getArtworks = (page) => {
   const url = `${ApiEndpoints.GET_ALL_ARTWORKS}/artwork?page=${page}&size=20`
   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
}
export const getArtworksWithVotes = (page, token) => {
   const url = `${ApiEndpoints.GET_ALL_ARTWORKS}/artwork?page=${page}&size=20`
   return AxiosInstance()
      .get(url, {
         headers: {
            "AuthToken": token
         }
      })
      .then((response) => {
         return {
            status: response.status,
            data: response.data,
         };
      })
      .catch((error) => {
         return error.response;
      });
}
export const getArtworksBySearch = (designType, search, page, sort) => {

   if (isNaN(designType)) {

      let url = `${ApiEndpoints.GET_ALL_ARTWORKS}/artwork?attribute=${search}&page=${page}&sort=${sort}&size=20`;
      return AxiosInstance()
         .get(url)
         .then((response) => {
            return response.data;
         })
         .catch((error) => {
            return error.response;
         });
   } else {
      let url = `${ApiEndpoints.GET_ALL_ARTWORKS}/artwork?attribute=${search}&page=${page}&sort=${sort}&size=20&design-type=${designType}`
      return AxiosInstance()
         .get(url)
         .then((response) => {
            return response.data;
         })
         .catch((error) => {
            return error.response;
         });
   }

}
export const getArtworksBySearchWithVoted = (designType, search, page, sort, token) => {
   if (isNaN(designType)) {
      let url = `${ApiEndpoints.GET_ALL_ARTWORKS}/artwork?attribute=${search}&page=${page}&sort=${sort}&size=20`
      return AxiosInstance()
         .get(url, {
            headers: {
               "AuthToken": token
            }
         })
         .then((response) => {
            return {
               status: response.status,
               data: response.data,
            };
         })
         .catch((error) => {
            return error.response;
         });
   } else {
      let url = `${ApiEndpoints.GET_ALL_ARTWORKS}/artwork?attribute=${search}&page=${page}&sort=${sort}&size=20&design-type=${designType}`
      return AxiosInstance()
         .get(url, {
            headers: {
               "AuthToken": token
            }
         })
         .then((response) => {
            return {
               status: response.status,
               data: response.data,
            };
         })
         .catch((error) => {
            return error.response;
         });
   }

}
export const uploadArtWorks = async (token, data) => {
   const url = `${ApiEndpoints.GET_ALL_ARTWORKS}/artwork`
   return AxiosInstance()
      .post(url, data, {
         headers: {
            "AuthToken": token
         }
      })
      .then((response) => {
         return {
            status: response.status,
            data: response.data,
         };
      })
      .catch((error) => {
         return error.response;
      });
};

export const voteArtwork = async (artworkId, vote) => {
   let call;
   if (vote === true) {
      call = VOTE_TYPES.UP;
   } else {
      call = VOTE_TYPES.DOWN
   }
   const url = `${ApiEndpoints.GET_ALL_ARTWORKS}/artwork/${artworkId}/token/${call}`
   return AxiosInstance()
      .put(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};
export const upVoteArtwork = async (token, artworkId) => {
   let call = "UP"
   const url = `${ApiEndpoints.GET_ALL_ARTWORKS}/artwork/${artworkId}/vote/${call}`
   return AxiosInstance()
      .put(url, "", {
         headers: {
            "AuthToken": token
         }
      })
      .then((response) => {
         return {
            status: response.status,
            data: response.data,
         };
      })
      .catch((error) => {
         return error.response;
      });
};
export const downVoteArtwork = async (token, artworkId) => {
   let call = "DOWN"
   const url = `${ApiEndpoints.GET_ALL_ARTWORKS}/artwork/${artworkId}/vote/${call}`
   return AxiosInstance()
      .put(url, "", {
         headers: {
            "AuthToken": token
         }
      })
      .then((response) => {
         return {
            status: response.status,
            data: response.data,
         };
      })
      .catch((error) => {
         return error.response;
      });
};


export const updateUserPrivacy = (token, userId, privateStatus) => {
   const updateUserPrivacyRequest = {
      userId: userId,
      private: privateStatus
   }
   return AxiosInstance()
      .put(ApiEndpoints.UPDATE_USER_PRIVACY, updateUserPrivacyRequest, {
         headers: {
            "AuthToken": token
         }
      })
      .then((response) => {
         return {
            status: response.status,
            data: response.data,
         };
      })
      .catch((error) => {
         return error.response;
      });
};

export const getArtworkById = (artWorkId) => {
   const url = `${ApiEndpoints.GET_ARTWORK_BY_ID}/${artWorkId}`;
   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const updateUserArtworkToken = (artWorkId, vote) => {
   const url = `${ApiEndpoints.UPDATE_USER_ARTWORK_TOKEN}/${artWorkId}/token/${vote}`;
   return AxiosInstance()
      .put(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const getArtworkByUserNameAndType = (token, artWorkType, userName, page, size, sort) => {
   const url = `${ApiEndpoints.GET_ARTWORK_BY_USER_ID_AND_TYPE}/${userName}/artwork/${artWorkType}?page=${page}&size=${size}`

   return AxiosInstance()
      .get(url, {
         headers: {
            "AuthToken": token
         }
      })
      .then((response) => {
         return {
            status: response.status,
            data: response.data,
         };
      })
      .catch((error) => {
         return error.response;
      });
};

// export const updateFavoritePlayer = async (token, data) => {
//     const url = `${ApiEndpoints.SET_FAVOURITE_PLAYER}`
//     return AxiosInstance()
//         .put(url, data, {
//             headers: {
//                 "AuthToken": token
//             }
//         })
//         .then((response) => {
//             return {
//                 status: response.status,
//                 data: response.data,
//             };
//         })
//         .catch((error) => {
//             return error.response;
//         });
// };
//
// export const updateFavoriteTeam = async (token, data) => {
//     const url = `${ApiEndpoints.SET_FAVOURITE_TEAM}`
//     return AxiosInstance()
//         .put(url, data, {
//             headers: {
//                 "AuthToken": token
//             }
//         })
//         .then((response) => {
//             return {
//                 status: response.status,
//                 data: response.data,
//             };
//         })
//         .catch((error) => {
//             return error.response;
//         });
// };
//
// // TODO check user profile change
// export const getFavourites = async (token, type, userName, page, size, sort) => {
//     //http://localhost:8080/api/user/1/artwork/TEST?page=0&size=10
//     const url = `${ApiEndpoints.GET_FAVOURITES}/${type}/${userName}?page=${page}&size=${size}`
//     return AxiosInstance()
//         .get(url, {
//             headers: {
//                 "AuthToken": token
//             }
//         })
//         .then((response) => {
//             return {
//                 status: response.status,
//                 data: response.data,
//             };
//         })
//         .catch((error) => {
//             return error.response;
//         });
// };

export const getUserFollowingFollowers = async (authToken, follow, userId, page, size, sort) => {
   //https://qa.meta11.com/api/user/follow/followers/1?page=0&size=10&sort=id%2Cdesc
   const url = `${ApiEndpoints.GET_FOLLOWERS_FOLLOWING}/${follow}/${userId}?page=${page}&size=${size}&sort=${sort}`
   return AxiosInstance()
      .get(url, {
         headers: {
            "AuthToken": authToken
         }
      })
      .then((response) => {
         return {
            status: response.status,
            data: response.data,
         };
      })
      .catch((error) => {
         return error.response;
      });
};

export const getPastMatchesByUsername = (userId, currentPage, postsPerPage, sort, lang) => {
   const url = `${ApiEndpoints.GET_PAST_MATCHES_BY_USER_NAME}/${userId}?lang=${lang}&page=${currentPage}&size=${postsPerPage}&sort=${sort}`;
   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const getUserQuestionHistory = (token, userId, matchId, page, size, sort) => {
   const url = `${ApiEndpoints.GET_USER_QUESTION_HISTORY}/${userId}/${matchId}?page=${page}&size=${size}&sort=${sort}`;
   console.log(url);
   return AxiosInstance()
      .get(url,
         {
            headers: {
               "AuthToken": token
            }
         })
      .then((response) => {
         return {
            status: response.status,
            data: response.data,
         };
      })
      .catch((error) => {
         return error.response;
      });
};
export const deleteUser = (userName, token) => {
   const url = `${ApiEndpoints.GET_USER_BY_USER_NAME}/${userName}`
   return AxiosInstance()
      .delete(url, {
         headers: {
            "AuthToken": token
         }
      })
      .then((response) => {
         return response;
      })
      .catch((error) => {
         return error.response;
      });
};
