import React, {useEffect, useState} from "react";
import NavbarSecondary from "../../components/NavigationBar/NavigationBar";
import {FaExternalLinkAlt, FaSearch} from "react-icons/fa";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Paginator from "../../components/Paginator/Paginator";
import Footer from "../../components/Footer/Footer";
import * as DigitalAssetsService from "../../services/DigitalAssetService";
import styles from "./Players.module.scss";
import {PlayerHittingRanks} from "../../utilities/Enums";
import {Link, useLocation} from "react-router-dom";
import * as FavouriteService from "../../services/FavouriteService";
import {connect} from "react-redux";
import HeartActive from "../../assets/images/HeartActive.svg";
import HeartDefault from "../../assets/images/HeartDefault.svg";
import {toast} from "react-toastify";
import classNames from "classnames";
import useDebounce, {isExistChecker, playerRoleStyleController} from "../../helpers/CommonFunctions";
import {getConf} from "../../services/ConfigService";
import {PLAYERS_PAGE} from "../../utilities/ConfigConstants";
import {useTranslation} from "react-i18next";


const Players = (props) => {

   const location = useLocation();
   const userName = new URLSearchParams(location.search).get('userName');
   const currentUrl = location.pathname + location.search;

   const [players, setPlayers] = useState([]);
   let [loadingInProgress, setLoading] = useState(true);
   const [currentPage, setCurrentPage] = useState(0);
   const [postsPerPage, setPostsPerPage] = useState(9);
   const [totalElements, setTotalElements] = useState(0);
   const [searchQuery, setSearchQuery] = useState("");
   const [sort, setSort] = useState('playerId,desc');
   const [favouritePlayers, setFavouritePlayers] = useState([]);
   const [user, setUser] = useState({});
   const [favouriteLoading, setFavouriteLoading] = useState(false);
   let SHOW_PLAYERS_PAGE_ADVERTISEMENT_URL = getConf(PLAYERS_PAGE.SHOW_PLAYERS_PAGE_ADVERTISEMENT_URL);
   let SHOW_PLAYERS_PAGE_ADVERTISEMENT_URL2 = getConf(PLAYERS_PAGE.SHOW_PLAYERS_PAGE_ADVERTISEMENT_URL2);
   const [pageLang, setPageLang] = useState(localStorage.getItem('language'));

   useEffect(() => {
      const fetchDetails = async () => {

         if (props.isAuthenticated) {
            await FavouriteService.getFavourites(props.Token, "player", props.userName, 0, 100).then((response) => {
               if (response.data.apiStatus) {
                  setFavouritePlayers(response.data.userFavourites);
                  if (userName != null) {
                     setTotalElements(response.data.totalElements);
                  }

               } else if (response.status == 401) {
                  //toast.error(COMMON_TOASTS.UNAUTHORIZED_REQUEST);
               }
            });
         }
         favouritePlayerController();
      };
      fetchDetails();
   }, [user]);

   const debouncedSearchQuery = useDebounce(searchQuery, 500)

   useEffect(() => {
      window.scrollTo({top: 0, behavior: "smooth"});
      setLoading(true);

      const getPlayersData = async () => {
         if (!userName) {
            await DigitalAssetsService.getAllPlayersCards(pageLang, debouncedSearchQuery, currentPage, postsPerPage, sort)
               .then((response) => {
                  if (response.apiStatus) {
                     setPlayers(response.players);
                     setTotalElements(response.totalElements);
                     setLoading(false);
                  } else {
                     console.log(response.rejectMessages?.[0]);
                  }
               });
         } else if (userName) {
            await DigitalAssetsService.getFavouritePlayerCardsByUserName(userName, currentPage, postsPerPage, sort).then((response) => {
               if (response.apiStatus) {
                  setPlayers(response.players);
                  setTotalElements(response.totalElements);
                  setLoading(false);
               }
            });
         }
      };
      getPlayersData();
   }, [debouncedSearchQuery, postsPerPage, currentPage, userName]);

   const favouritePlayerController = (playerName, playerID) => {
      if (isExistChecker(favouritePlayers, playerID)) {

         return (
            <Link to={currentUrl}>
               <button onClick={() => updateFavouritePlayer(false, playerName, playerID)} className="heartLikeFill" style={{top: 15, right: 15}}>
                  <img src={HeartActive} alt="HeartActive" className="heartActive"/>
               </button>
            </Link>
         );
      } else {
         return (
            <Link to={currentUrl}>
               <button onClick={() => updateFavouritePlayer(true, playerName, playerID)} className="heartLike" style={{top: 15, right: 15}}>
                  <img src={HeartDefault} alt="HeartDefault" className="heartDefault"/>
               </button>
            </Link>
         );
      }
   };

   const updateFavouritePlayer = async (isFavourite, playerName, playerId) => {

      if (isFavourite == true && favouritePlayers?.length > 0) {
         setFavouritePlayers(prevState => [...prevState, {id: playerId, name: playerName}]);
      } else if (isFavourite == false && favouritePlayers?.length > 0) {
         setFavouritePlayers((current) => current.filter((player) => player.id != playerId))
      } else if (isFavourite == true && favouritePlayers?.length == 0) {
         setFavouritePlayers([{id: playerId, name: playerName}]);
      } else if (isFavourite == false && favouritePlayers?.length == 0) {

      }
      if (playerName != undefined || playerName != null) {
         const toastId = toast.loading(`${isFavourite ? t("PLAYERS.ADD") : t("PLAYERS.REMOVE")} ${t("PLAYERS.PLAYER")} ${playerName} ${isFavourite ? t("PLAYERS.TO") : t("PLAYERS.FROM")} ${t("PLAYERS.FAVOURITE")}`)

         await FavouriteService.updateFavoritePlayer(props.Token, {
            id: parseInt(playerId),
            like: isFavourite,
            name: playerName,
            userName: props.userName,
         }).then((response) => {
            if (response.data.apiStatus) {
               setUser(response.data.user);
               toast.update(toastId, {
                  render: `${t("PLAYERS.SUCCESSFULLY")} ${isFavourite ? t("PLAYERS.ADDED") : t("PLAYERS.REMOVED")} ${t("PLAYERS.THE_PLAYER")} ${playerName} ${isFavourite ? t("PLAYERS.TO") : t("PLAYERS.FROM")} ${t("PLAYERS.FAVOURITES")}`,
                  type: toast.TYPE.SUCCESS,
                  autoClose: 5000,
                  closeButton: null,
                  isLoading: false
               })
            } else if (response.status == 401) {
               setFavouritePlayers((current) => current.filter((player) => player.id != playerId))
               //toast.error(COMMON_TOASTS.UNAUTHORIZED_REQUEST);
               toast.update(toastId, {
                  render: `${t("PLAYERS.ERROR_UPDATING_FAVOURITE_PLAYER")}`,
                  type: toast.TYPE.ERROR,
                  autoClose: 5000,
                  closeButton: null,
                  isLoading: false
               })
            } else {
               // toast.error(response.rejectMessages[0]);
               setFavouritePlayers((current) => current.filter((player) => player.id != playerId))

               toast.update(toastId, {
                  render: `${t("PLAYERS.ERROR_UPDATING_FAVOURITE_PLAYER")}`,
                  type: "error",
                  autoClose: 5000,
                  closeButton: null,
                  isLoading: false
               })
            }
         });
      } else {
         console.log("Error occurred in marking Favourite Player.");
      }
   };

   const paginate = (pageNumber, postsPerPage) => {
      setPostsPerPage(Number(postsPerPage));
      setCurrentPage(pageNumber);
   };
   const getPlayerHittingRank = (percentage) => {
      if (percentage >= 80 && percentage <= 100) {
         return PlayerHittingRanks.LEGEND;
      } else if (percentage >= 60 && percentage < 80) {
         return PlayerHittingRanks.CHAMPION;
      } else if (percentage >= 40 && percentage < 60) {
         return PlayerHittingRanks.SKILLED;
      } else if (percentage >= 20 && percentage < 40) {
         return PlayerHittingRanks.NOVICE;
      } else if (percentage >= 0 && percentage < 20) {
         return PlayerHittingRanks.ROOKIE;
      } else {
         return 'Invalid Rank';
      }
   };


   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 1000);
   }, []);

   const searchQuaryHandler = (e) => {
      setSearchQuery(e.target.value);
      setCurrentPage(0)
   }

   const loadingIndicator = (
      <div className="w-100 text-center">
         <div className={styles.loaderContainer}>
            <div className="lds-facebook">
               <div></div>
               <div></div>
               <div></div>
            </div>
         </div>
      </div>);
   return (
      <div className="pb-4">
         <div className={classNames(styles.ResponceCont, "col-12", "row", "ps-2", "pe-2", "ps-xxl-0", "pe-xxl-0")}>
            <div className="col-12 gx-2 gx-sm-4 p-0">
               <NavbarSecondary page="matches"/>
            </div>
            {!translationsLoaded ? <>{loadingIndicator}</> : <>
               <div className="row gx-0 gx-sm-4 mt99 p-0">
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 p-0">
                     <h1 className={classNames("mainHeaderH1", "mt-0")}>
                        {t("PLAYERS.PLAYERS")}
                     </h1>
                  </div>
                  <div className="col-12 cil-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 selectWrapperRes selectWrapper p-0">
                     <div className="fc-wrapper player-fc-wrapper float-right">
                        {userName ? <></> :
                           <InputGroup className="searchtxt playerSearch">
                              <Form.Control style={{height: '54px', fontSize: '16px'}} placeholder={t("PLAYERS.SEARCH")} onChange={searchQuaryHandler}
                                            aria-label="Search..." aria-describedby="basic-addon1"/>
                              <InputGroup.Text id="basic-addon1">
                                 <FaSearch/>
                              </InputGroup.Text>
                           </InputGroup>
                        }
                     </div>
                  </div>
               </div>
               <div className="row gx-0 gx-sm-4 p-0">
                  <div className="col-12 p-0">
                     <div className="row gx-0 mt40">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9">
                           {loadingInProgress ? <div className="w-100 text-center">
                                 <div className={styles.loaderContainer}>
                                    <div className="lds-facebook">
                                       <div></div>
                                       <div></div>
                                       <div></div>
                                    </div>
                                 </div>
                              </div>
                              : <>
                                 <div className="matchCards mb40">
                                    {players ? <>
                                          {players?.map((player) => (
                                             <Link className="matchCardInnerWrapper threecards player-wrapper" to={{pathname: `/player/${player?.playerId}`, search: `returnUrl=${encodeURIComponent(location.pathname)}`}}>
                                                <div className="teamDetail teamsTeamDetail">
                                                   {props.Token ?
                                                      // {favouriteLoading ?
                                                      //     <Puff className={styles.puffWrapper} height={'1em'} strokeWidth={5} speed={2}/> :
                                                      //  <a>{favouritePlayerController(player?.playerName, player?.playerId)}</a>}
                                                      <a>{favouritePlayerController(player?.playerName, player?.playerId)}</a>
                                                      :
                                                      <></>
                                                   }

                                                   <div className="TeamsteamLogo">
                                                      <div className="imgInnerWrap">
                                                         <img src={player?.pictureURL} alt={player?.playerName || ""}/>
                                                      </div>
                                                   </div>
                                                   <span className="TeamsteamName">{player?.playerName && player?.playerName.length > 15 ? player.playerName.substring(0, 17) + '...' : player?.playerName}</span>
                                                   <span className="winsLabel">
                                                                                {/*<img src={player?.teamPictureURL}/>*/}
                                                      {console.log("player  sdsdsd", player)}
                                                      {player?.teamList.length > 0 ?
                                                         <Link to={{pathname: `/team/${player.teamList[0].teamId}`, search: `returnUrl=${encodeURIComponent(location.pathname)}`}}>
                                                            <span href="" className={classNames(styles.subLinkPurpe)}>{t("PLAYERS.1vs1")} <FaExternalLinkAlt style={{marginLeft: 5}}/></span>
                                                         </Link> : <></>
                                                      }
                                                                            </span>
                                                   <div className="progressBarWrapper">
                                                      <span className="playerRole">{t("PLAYERS.HITTING_POWER")}</span>
                                                      <span className="playerLevel">
                                                                                    {getPlayerHittingRank(player?.hittingPower)}
                                                                                </span>
                                                      <span className="progressGroove">
                                                                                <span className="progresStick" style={{width: `${player?.hittingPower}%`}}>
                                                                                    <span className="progressDot"></span>
                                                                                </span>
                                                                            </span>
                                                   </div>
                                                </div>
                                                <div className="cardFooter">
                                          <span className="roleLeft">
                                          <label className="roleLbl">{t("PLAYERS.PLAYING_ROLE")}</label>
                                       <label className="roleVal">{playerRoleStyleController(player?.playingRole, pageLang)}</label>
                                       </span>
                                                   <span className="batStyle">
                                       <label className="roleLbl">{t("PLAYERS.BATTING_STYLE")}</label>
                                                                                <label className="roleVal">{playerRoleStyleController(player?.battingStyle, pageLang)}</label>
                                                                            </span>
                                                   <span className="bowlingStyle">
                              <label className="roleLbl">{t("PLAYERS.BOWLING_STYLE")}</label>
                        <label className="roleVal">{playerRoleStyleController(player?.bowlingStyle, pageLang)}</label>
                     </span>
                                                </div>
                                             </Link>
                                          ))}
                                       </>
                                       :
                                       <div className="matchCards mb40">{t("PLAYERS.0_PLAYERS_FOUND!")}</div>
                                    }
                                 </div>
                                 <div className="pageWrap">
                                    <Paginator postsPerPage={9} totalElements={totalElements} currentPage={currentPage} paginate={paginate}/>
                                 </div>
                              </>
                           }
                        </div>
                        <div className="col-12 col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 d-md-none d-sm-none d-none d-lg-block">
                           <div className="row gx-0">
                              {SHOW_PLAYERS_PAGE_ADVERTISEMENT_URL ?
                                 <div className="col-12">
                                    <div className="adImgWrap" style={{marginBottom: 25}}><img src={SHOW_PLAYERS_PAGE_ADVERTISEMENT_URL} alt=""/></div>
                                 </div> : <></>}
                              {SHOW_PLAYERS_PAGE_ADVERTISEMENT_URL2 ?
                                 <div className="col-12">
                                    <div className="adImgWrap"><img src={SHOW_PLAYERS_PAGE_ADVERTISEMENT_URL2} alt=""/></div>
                                 </div> : <></>}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className={loadingInProgress && classNames(styles.Footer)}>
                  <Footer/>
               </div>
            </>}
         </div>
      </div>
   )
      ;
};

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      email: state.auth.email,
      Token: state.auth.accessToken,
      RefreshToken: state.auth.refreshToken,
      timeOut: state.auth.sessionTimeout,
   };
};
export default connect(mapStateToProps)(Players);
