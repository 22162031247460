/****---stable version 4.0.0 ---****/
/****--- QBOT ---***/
import React, {useCallback, useEffect, useState} from 'react'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {connect} from "react-redux";
import styles from "./FantasyBot.module.scss";
import classNames from "classnames";
import Classnames from "classnames";

import SprtsToken from "../../assets/images/icons/24_sprts_token.webp";
import OmniPoints from "../../assets/images/icons/24_xp.webp";
import {getConf} from "../../services/ConfigService";
import {WALLET} from "../../utilities/ConfigConstants";
import {t} from "i18next";
import {checkUserFantasySubscription, getFantasyTeamByUsernameAndTournamentId} from "../../services/FantasyService";
import Styles from "../Tournament/FantasyTeamSection/TournamentFantasySection.module.scss";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {getTournamentById} from "../../services/TournamentService";
import {getShowingFantasyPoints} from "../../helpers/CommonFunctions";
import MoreInfoIcon from "../../assets/images/icons/more_info_icon.svg";
import Calendar from "../../assets/images/fi_calendar.webp";

function FantasyBot(props) {
   const XP_TO_SPRTS_RATIO = getConf(WALLET.XP_TO_SPRTS_RATIO);
   const CHECKING_TOAST_ID = "checkingToast";
   const CHECKING_INTERVAL = 30000;
   const MAX_TRIES = 3;
   const navigate = useNavigate();

   const [pointFetchTries, setPointFetchTries] = useState(0);

   const [matchData, setMatchData] = useState({});
   const [tournamentId, setTournamentId] = useState(undefined);
   const [checkingSubscription, setCheckingSubscription] = useState(true);
   const [isFetchingFantasyTeam, setIsFetchingFantasyTeam] = useState(true);
   const [isSubscribed, setIsSubscribed] = useState(false);

   const [fantasyTeam, setFantasyTeam] = useState([]);
   const [teamCaptain, setTeamCaptain] = useState(null);
   const [teamViceCaptain, setTeamViceCaptain] = useState(null);
   const [totalPoints, setTotalPoints] = useState(0);
   const [fantasyTeamName, setFantasyTeamName] = useState('');
   const [activeStatus, setActiveStatus] = useState(false);

   const [shouldGetFantasyPoints, setShouldGetFantasyPoints] = useState(false);

   const fetchFantasyTeam = useCallback(async () => {
      getFantasyTeamByUsernameAndTournamentId(props.userName, tournamentId, props.Token)
         .then((response) => {
            if (response.apiStatus && response.team) {
               response?.team.forEach(player => {
                  // set captain and vice captain
                  if (player.isCaptain) {
                     setTeamCaptain(player);
                  } else if (player.isVcCaptain) {
                     setTeamViceCaptain(player);
                  }
               });

               setTotalPoints(prevState => {
                  console.log("FantasyBot points changed:", prevState, response?.totalPoints);
                  if (prevState !== response?.totalPoints) {
                     setShouldGetFantasyPoints(false);
                     toast.update(CHECKING_TOAST_ID, {
                        render: t("FANTASY.FANTASY_POINTS_CALCULATED_SUCCESSFULLY"),
                        type: toast.TYPE.SUCCESS,
                        autoClose: 5000,
                        isLoading: false,
                     })
                  }
                  return response?.totalPoints || 0;
               })
               setFantasyTeamName(response?.teamName || '')
               setFantasyTeam(response?.team);
               setActiveStatus(response?.activeStatus);
            }
         })
         .finally(() => {
            setIsFetchingFantasyTeam(false);
         });
   }, [props.Token, props.userName, tournamentId]);

   // navigate to create fantasy team page
   const handleCreateFantasyTeam = async () => {
      const res = await getTournamentById(tournamentId)
      if (res?.apiStatus && res?.tournament?.id) {
         const tournamentDetails = res?.tournament;
         // check if the tournament is completed
         if (tournamentDetails?.active === false && tournamentDetails?.toStart === false) {
            toast.warning(t("FANTASY.YOU_CANT_CREATE_FANTASY_TEAM_FOR_A_COMPLETED_TOURNAMENT"));
         } else {
            navigate(`/fantasy-team/${tournamentId}`, {state: {tournamentDetails: tournamentDetails, returnPath: `/match/${matchData?.matchId}`}})
         }
      } else {
         toast.error(t("FANTASY.TOURNAMENT_NOT_FOUND"));
      }
   }

   // get classes when captain and vice captain are not present
   const getCaptainRowClasses = () => {
      if (!teamCaptain && !teamViceCaptain) {
         return classNames(styles.CardWrapper, "row", styles.NoAnyCaptain);
      } else if (!teamCaptain || !teamViceCaptain) {
         return classNames(styles.CardWrapper, "row", styles.NoOneCaptain);
      } else {
         return classNames(styles.CardWrapper, "row");
      }
   }

   useEffect(() => {
      console.log("FantasyBot props changed");
      if (props?.matchData) {
         setTournamentId(props.matchData?.tournamentId);
         setMatchData(prevState => {
            if (prevState?.live === true && props.matchData?.live === false) {
               console.log("FantasyBot match ended");
               setShouldGetFantasyPoints(true);
               toast.loading(t("FANTASY.CALCULATING_FANTASY_POINTS"), {
                  toastId: CHECKING_TOAST_ID,
               });
            }

            return props.matchData;
         })
      }
   }, [props.matchData]);

   // check if user is subscribed to fantasy team
   useEffect(() => {
      console.log("FantasyBot checking subscription", tournamentId);
      const checkSubscription = async () => {
         setCheckingSubscription(true);
         checkUserFantasySubscription(tournamentId, props.userName, props.Token)
            .then((response) => {
               if (response.apiStatus && response.isSubscribed === true) {
                  setIsSubscribed(true);
                  setCheckingSubscription(false);
               } else if (response.apiStatus) {
                  setIsSubscribed(false);
                  setCheckingSubscription(false);
               }
            })
      }

      if (tournamentId) {
         checkSubscription();
      }
   }, [props.Token, props.userName, tournamentId]);

   // get fantasy team of the user
   useEffect(() => {
      console.log("FantasyBot fetching fantasy team", tournamentId);
      if (isSubscribed) {
         setIsFetchingFantasyTeam(true);
         fetchFantasyTeam();

         // fetch fantasy team every 30 seconds
         const interval = setInterval(() => {
            console.log("FantasyBot fetching fantasy team", tournamentId);
            if (isSubscribed) {
               fetchFantasyTeam();
            } else {
               clearInterval(interval);
            }
         }, CHECKING_INTERVAL);
         return () => clearInterval(interval);
      }
   }, [fetchFantasyTeam, isSubscribed, props.Token, props.userName, tournamentId]);

   // handle get fantasy points at the end of the match
   useEffect(() => {
      if (shouldGetFantasyPoints && pointFetchTries < MAX_TRIES) {
         const interval = setInterval(() => {
            // console.log("FantasyBot fetching fantasy team in interval", pointFetchTries);
            // fetchFantasyTeam(); // commented because calling it every 30 seconds handling in another useEffect
            setPointFetchTries(prevState => prevState + 1);
         }, CHECKING_INTERVAL);

         return () => clearInterval(interval);
      } else if (pointFetchTries >= MAX_TRIES) {
         console.log(`Max attempts (${MAX_TRIES}) reached, stopping interval`);
         toast.dismiss(CHECKING_TOAST_ID);
      }
   }, [fetchFantasyTeam, pointFetchTries, shouldGetFantasyPoints]);

   const loadingIndicator = (<div className={styles.loaderContainer} style={{textAlign: "center"}}>
      <div className="lds-facebook">
         <div></div>
         <div></div>
         <div></div>
      </div>
   </div>);

   const PlayerCard = ({player, role}) => {
      return <div className={classNames(styles.Card)}>
         <div className={classNames(styles.CardBackground)}>
            <div className="row">
               <a className={classNames(Styles.HistoryInfoIconBtn, "HistoryInfoIconBtn")} role={"button"}
                  onClick={() => props?.setShowFantasyMatchHistoryModal(player?.playerId, player?.playerName, player?.playerPictureURL)}>
                  <img src={Calendar}/>
               </a>
               <a className={classNames(Styles.MoreInfoIconBtn, "MoreInfoIconBtn")} role={"button"}
                  onClick={() => props.setShowPlayerInfoModal(player?.playerId)}>
                  <img src={MoreInfoIcon}/>
               </a>
               <div className={classNames(styles.ProfilePic)}
                    style={{backgroundImage: `url(${player?.playerPictureURL})`}}>
                  {(() => {
                     const playerPoints = getShowingFantasyPoints(player?.points);
                     return (<div className={classNames(styles.PlayerPoints, "PlayerPoints")}>
                        <span className={classNames(Styles.PointSplus, "PointSplus")}>{playerPoints.sign}</span>
                        <div className={classNames(Styles.PointsWrapper, "PointsWrapper", playerPoints.intPart === 0 ? 'noPoints' : '')}>
                           <span className={classNames(Styles.Points, "Points")}>{playerPoints.intPart}</span>
                           <span className={classNames(Styles.DecimalPoints, "DecimalPoints")}>{playerPoints.decPart}</span>
                        </div>
                     </div>);
                  })()}
               </div>
            </div>
            <div className={classNames(styles.TitleRow, "row")}>
               <h4>{player?.playerName}</h4>
               {role === "CAPTAIN" ? <span>{t("FANTASY.CAPTAIN")}</span> : role === "VICE_CAPTAIN" ?
                  <span>{t("FANTASY.VICE_CAPTAIN")}</span> : null}
            </div>
         </div>
      </div>
   }

   const fantasyTeamStatusToast = (activeStatus) => {
      if (activeStatus) {
         toast.success("Your fantasy team is Active")
      } else {
         toast.error("Team is still inactive, will get active from next match onwards.")
      }
   }

   return (
      <div className={classNames(styles.quizBoardWrapper, styles.FantasyzBoardWrapper, "App")}>
         <div className="chatbothead d-none d-md-block p-relative">
            <div className={classNames(styles.TitleWrapper)}>
               {/* <h2>{t("FANTASY.FANTASY_TEAM")}</h2> */}
               <h2>MY FANTASY TEAM</h2>
               <a className={classNames(styles.MoreInfoIconBtn)} onClick={() => {
                  props.setShowHowFantasyWorksModal(true)
               }}>
                  <img src={MoreInfoIcon}/>
               </a>
            </div>
         </div>

         {checkingSubscription || (isSubscribed && isFetchingFantasyTeam) || props?.parentLoading ? loadingIndicator : <>{!isSubscribed ?
            <div className={classNames(Styles.WinPrizesWrapper)}>
               <div className={classNames(Styles.Tournament)}>
                  <div className={classNames(Styles.NoDataWrapper)}>
                     <div className={classNames(Styles.NoFantasyDataWrapper)}>
                        <p>
                           {t("FANTASY.JOIN_THE_META_11_FANTASY_TOURNAMENT_BUILD_YOUR_DREAM_TEAM_AND_SCORE_BIG_TO_WIN_SPRTS_TOKENS_LEARN_MORE")}
                           <button
                              className={Classnames(Styles.SeeFantasyPointsCalculation, "bg-transparent border-0 text-white")}
                              onClick={() => {
                                 props.setShowHowFantasyWorksModal(true)
                              }}
                           >
                              {t("FANTASY.LEARN_MORE")}
                           </button>
                        </p>
                     </div>
                     <div className={classNames(Styles.NoFantasyDataButtonWrapper)}>
                        <button className="lightGreenButton" onClick={handleCreateFantasyTeam}>
                           {t("FANTASY.CREATE_FANTASY_TEAM")}
                        </button>
                     </div>
                  </div>
               </div>
            </div> :
            <div style={{position: "relative", minHeight: "fit-content", width: "100%", backgroundColor: "rgb(2, 19, 27)"}}>
               <div className={classNames(styles.FantasyCardWrapper)}>

                  <div className="text-center mb-2">
                     <div className={classNames(Styles.ActiveStatusTagWrapper, "ActiveStatusTagWrapper")}>
                        {!activeStatus ?
                           <div className={classNames(Styles.TournamentStatus, Styles.InactiveStatus, "TournamentStatus InactiveStatus")} role={'button'} onClick={() => fantasyTeamStatusToast(activeStatus)}><span></span>{t("FANTASY.INACTIVE")}</div> :
                           <div className={classNames(Styles.TournamentStatus, Styles.ActiveStatus, "TournamentStatus ActiveStatus")} role={'button'} onClick={() => fantasyTeamStatusToast(activeStatus)}><span></span>{t("FANTASY.ACTIVE")}</div>
                        }
                     </div>
                  </div>
                  <div className="row">
                     <h2>{fantasyTeamName}</h2>
                  </div>
                  <div className={classNames(styles.CardWrapper, "row")}>
                     {teamCaptain && <PlayerCard player={teamCaptain} role="CAPTAIN"/>}
                     {teamViceCaptain && <PlayerCard player={teamViceCaptain} role="VICE_CAPTAIN"/>}
                     {fantasyTeam.map(player => {
                        if (!player.isCaptain && !player.isVcCaptain) {
                           return (<PlayerCard player={player}/>)
                        } else {
                           return null;
                        }
                     })}
                  </div>
                  <div className="row">
                     <div className={classNames(styles.TotalEarningWrapper)}>
                        <div className={classNames(styles.TotalEarning)}>
                           <div className={classNames(styles.Title)}>
                              {t("FANTASY.TOTAL_EARNING")}
                           </div>
                           <div className={classNames(styles.SprtsToken)}>
                              <img
                                 src={SprtsToken}/>{totalPoints ? (totalPoints / XP_TO_SPRTS_RATIO).toFixed(3) : 0}
                           </div>
                           <div className={classNames(styles.OmniPoints)}>
                              <img src={OmniPoints}/>{totalPoints}
                           </div>
                        </div>
                     </div>
                  </div>

               </div>

            </div>}</>}

         <div className="chatbothead d-block d-md-none p-relative">
            <h2>{t("FANTASY.FANTASY_TEAM")}</h2>
         </div>
      </div>
   )
}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      refresh: state.auth.refreshToken,
      Token: state.auth.accessToken,
      userId: state.auth.userId,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      email: state.auth.email
   };
}

export default connect(mapStateToProps)(FantasyBot)
