import React, {useEffect, useState} from "react";
import Styles from "./OngoingTournaments.module.scss";
import Classnames from "classnames";
import Footer from "../../components/Footer/Footer";
import NavbarSecondary from "../../components/NavigationBar/NavigationBar";
import CreditIcon from "../../assets/images/icons/24_sprts_token.webp";
import * as TournamentService from "../../services/TournamentService"
import {useLocation, useNavigate} from "react-router-dom";
import Paginator from "../../components/Paginator/Paginator";
import PaymentConfirmModal from "../../components/Wallet/PaymentConfirmModal/PaymentConfirmModal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import {FaSearch} from "react-icons/fa";
import UserIcon from "../../assets/images/usericon.webp";
import VSIcon from "../../assets/images/vs.webp";
import {useTranslation} from "react-i18next";
import {MATCH_CARD_TYPES, TOURNAMENT_TYPES} from "../../utilities/Constants";
import Winner from "../../assets/images/Winner.webp";
import TournamentTimer from "./TournamentTimer";
import styles from "../../components/Wallet/PaymentConfirmModal/PaymentConfirmModal.module.scss";
import moment from "moment/moment";
import {connect} from "react-redux";
import QRIconForModal from "../../assets/images/qr_code_WA_modal.webp";
import QRIcon2ForModal from "../../assets/images/qr_code_TG_modal.webp";
import Telegram from "../../assets/images/Telegram.webp";
import {toast} from "react-toastify";
import {AUTHENTICATION_TOASTS} from "../../utilities/ToastMessages";
import * as PubNubService from "../../services/PubNubService";
import OngoingTournamentsHandler from "../../services/PubNubHandlers/OngoingTournamentsHandler";
import {checkUserFantasySubscription} from "../../services/FantasyService";


const OngoingTournaments = (props) => {
      // const screenSize = window.innerWidth;

      const [tournamentDetails, setTournamentDetails] = useState(null);
      const location = useLocation();
      const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);
      const [loadingInProgress, setLoading] = useState(true);
      const [currentPage, setCurrentPage] = useState(parseInt(new URLSearchParams(location.state).get('currentPage')) || 0);
      const [postsPerPage, setPostsPerPage] = useState(5);
      const [totalElements, setTotalElements] = useState(-1);
      const {t} = useTranslation();
      const [pageLang, setPageLang] = useState(localStorage.getItem('language'));
      const [translationsLoaded, setTranslationsLoaded] = useState(false);
      const [selectedRadioBtnValue, setSelectedRadioBtnValue] = useState(new URLSearchParams(location.state).get('type') || 'all');
      const [searchQuery, setSearchQuery] = useState('');
      const [liveTournaments, setLiveTournaments] = useState([]);
      const [scheduledTournaments, setScheduledTournaments] = useState([]);
      const [completedTournaments, setCompletedTournaments] = useState([]);
      const [isQRModalOpen, setIsQRModalOpen] = useState(false);
      const [selectedQR, setSelectedQR] = useState('');
      const [isRegistrationLoading, setIsRegistrationLoading] = useState(false);
      let navigate = useNavigate();
      let uuid = localStorage.getItem("uuid");
      const [connectPubnub, setConnectPubnub] = useState(false);
      const [pubNubToken, setPubNubToken] = useState(null);
      const [subscribeChannel, setSubscribeChannel] = useState(null);
      const [unSubscribeChannel, setUnsubscribeChannel] = useState(null);

      const paginate = (pageNumber, postsPerPage) => {
         setPostsPerPage(Number(postsPerPage));
         setCurrentPage(pageNumber);
      };

      const handleRadioBtnValue = (event) => {
         setCurrentPage(0)
         setSelectedRadioBtnValue(event.target.value);
      };

      const handleSearchChange = (event) => {
         setCurrentPage(0)
         setSearchQuery(event.target.value)
      };

      useEffect(() => {
         setTimeout(() => {
            setTranslationsLoaded(true);
         }, 2000);
      }, []);

      const handleCheckUserFantasySubscription = async (tournamentId) => {
         try {
            const response = await checkUserFantasySubscription(tournamentId, props.userName, props.Token);
            if (response.apiStatus && response?.isSubscribed) {
               return response?.isSubscribed;
            } else if (response.apiStatus) {
               return false;
            }
            return null;
         } catch (error) {
            console.error("handleCheckUserFantasySubscription", error);
         }
      };

      const handleGoToTournamentButtonClick = async (pathname, search, tournament, e) => {
         sessionStorage.setItem("SelectedOption", "Matches");
         if (e) {
            e.stopPropagation();
         }

         // check if the tournament is active or to start
         if (tournament.active === true || tournament.toStart === true) {
            // Check if user is subscribed to fantasy. If not redirect to fantasy team creation page
            const isFantasySubscribed = await handleCheckUserFantasySubscription(tournament.id);
            if (isFantasySubscribed === false) {
               navigate(`/fantasy-team/${tournament.id}`, {state: {tournamentDetails: tournament}});
               return;
            }
         }

         if (!tournament?.geoBlockedForUser) {
            if (tournament?.tournamentTicketOmniCredits > 0 && !props.Token) {
               toast.error(t("ONGOING_TOURNAMENTS.PLEASE_SIGN_IN_TO_ACCESS_THE_PAID_TOURNAMENT"));
               navigate("/login");
            } else {
               const tournamentUrl = `${pathname}?${search}`;
               window.location.href = tournamentUrl;
            }
         } else {
            toast.error(AUTHENTICATION_TOASTS.TOURNAMENT_IS_BLOCKED)
         }
      };

      const messageFromTournamentHandlerCallback = (message) => {

         if (selectedRadioBtnValue != MATCH_CARD_TYPES.COMPLETED && selectedRadioBtnValue != MATCH_CARD_TYPES.PURCHASED) {
            try {
               let json = JSON.parse(message);
               filterTournaments(json.body);

            } catch (error) {
               console.error("JSON parsing error:", error);
            }
         }
      }

      const filterTournaments = (response) => {
         if (response.tournaments) {
            setScheduledTournaments(response?.tournaments?.filter((tournament) => tournament.active === false && tournament.toStart === true));
            setLiveTournaments(response?.tournaments?.filter((tournament) => tournament.active === true && tournament.toStart === false));
            setCompletedTournaments(response?.tournaments?.filter((tournament) => tournament.active === false && tournament.toStart === false));
         }
      };

      const openModal = (qrName, e) => {
         e.stopPropagation();
         if (qrName == 'whatsapp') {
            setSelectedQR(QRIconForModal);
            setIsQRModalOpen(true);
         } else {
            setSelectedQR(QRIcon2ForModal);
            setIsQRModalOpen(true);
         }
      };

      useEffect(() => {
         const fetchOngoingTournaments = async () => {
            setLoading(true);

            await TournamentService.getAllTournaments(searchQuery, currentPage, postsPerPage, "active,startTime,desc", selectedRadioBtnValue, pageLang, props.Token).then((response) => {
               if (response?.apiStatus && response.totalElements != 0) {
                  setTotalElements(response.totalElements);
                  filterTournaments(response);

                  window.scrollTo({top: 0, behavior: 'smooth'});
                  setLoading(false);
               } else if (response.totalElements == 0) {
                  setTotalElements(0);
                  setLoading(false);
               }
            })
         }

         fetchOngoingTournaments();
      }, [selectedRadioBtnValue, searchQuery, currentPage]);

      useEffect(() => {

         const fetchData = async () => {
            try {
               const response = await PubNubService.requestAccessTokenMatches(uuid, props.Token).then(response => {
                  if (response != null) {
                     setPubNubToken(response);
                     setConnectPubnub(true);
                     console.log(response);
                     localStorage.setItem("pubnubToken", response);
                  }
               });

            } catch (error) {
               console.error("Error:", error);
            }

         };
         fetchData();
      }, [uuid, selectedRadioBtnValue]);

      const renderAllTournaments = () => {
         if (loadingInProgress) {
            return loadingIndicator;
         }

         return totalElements === 0 ? (
            <div className={Styles.loaderContainer}>
               <div className={Classnames(Styles.NoTournamentsFound, "gray-text font18")}>
                  {t("ONGOING_TOURNAMENTS.NO_TOURNAMENTS_FOUND")}
               </div>
            </div>
         ) : (
            <>
               {liveTournaments?.map((tournamentObj) => liveTournamentCard(tournamentObj))}
               {scheduledTournaments?.map((tournamentObj) => scheduledTournamentCard(tournamentObj))}
               {completedTournaments?.map((tournamentObj) => completedTournamentCard(tournamentObj))}
            </>
         )
      }

      const checkTournamentRegistration = async (tournamentId) => {
         try {
            const response = await TournamentService.checkTournamentRegistration(tournamentId, props.userName);
            if (response.apiStatus) {
               return response.available;
            }
            return null;
         } catch (error) {
            console.log(error);
            throw error;
         }
      }


      const getTournamentType = (tournamentType) => {
         if (tournamentType === TOURNAMENT_TYPES.LEAGUE) {
            return t("ONGOING_TOURNAMENTS.LEAGUE");
         } else if (tournamentType === TOURNAMENT_TYPES.KNOCKOUT) {
            return t("ONGOING_TOURNAMENTS.KNOCKOUT");
         } else if (tournamentType === TOURNAMENT_TYPES.LEAGUE_KNOCKOUT) {
            return t("ONGOING_TOURNAMENTS.LEAGUE_KNOCKOUT");
         }
      }

      //TODO LockUnlock
      const tournamentButtonController = (tournamentObj) => {
         if (props.isAuthenticated === true) {
            if (tournamentObj.tournamentTicketOmniCredits) {
               return (
                  <div className={Classnames(Styles.buttonRow, "ml-1")}>
                     {/*Lock*/}
                     <button className={Classnames(Styles.playNow)} disabled={isRegistrationLoading} onClick={(e) => showPurchaseModal(tournamentObj, e)}>
                        {t("ONGOING_TOURNAMENTS.ENTER_HERE")}
                     </button>
                  </div>
               )
            } else if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.KNOCKOUT) {
               return (
                  <div className={Classnames(Styles.buttonRow, "ml-1")}>
                     {/*unlock*/}
                     <button className={Classnames(Styles.playNow, 'mt-0')}
                             onClick={(e) => handleGoToTournamentButtonClick(`/knockout/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj, e)}>
                        {t("ONGOING_TOURNAMENTS.ENTER_HERE")}
                     </button>
                  </div>
               )
            } else if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.LEAGUE) {
               return (
                  <div className={Classnames(Styles.buttonRow)}>
                     {/*unlock*/}
                     <button className={Classnames(Styles.playNow, 'mt-0')}
                             onClick={(e) => handleGoToTournamentButtonClick(`/league/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj, e)}>
                        {t("ONGOING_TOURNAMENTS.ENTER_HERE")}
                     </button>
                  </div>
               )
            } else if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.LEAGUE_KNOCKOUT) {
               return (
                  <div className={Classnames(Styles.buttonRow)}>
                     {/*unlock*/}
                     <button className={Classnames(Styles.playNow, 'mt-0')}
                             onClick={(e) => handleGoToTournamentButtonClick(`/league-knockout/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj, e)}>
                        {t("ONGOING_TOURNAMENTS.ENTER_HERE")}
                     </button>
                  </div>
               )
            } else if (tournamentObj.tournamentTicketOmniCredits === 0) {
               if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.KNOCKOUT) {
                  return (
                     <div className={Classnames(Styles.buttonRow)}>
                        {/*unlock*/}
                        <button className={Classnames(Styles.playNow, 'mt-0')} onClick={(e) => handleGoToTournamentButtonClick(`/knockout/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj, e)}>
                           {t("ONGOING_TOURNAMENTS.ENTER_HERE")}
                        </button>
                     </div>
                  )
               } else if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.LEAGUE) {
                  return (
                     <div className={Classnames(Styles.buttonRow)}>
                        {/*unlock*/}
                        <button className={Classnames(Styles.playNow, 'mt-0')} onClick={(e) => handleGoToTournamentButtonClick(`/league/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj, e)}>
                           {t("ONGOING_TOURNAMENTS.ENTER_HERE")}
                        </button>
                     </div>
                  )
               } else if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.LEAGUE_KNOCKOUT) {
                  return (
                     <div className={Classnames(Styles.buttonRow)}>
                        {/*unlock*/}
                        <button className={Classnames(Styles.playNow, 'mt-0')} onClick={(e) => handleGoToTournamentButtonClick(`/league-knockout/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj, e)}>
                           {t("ONGOING_TOURNAMENTS.ENTER_HERE")}
                        </button>
                     </div>
                  )
               }
            }
         } else {
            if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.KNOCKOUT) {
               return (
                  <div className={Classnames(Styles.buttonRow)}>
                     <button className={Classnames(Styles.playNow, 'mt-0')} onClick={(e) => handleGoToTournamentButtonClick(`/knockout/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj, e)}>
                        {t("ONGOING_TOURNAMENTS.ENTER_HERE")}
                     </button>
                  </div>
               )
            } else if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.LEAGUE) {
               return (
                  <div className={Classnames(Styles.buttonRow)}>
                     <button className={Classnames(Styles.playNow, 'mt-0')} onClick={(e) => handleGoToTournamentButtonClick(`/league/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj, e)}>
                        {t("ONGOING_TOURNAMENTS.ENTER_HERE")}
                     </button>
                  </div>
               )
            } else if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.LEAGUE_KNOCKOUT) {
               return (
                  <div className={Classnames(Styles.buttonRow)}>
                     <button className={Classnames(Styles.playNow, 'mt-0')} onClick={(e) => handleGoToTournamentButtonClick(`/league-knockout/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj, e)}>
                        {t("ONGOING_TOURNAMENTS.ENTER_HERE")}
                     </button>
                  </div>
               )
            }
         }
      }

      const tournamentCardClickHandler = (tournamentObj, e) => {
         if (props.isAuthenticated === true) {
            if (tournamentObj.tournamentTicketOmniCredits) {
               showPurchaseModal(tournamentObj);
            } else if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.KNOCKOUT) {
               handleGoToTournamentButtonClick(`/knockout/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj, e)

            } else if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.LEAGUE) {
               handleGoToTournamentButtonClick(`/league/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj, e)

            } else if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.LEAGUE_KNOCKOUT) {
               handleGoToTournamentButtonClick(`/league-knockout/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj, e)

            } else if (tournamentObj.tournamentTicketOmniCredits === 0) {
               if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.KNOCKOUT) {
                  handleGoToTournamentButtonClick(`/knockout/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj, e)

               } else if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.LEAGUE) {
                  handleGoToTournamentButtonClick(`/league/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj, e)

               } else if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.LEAGUE_KNOCKOUT) {
                  handleGoToTournamentButtonClick(`/league-knockout/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj, e)
               }
            }
         } else {
            if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.KNOCKOUT) {
               handleGoToTournamentButtonClick(`/knockout/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj, e)
            } else if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.LEAGUE) {
               handleGoToTournamentButtonClick(`/league/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj, e)
            } else if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.LEAGUE_KNOCKOUT) {
               handleGoToTournamentButtonClick(`/league-knockout/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj, e)
            }
         }
      }

      const liveTournamentCard = (tournamentObj) => (
         <div className={Classnames(Styles.DailyMatchesWrapper, Styles.MatchWrapper, Styles.LiveTournamentCard)} onClick={(e) => tournamentCardClickHandler(tournamentObj, e)}
              style={{cursor: "pointer", backgroundImage: tournamentObj.logoUrl ? `url(${tournamentObj.logoUrl})` : "url('https://storage.googleapis.com/meta11/serviceplatform/Daily_Matches_back.png')"}}>
            <div className="row">
               <div className="col-md-12 gx-0">
                  <div className="row">
                     <div class="d-none d-md-inline">
                        <div className={Classnames(Styles.QRCotainer)}>
                           {/* <div className={Classnames(Styles.QRCodeWrapper)} onClick={() => openModal('whatsapp')}>
                                    <img src={WhatsApp} alt="WhatsApp"/>
                                 </div> */}
                           <div className={Classnames(Styles.QRCodeWrapper)} onClick={(e) => openModal('telegram', e)} style={{cursor: 'pointer'}}>
                              <img src={Telegram} alt="Telegram"/>
                           </div>
                        </div>
                     </div>

                     <div className={Classnames(Styles.MobileHeaderRow1, "m-0")}>
                        {/* <label className={Classnames(Styles.PlayNowliveTag)}>
                           <div className={Classnames(Styles.liveTagWrapper)}>
                              <span></span>
                                 {t("ONGOING_TOURNAMENTS.PLAY_NOW")}
                           </div>
                        </label> */}

                        <div className={Classnames(Styles.PlayNowLiveTagContainer)}>
                           <div className={Classnames(Styles.PlayNowLiveTagWrapper)}>
                           </div>
                           <span className={Classnames(Styles.PlayNowLiveTag, "matchStatslbl-")}>
                            <span className={Classnames(Styles.BlinkingDot, "livedot blinking")}></span>
                           <div className={Classnames(Styles.LiveTag)}>{t("ONGOING_TOURNAMENTS.PLAY_NOW")}</div>
                        </span>
                        </div>

                        <label className={Classnames(Styles.KnockoutTag)}>{getTournamentType(tournamentObj?.tournamentType)}</label>
                        <h2>{tournamentObj.name}</h2>
                     </div>
                     <div className="col-md-5 gx-0">
                        <div className={Classnames(Styles.RightColWrapper)}>

                           {/* <div className={Classnames(Styles.UserInforLabel)}>
                              <img src={UserIcon}/>&nbsp;{tournamentObj.tournamentUserCount != null ? tournamentObj.tournamentUserCount : 'N/A'} {tournamentObj?.tournamentUserCount > 1 ? t("ONGOING_TOURNAMENTS.USERS_PLAYING"):t("ONGOING_TOURNAMENTS.USER_PLAYING")}
                           </div> */}

                        </div>
                     </div>
                     <div className={Classnames(Styles.FooterRow)}>
                        <div className={Classnames(Styles.FooterCol1)}>
                           {tournamentObj?.match && tournamentObj?.match[0]?.name ?
                              <div className={Classnames(Styles.TwoPlayers)}>
                                 <div className={Classnames(Styles.PlayerWrapper)}>
                                    <div className={Classnames(Styles.Player)}>
                                       <div className={Classnames(Styles.PlayerThumbWrapper)}>
                                          <img src={tournamentObj?.match[0]?.teamPictureURL}/>
                                       </div>
                                       <span>{tournamentObj?.match[0]?.name}</span>
                                    </div>
                                 </div>
                                 <label><img src={VSIcon}/></label>
                                 <div className={Classnames(Styles.PlayerWrapper)}>
                                    <div className={Classnames(Styles.Player)}>
                                       <div className={Classnames(Styles.PlayerThumbWrapper)}>
                                          <img src={tournamentObj?.match[1]?.teamPictureURL}/>
                                       </div>
                                       <span>{tournamentObj?.match[1]?.name}</span>
                                    </div>
                                 </div>
                              </div> : <></>
                           }
                        </div>
                        <div>
                           <div className={Classnames(Styles.RightColWrapper)}>
                              {/*<div class="d-none d-md-inline">*/}
                              {/*   <div className={Classnames(Styles.QRCotainer)}>*/}
                              {/*      <button className={Classnames(Styles.QRCodeWrapper)} onClick={() => openModal('QR1')}>*/}
                              {/*         <img src={QR1} alt="QR Code 1"/>*/}
                              {/*      </button>*/}
                              {/*      <button className={Classnames(Styles.QRCodeWrapper)} onClick={() => openModal('QR2')}>*/}
                              {/*         <img src={QR2} alt="QR Code 2"/>*/}
                              {/*      </button>*/}
                              {/*   </div>*/}
                              {/*</div>*/}
                              <div className={Classnames(Styles.FooterCol2)}>
                                 <div>
                                    <div className={Classnames(Styles.UserInforLabel, "mb-0 mb-md-2")}>
                                       <img
                                          src={UserIcon}/>&nbsp;{tournamentObj.tournamentUserCount != null ? tournamentObj.tournamentUserCount != 0 ? tournamentObj.tournamentUserCount : '' : 'N/A'} {tournamentObj?.tournamentUserCount > 1 ? t("ONGOING_TOURNAMENTS.USERS_PLAYING") : tournamentObj?.tournamentUserCount == 1 ? t("ONGOING_TOURNAMENTS.USER_PLAYING") : t("ONGOING_TOURNAMENTS.NO_USER_PLAYING")}
                                    </div>
                                 </div>
                                 <div className={Classnames(Styles.buttonRow, "mt-0 mt-md-2")}>

                                    {tournamentObj.tournamentTicketOmniCredits > 0 ?
                                       <div className={Classnames(Styles.creditsWrapper)}>
                                          <div className={Classnames(Styles.credits)}>
                                             <label>{t("ONGOING_TOURNAMENTS.PRICE")}</label><b> : </b>
                                             <img src={CreditIcon}/>
                                             <span>{tournamentObj.tournamentTicketOmniCredits}</span>
                                          </div>
                                       </div> : <div className={Classnames(Styles.creditsWrapper, Styles.noCreditsWrapper)}>
                                          <div className={Classnames(Styles.credits)}>
                                             <label>{t("ONGOING_TOURNAMENTS.FREE")}</label>
                                          </div>
                                       </div>
                                    }
                                    {/*<button className={Classnames(Styles.playNow)}><img className={Classnames(Styles.LockIcon)} src={Lock}/>{t("ONGOING_TOURNAMENTS.PLAY_NOW")}</button>*/}
                                    {tournamentButtonController(tournamentObj)}
                                 </div>
                              </div>
                              {/* --------- */}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      )

      const scheduledTournamentCard = (tournamentObj) => (
         <div className={Classnames(Styles.DailyMatchesWrapper, Styles.MatchWrapper, Styles.UpcomingMatchWrapper)} onClick={(e) => tournamentCardClickHandler(tournamentObj, e)}>
           <div className={Classnames(Styles.BackgroundImageFilter)} 
                 style={{cursor: 'pointer', backgroundImage: tournamentObj.logoUrl ? `url(${tournamentObj.logoUrl})` : "url('https://storage.googleapis.com/meta11/serviceplatform/Daily_Matches_back.png')"}}></div> 
            <div className="row">
               <div className="col-md-12 gx-0">
                  <div className="row gx-0">
                     <div className={Classnames(Styles.HeaderRow)}>
                        <div className={Classnames(Styles.LeftColWrapper)}>
                           <label className={Classnames(Styles.upcomingTag)}>{t("ONGOING_TOURNAMENTS.UPCOMING")}</label>
                           <label className={Classnames(Styles.KnockoutTag, 'pt-3')}>{getTournamentType(tournamentObj?.tournamentType)}</label>
                           <h2>{tournamentObj.name}</h2>
                        </div>
                        <div className={Classnames(Styles.RightColWrapper)}>
                           <div className="d-none d-md-inline">
                              <div className={Classnames(Styles.QRCotainer)}>
                                 {/* <div className={Classnames(Styles.QRCodeWrapper)} onClick={() => openModal('whatsapp')}>
                                    <img src={WhatsApp} alt={"Whatsapp QR"}/>
                                 </div> */}
                                 <div className={Classnames(Styles.QRCodeWrapper)} onClick={(e) => openModal('telegram', e)} style={{cursor: 'pointer'}}>
                                    <img src={Telegram} alt={"Telegram QR"}/>
                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>
                     <div className={Classnames(Styles.FooterRow)}>
                        <div className={Classnames(Styles.FooterCol1)}>
                           <div className={Classnames(Styles.CountDownWrapper)}>
                              <TournamentTimer metaTimer={tournamentObj?.startTime} tournamnetEndTimer={tournamentObj?.endTime}/>
                           </div>

                        </div>
                        <div className={Classnames(Styles.FooterCol2)}>
                           <div className={Classnames(Styles.UserInforLabel, "mb-0 mb-md-2")}>
                              <img
                                 src={UserIcon}/> {tournamentObj.tournamentUserCount != null ? tournamentObj.tournamentUserCount != 0 ? tournamentObj.tournamentUserCount : '' : 'N/A'} {tournamentObj?.tournamentUserCount > 1 ? t("ONGOING_TOURNAMENTS.USERS_SUBSCRIBED") : tournamentObj?.tournamentUserCount == 1 ? t("ONGOING_TOURNAMENTS.USER_SUBSCRIBED") : t("ONGOING_TOURNAMENTS.NO_USER_SUBSCRIBED")}
                           </div>
                           <div className={Classnames(Styles.buttonRow, "mt-0 mt-md-2")}>

                              {/*<button className={Classnames(Styles.playNow)}><img className={Classnames(Styles.LockIcon)} src={Lock}/>Enter here</button>*/}
                              {tournamentObj.tournamentTicketOmniCredits > 0 ?
                                 <div className={Classnames(Styles.creditsWrapper)}>
                                    <div className={Classnames(Styles.credits)}>
                                       <label>{t("ONGOING_TOURNAMENTS.PRICE")}</label><b> : </b>
                                       <img src={CreditIcon}/>
                                       <span>{tournamentObj.tournamentTicketOmniCredits}</span>
                                    </div>
                                 </div> :
                                 <div className={Classnames(Styles.creditsWrapper, Styles.noCreditsWrapper)}>
                                    <div className={Classnames(Styles.credits)}>
                                       <label>{t("ONGOING_TOURNAMENTS.FREE")}</label>
                                    </div>
                                 </div>
                              }
                              {tournamentButtonController(tournamentObj)}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      )

      const completedTournamentCard = (tournamentObj) => (
         <div className={Classnames(Styles.DailyMatchesWrapper, Styles.MatchWrapper, Styles.CompletedTournamentCard)} onClick={(e) => tournamentCardClickHandler(tournamentObj, e)}
              style={{cursor: 'pointer', backgroundImage: tournamentObj.logoUrl ? `url(${tournamentObj?.logoUrl})` : "url('https://storage.googleapis.com/meta11/serviceplatform/Daily_Matches_back.png')"}}>
            <div className={Classnames(Styles.CompletedMatchesWrapper, "row")}>
               <div className="col-md-12 gx-0">
                  <div className="row">
                     <div className="row">
                        <div className={Classnames(Styles.MobileHeaderRow1)}>
                           <label className={Classnames(Styles.completedTag)}>{t("ONGOING_TOURNAMENTS.COMPLETED")}</label>
                           <label className={Classnames(Styles.KnockoutTag)}>{getTournamentType(tournamentObj?.tournamentType)}</label>
                           <h2>{tournamentObj.name}</h2>
                        </div>
                        <div className="col-md-5 gx-0">
                           {/* <div className={Classnames(Styles.qrSpace,'d-none d-md-inline')}></div> */}
                           {/* <div className={Classnames(Styles.buttonRow,'mt-0')}> */}
                           {/* <div  className={Classnames(Styles.UserInforLabel)}>
                                    <img src={UserIcon}/>&nbsp;{tournamentObj.tournamentUserCount != null ? tournamentObj.tournamentUserCount : 'N/A'} {tournamentObj?.tournamentUserCount > 1 ? t("ONGOING_TOURNAMENTS.USERS_PLAYED"):t("ONGOING_TOURNAMENTS.USER_PLAYED")}
                              </div> */}
                           {/* {tournamentButtonController(tournamentObj)} */}
                           {/* </div> */}
                        </div>
                     </div>
                  </div>


                  <div className={Classnames(Styles.FooterRow)}>
                     <div className={Classnames(Styles.FooterCol1)}>
                        <div className={Classnames(Styles.WinnerWrapper)}>
                           {tournamentObj?.winnerTeam?.name ?
                              <div className={Classnames(Styles.Winner)}>
                                 <img className={Classnames(Styles.WinnerBack)} src={Winner}/>
                                 <img className={Classnames(Styles.WinnerTeamLogo)} src={tournamentObj?.winnerTeam?.teamPictureURL}/>
                                 <span>{tournamentObj?.winnerTeam?.name ? tournamentObj.winnerTeam.name : '--'}</span>
                              </div>
                              : <></>
                           }
                        </div>
                     </div>
                     <div className={Classnames(Styles.FooterCol2)}>
                        <div>
                           <div className={Classnames(Styles.UserInforLabel)}>
                              {tournamentObj?.tournamentUserCount == 0 ?
                                 <></>
                                 : <img alt={""} src={UserIcon}/>}
                              &nbsp;{tournamentObj.tournamentUserCount != null ? tournamentObj.tournamentUserCount != 0 ? tournamentObj.tournamentUserCount : '' : 'N/A'} {tournamentObj?.tournamentUserCount > 1 ? t("ONGOING_TOURNAMENTS.USERS_PLAYED") : tournamentObj?.tournamentUserCount == 1 ? t("ONGOING_TOURNAMENTS.USER_PLAYED") : ""}
                           </div>
                        </div>
                        <div className={Classnames(Styles.buttonRow, 'mt-0')}>
                           {tournamentButtonController(tournamentObj)}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      )

      const getTournamentRegistrationStatus = async (tournamentId, userName) => {
         await TournamentService.checkTournamentRegistration(tournamentId, userName).then((response) => {
            return !!(response?.apiStatus && response?.available);
         })
      }

      function compareDates(start, end) {
         const now = moment();
         const startDate = moment(start);
         const endDate = moment(end);

         if (now.isBefore(startDate)) {
            return <label className={Classnames(Styles.upcomingTag)}>{t("ONGOING_TOURNAMENTS.UPCOMING")}</label>
         } else if (now.isBetween(startDate, endDate)) {
            return <label className={Classnames(Styles.liveTag)}><span></span>{t("ONGOING_TOURNAMENTS.LIVE")}</label>;
         } else if (now.isAfter(endDate)) {
            return <label className={Classnames(Styles.completedTag)}><span></span>{t("ONGOING_TOURNAMENTS.COMPLETED")}</label>;
         }
      }

      function closeModal() {
         setIsPurchaseModalOpen(false);
         toast.dismiss();
      }

      const showPurchaseModal = async (tournament, e) => {
         sessionStorage.setItem("SelectedOption", "Matches");
         if (e) {
            e.stopPropagation();
         }

         if (!tournament?.geoBlockedForUser) {
            const toastId = toast.loading(t("ONGOING_TOURNAMENTS.CHECKING_YOUR_REGISTRATION_STATUS"));
            setIsRegistrationLoading(true);
            const registered = await checkTournamentRegistration(tournament.id, props.userName);

            if (registered) {
               toast.update(toastId, {
                  render: `${t("ONGOING_TOURNAMENTS.REGISTRATION_DONE_REDIRECTING_TO_THE_TOURNAMENT")}`,
                  type: toast.TYPE.SUCCESS,
                  autoClose: 5000,
                  closeButton: null,
                  isLoading: false
               })

               setTimeout(() => {
                  if (tournament?.tournamentType === TOURNAMENT_TYPES.LEAGUE) {
                     window.location.href = `/league/${tournament?.id}?returnUrl=${encodeURIComponent(location.pathname)}`;
                  } else if (tournament?.tournamentType === TOURNAMENT_TYPES.KNOCKOUT) {
                     window.location.href = `/knockout/${tournament?.id}?returnUrl=${encodeURIComponent(location.pathname)}`;
                  } else if (tournament?.tournamentType === TOURNAMENT_TYPES.LEAGUE_KNOCKOUT) {
                     window.location.href = `/league-knockout/${tournament?.id}?returnUrl=${encodeURIComponent(location.pathname)}`;
                  }
               }, 1000);
            } else if (tournament.active === false && tournament.toStart === false) {

               toast.update(toastId, {
                  render: `${t("ONGOING_TOURNAMENTS.TOURNAMENT_IS_COMPLETED_CANT_ENTER_THE_TOURNAMENT_NOW")}`,
                  type: toast.TYPE.ERROR,
                  autoClose: 5000,
                  closeButton: null,
                  isLoading: false
               })

               setIsRegistrationLoading(false);
            } else {
               toast.update(toastId, {
                  render: `${t("ONGOING_TOURNAMENTS.PLEASE_ENROLL_TO_THE_TOURNAMENT_BEFORE_ENTERING")}`,
                  type: toast.TYPE.INFO,
                  autoClose: 5000,
                  closeButton: null,
                  isLoading: false
               })

               setTournamentDetails(tournament)
               setIsPurchaseModalOpen(true);
               setIsRegistrationLoading(false);
            }
         } else {
            toast.error(AUTHENTICATION_TOASTS.TOURNAMENT_IS_BLOCKED)
         }
      }

      const loadingIndicator = (
         <div className="w-100 text-center">
            <div className="lds-facebook">
               <div></div>
               <div></div>
               <div></div>
            </div>
         </div>
      );

      const pageloadingIndicator = (
         <div className="w-100 text-center">
            <div className={Styles.loadingContainer}>
               <div className="lds-facebook">
                  <div></div>
                  <div></div>
                  <div></div>
               </div>
            </div>
         </div>);

      const handleQRModalClose = () => {
         setIsQRModalOpen(false);
      }

      return (
         <React.Fragment>
            {(connectPubnub && pubNubToken != null) ?
               <OngoingTournamentsHandler onRecieved={messageFromTournamentHandlerCallback} pubNubToken={pubNubToken} subscribe={subscribeChannel}
                                          onUnsubscribe={unSubscribeChannel} selectedRadioBtnValue={selectedRadioBtnValue} /> : <></>}

            <div className={Classnames(Styles.Radial1)}></div>
            <div className={Classnames(Styles.Radial2)}></div>
            <div className={Classnames(Styles.tournamentsPage)}>
               <div className={Classnames(Styles.bodyTournaments)}>
                  <div className={Classnames(Styles.ResponceCont, "col-12", "row", "pb-2", "ps-2", "pe-2", "ps-xl-0", "pe-xl-0")}>
                     <div className="col-12 p-0 row">
                        <NavbarSecondary page="events"/>
                     </div>
                     {!translationsLoaded ? pageloadingIndicator :
                        <div className={Classnames(Styles.pageWrapper, "col-12 p-0")}>
                           <div className="p-0 row">
                              <div className="col-12 col-xl-5  p-0">
                                 {/* <h1>Ongoing Tournaments</h1> */}
                                 <h1>{t("ONGOING_TOURNAMENTS.TOURNAMENTS")}</h1>
                              </div>
                              <div className="col-12  pb-2 p-xl-0 mb-3">
                                 <div className={Classnames(Styles.matchTypeWrapper, "fc-wrapper matches-radio-wrapper row mb-4 mb-sm-0")}>

                                    <label className="radioButtonlbl order-sm-2 order-2 m-0 pe-2 col-md-auto col-6 p-0 mb-2 mb-sm-0">
                                       <input type="radio" name="radio" value='all' checked={selectedRadioBtnValue === MATCH_CARD_TYPES.ALL} onChange={handleRadioBtnValue}/>
                                       <span>{t("ONGOING_TOURNAMENTS.PLAY_NOW")}</span>
                                    </label>
                                    <label className="radioButtonlbl order-sm-2 order-2 m-0 pe-0 pe-sm-2 col-md-auto col-6 p-0 mb-2 mb-sm-0">
                                       <input type="radio" name="radio" value='live' checked={selectedRadioBtnValue === MATCH_CARD_TYPES.LIVE} onChange={handleRadioBtnValue}/>
                                       <span>{t("ONGOING_TOURNAMENTS.LIVE")}</span>
                                    </label>
                                    <label className="radioButtonlbl order-sm-2 order-2 m-0 pe-2 col-md-auto col-6 p-0 mb-2 mb-sm-0">
                                       <input type="radio" name="radio" value='upcoming' checked={selectedRadioBtnValue === MATCH_CARD_TYPES.UPCOMING} onChange={handleRadioBtnValue}/>
                                       <span>{t("ONGOING_TOURNAMENTS.UPCOMING")}</span>
                                    </label>
                                    <label className="radioButtonlbl order-sm-2 order-2 m-0 pe-0 pe-sm-2 col-md-auto col-6 p-0 mb-2 mb-sm-0">
                                       <input type="radio" name="radio" value='completed' checked={selectedRadioBtnValue === MATCH_CARD_TYPES.COMPLETED} onChange={handleRadioBtnValue}/>
                                       <span>{t("ONGOING_TOURNAMENTS.COMPLETED")}</span>
                                    </label>
                                    {props.isAuthenticated ?
                                       <label className="radioButtonlbl order-sm-2 order-2 m-0 col-md-auto col-6 p-0 mb-2 mb-sm-0">
                                          <input type="radio" name="radio" value='user_purchased' checked={selectedRadioBtnValue === MATCH_CARD_TYPES.PURCHASED} onChange={handleRadioBtnValue}/>
                                          <span>{t("ONGOING_TOURNAMENTS.PURCHASED")}</span>
                                       </label> : <></>}
                                    <div className={Classnames(Styles.SearchWrapper)}>
                                       <Form.Control style={{height: '54px'}} placeholder={t("ONGOING_TOURNAMENTS.SEARCH")} aria-label="SearchForArtworks"
                                                     aria-describedby="basic-addon1" value={searchQuery} onChange={handleSearchChange}/>
                                       <InputGroup.Text style={{height: '54px'}} id="basic-addon1">
                                          <FaSearch/>
                                       </InputGroup.Text>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div className={Classnames(Styles.contnetWrapper)}>
                              <div className="row">
                                 <div className="col-xl-9 col-lg-12 p-0 float-left pe-lg-0">
                                    {/* Daily MatchesWrapper */}

                                    {loadingInProgress ? loadingIndicator : <>
                                       {/*{currentPage == 0 && dailyTournamentCard()}*/}
                                       {renderAllTournaments()}
                                    </>}

                                    {totalElements > 0 && !loadingInProgress ?
                                       <div className="pageWrap" style={{marginTop: "45px"}}>
                                          <Paginator postsPerPage={postsPerPage} totalElements={totalElements} currentPage={currentPage} paginate={paginate}/>
                                       </div> : <></>}
                                 </div>
                                 <div className="col-xl-3 p-0 d-none d-xl-block text-end">
                                    <img src="https://storage.googleapis.com/meta11/serviceplatform/StaticBanner4.webp"/>
                                 </div>
                              </div>
                           </div>
                        </div>
                     }
                     <Footer/>
                  </div>
               </div>
            </div>

            {isPurchaseModalOpen ?
               <PaymentConfirmModal handleClose={closeModal} tournamentDetails={tournamentDetails}/> : <></>
            }

            {isQRModalOpen ?
               <div className={Classnames("modal fade show", styles.modalBlur, styles.PaymentConfirmmodal)} id="exampleModalCenter"
                    tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{display: "block"}}>
                  <div className={Classnames(styles.userProfileModalWrapper, "modal-dialog modal-dialog-centered")} role="document">
                     <div className={Classnames("modal-content", "p-4", styles.modalContentContainer)}>
                        <button className={Classnames(styles.closeButton, "close")} style={{position: "absolute", right: "15px", top: "10px", zIndex: "2"}} type="button" onClick={handleQRModalClose} data-dismiss="modal" aria-label="Close">
                   <span className={styles.closeButton} aria-hidden="true">
                      <img alt='Close Icon' src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                   </span>
                        </button>
                        <div className={Classnames(styles.UserProfileModalWrapper, styles.QRModal)}>
                           <div className={Classnames(styles.circle1)}></div>
                           <div className={Classnames(styles.circle2)}></div>
                           <div className={Classnames(styles.circle3)}></div>
                           <div className="row p-0">
                              <div className={Classnames(styles.UserProfileModal, "col-md-12")}>
                                 <h4 className={Classnames(styles.QRModalTitle)}>{t("ONGOING_TOURNAMENTS.NEED_UPDATES")} <br/>{t("ONGOING_TOURNAMENTS.SCAN_THE_QR")}</h4>
                                 <img src={selectedQR}/>
                                 {/* <img src={QRIcon2ForModal}/> */}
                              </div>
                           </div>
                           <div className={Classnames(styles.photoModifyModelBtnRow, "p-3")}>
                              <button onClick={handleQRModalClose} type="button" className={styles.LoginButton}>{t("ONGOING_TOURNAMENTS.DONE")}</button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div> : <></>
            }
         </React.Fragment>
      );
   }
;

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken != null || undefined,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      email: state.auth.email,
      Token: state.auth.accessToken,
      RefreshToken: state.auth.refreshToken,
      timeOut: state.auth.sessionTimeout,
   };
};
export default connect(mapStateToProps)(OngoingTournaments);
