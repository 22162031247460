import React, {useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import ProgressBar from "./ProgressBar";
import classNames from "classnames";
import styles from "./BallByBallCard.module.scss";
import {getConf} from "../../services/ConfigService";
import {MATCH_PAGE} from "../../utilities/ConfigConstants";


const GreetCard = ({cardData, onAnswerSelected, lang}) => {

   const [selectedAnswer, setSelectedAnswer] = useState(null);
   const [disable, setDisable] = useState(false);

   const handleOnInput = (answer) => {
      setSelectedAnswer(answer);
      if (!disable) {
         const answerData = {
            "questionId": cardData.questionId,
            "answerId": answer.answer_id,
            "answer": answer.answer,
            "time": 0,
            "questionText": cardData?.questionText,
            "messageType": cardData?.messageBodyType,
            "maxMillsEnc": cardData?.maxMillsEnc,
            "sendAnswer": answer,
            "questionRun": cardData?.questionRun
         }
         onAnswerSelected(answerData);
         setDisable(true)
      }
   }

   return (
      <Card style={{width: '100%'}}>
         <Card.Body className={"bot_card_body"}>
            {/*<Card.Title>Card Title</Card.Title>*/}
            {/*<Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle>*/}
            <Card.Text>
               {cardData?.greetingQuestionList?.find(question => question.language_code == lang)?.question_txt}
            </Card.Text>
            <Card.Text>
               {cardData?.greetingQuestionList?.find(question => question.language_code == lang)?.answers?.filter(question => question.language_code == lang).map(choice => (
                  <>
                     <input disabled={disable} type={"radio"} id={choice?.answer_id} checked={selectedAnswer?.answer_id === choice?.answer_id} value={choice?.answer} name={"answer"}/>
                     <label htmlFor={choice?.answer_id} className={selectedAnswer?.answer_id == choice?.answer_id ? "selected-input qbotLabel" : "deselected-input qbotLabel"} onClick={disable ? null : () => handleOnInput(choice)}>{choice?.answer}</label>
                  </>
               ))}
            </Card.Text>
         </Card.Body>
      </Card>
   )
}

const QbotDisableGreetCard = () => {
   return (
      <Card style={{width: '100%'}}>
         <Card.Body className={"bot_card_body"}>
            {/*<Card.Title>Card Title</Card.Title>*/}
            {/*<Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle>*/}
            <Card.Text>
               🎉 Exciting News! 🎉
            </Card.Text>
            <Card.Text>
               We're thrilled to announce that QBOT is getting a major upgrade! 🚀
            </Card.Text>
         </Card.Body>
      </Card>
   )
}
const QuestionCard = ({cardData, onAnswerSelected, disableBack, lang, summaryMessages}) => {
   const [selectedAnswer, setSelectedAnswer] = useState(null);
   const [sendAnswer, setSendAnswer] = useState(null);
   const [disable, setDisable] = useState(false);

   const mapData = (feedbacks) => {
      const mappedObject = {};

      feedbacks?.forEach((item) => {
         const {fId, language_code, feedback_txt} = item;

         // Define key names based on fId
         let keyName = '';

         switch (fId) {
            case 10:
               keyName = 'OverSummary';
               break;
            case 11:
               keyName = 'CorrectAnswers';
               break;
            case 12:
               keyName = 'StreakBonus';
               break;
            case 13:
               keyName = 'RowBonus';
               break;
            case 14:
               keyName = 'TPoints';
               break;
            case 15:
               keyName = 'CheckPoints';
               break;
            case 16:
               keyName = 'AllCorrectBonus';
               break;
            case 17:
               keyName = 'MatchSummary';
               break;
            case 18:
               keyName = 'WinnerPrediction';
               break;
            case 19:
               keyName = 'PointsWord';
               break;
            default:
               keyName = `DefaultKey_${fId}`;
         }

         // Use keyName as the key
         if (!mappedObject[keyName]) {
            mappedObject[keyName] = {en: [], ta: [], hi: []};
         }

         mappedObject[keyName][language_code].push({language_code, feedback_txt});
      });

      return (mappedObject);
   };

   const onDisable = (disable) => {
      setDisable(disable)
   }

   const handleOnInput = (answer) => {
      setSelectedAnswer(answer);
      let answerWithOver = {
         ...answer,
         "overId": cardData?.overNo,
         "inning": cardData?.inning
      }
      if (!disable) {
         const answerData = {
            "questionId": cardData.questionId,
            "answerId": answer.answerId,
            "answer": answer.answer,
            "time": 0,
            "questionText": cardData?.questionText,
            "messageType": cardData?.messageBodyType,
            "maxMillsEnc": cardData?.maxMillsEnc,
            "sendAnswer": answerWithOver,
            "questionRun": cardData?.questionRun
         }
         setSendAnswer(answerData);

      } else {
         const nullAnswerData = {
            "questionId": cardData.questionId,
            "answerId": null,
            "answer": null,
            "time": 0,
            "questionText": cardData?.questionText,
            "messageType": cardData?.messageBodyType,
            "maxMillsEnc": cardData?.maxMillsEnc,
            "sendAnswer": null,
            "questionRun": cardData?.questionRun
         }
         setSendAnswer(nullAnswerData);
      }
   }

   useEffect(() => {
      const submit = async () => {
         const nullAnswerData = {
            "questionId": cardData.questionId,
            "answerId": null,
            "answer": null,
            "time": 0,
            "questionText": cardData?.questionText,
            "messageType": cardData?.messageBodyType,
            "maxMillsEnc": cardData?.maxMillsEnc,
            "sendAnswer": null,
            "questionRun": cardData?.questionRun
         }
         if (sendAnswer == null) {
            onAnswerSelected(nullAnswerData);
         } else {
            onAnswerSelected(sendAnswer);
         }

      }
      if (disable) {
         submit();
         if (cardData?.questionId != null) {
            disableBack(true)
         }
      }

   }, [disable])

   return (
      <Card style={{width: '100%'}} >
         <Card.Body className={"bot_card_body"} >
            {/*<Card.Title>Card Title</Card.Title>*/}
            <Card.Subtitle className="mb-2 text-muted-">Question {cardData?.QuestionNo}/{cardData?.totQuestions}</Card.Subtitle>
            <Card.Text>
               {cardData?.questionList?.find(question => question?.languageCode == lang)?.questionText}
            </Card.Text>
            <Card.Text>
               {cardData?.answers?.filter(answer => answer?.languageCode == lang).map(choice => (
                  < div key={choice?.answerId}>
                     <input disabled={disable} type={"radio"} id={choice?.answerId} checked={selectedAnswer?.answerId === choice?.answerId} value={choice?.answer} name={"answer"} style={disable ? { cursor: 'not-allowed', opacity: '0.3' } : {}}/>
                     <label htmlFor={choice?.answerId}
                            className={selectedAnswer?.answerId == choice?.answerId ? "selected-input qbotLabel" : "deselected-input qbotLabel"}
                            onClick={disable ? null : () => handleOnInput(choice)}
                            style={disable ? { cursor: 'not-allowed', opacity: '0.3' } : {}}
                     >{choice?.answer}</label>
                  </div>
               ))}
            </Card.Text>
            <Card.Text>
               {mapData(summaryMessages)?.PointsWord[lang][0]?.feedback_txt}: {cardData?.points}
            </Card.Text>
            {!disable && (cardData?.ttl != 0 || cardData?.ttl !== null) ?
               <ProgressBar interval={cardData?.ttl} disable={onDisable}/> : <></>}
         </Card.Body>
      </Card>
   )
}
const OverSummaryCard = ({cardData, lang, summaryMessages}) => {


   const mapData = (feedbacks) => {
      const mappedObject = {};

      feedbacks?.forEach((item) => {
         const {fId, language_code, feedback_txt} = item;

         // Define key names based on fId
         let keyName = '';

         switch (fId) {
            case 10:
               keyName = 'OverSummary';
               break;
            case 11:
               keyName = 'CorrectAnswers';
               break;
            case 12:
               keyName = 'StreakBonus';
               break;
            case 13:
               keyName = 'RowBonus';
               break;
            case 14:
               keyName = 'TPoints';
               break;
            case 15:
               keyName = 'CheckPoints';
               break;
            case 16:
               keyName = 'AllCorrectBonus';
               break;
            case 17:
               keyName = 'MatchSummary';
               break;
            case 18:
               keyName = 'WinnerPrediction';
               break;
            case 19:
               keyName = 'PointsWord';
               break;
            case 22:
               keyName = 'QuestionAnsweredCount';
               break;
            case 23:
               keyName = 'CorrectAnswersCount';
               break;
            case 24:
               keyName = 'HistoryPoints';
               break;
            default:
               keyName = `DefaultKey_${fId}`;
         }

         // Use keyName as the key
         if (!mappedObject[keyName]) {
            mappedObject[keyName] = {en: [], ta: [], hi: []};
         }

         mappedObject[keyName][language_code].push({language_code, feedback_txt});
      });

      return (mappedObject);
   };

   return (
      <Card style={{width: '100%'}}>
         <Card.Body className={"bot_card_body"}>
            <div className={classNames(styles.ballSecondBody, "d-flex mt-4")}
                 style={{justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
               <h7 className={classNames(styles.summarytxtlabel, "heading4 color-white bold d-flex capitalize green-glow")}
                   style={{alignItems: "center"}}>{mapData(summaryMessages)?.OverSummary[lang][0]?.feedback_txt}</h7>
               <h7 className="color-textturquiose heading5 bold green-glow">{cardData?.overSummary?.totalPoints} pts</h7>
               <div className={classNames(styles.borderBottomNion, "d-flex mb-2")}></div>
               <div className={classNames(styles.boardWrapper)}>
                   <span className="d-flex mt-1 heading6 semi-bold">
                      <label className="color-white upper-case mb-0 pe-2">{mapData(summaryMessages)?.QuestionAnsweredCount[lang][0]?.feedback_txt}:</label>
                      <label className="body-text4 color-textturquiose">{cardData?.overSummary?.totalAnsweredCount}</label>
                   </span>
                  <span className="d-flex mt-1 heading6 semi-bold">
                     <label className="color-white upper-case mb-0 pe-2">{mapData(summaryMessages)?.CorrectAnswersCount[lang][0]?.feedback_txt}:</label>
                     <label className="body-text4 color-textturquiose">{cardData?.overSummary?.correctAnsweredCount}</label>
                  </span>
                  <div className={classNames(styles.borderBottomNion, "d-flex mb-2 mt-2")}></div>
                  <span className="d-flex mt-1 heading6 semi-bold">
                     <label className="color-white upper-case mb-0 pe-2">{mapData(summaryMessages)?.TPoints[lang][0]?.feedback_txt}:</label>
                     <label className="body-text4 color-textturquiose">{cardData?.overSummary?.totalPoints} pts</label>
                  </span>
               </div>
            </div>
         </Card.Body>
      </Card>
   )
}

const StatisticsCard = ({cardData, lang, summaryMessages}) => {

   const mapData = (feedbacks) => {
      const mappedObject = {};

      feedbacks?.forEach((item) => {
         const {fId, language_code, feedback_txt} = item;

         // Define key names based on fId
         let keyName = '';

         switch (fId) {
            case 10:
               keyName = 'OverSummary';
               break;
            case 11:
               keyName = 'CorrectAnswers';
               break;
            case 12:
               keyName = 'StreakBonus';
               break;
            case 13:
               keyName = 'RowBonus';
               break;
            case 14:
               keyName = 'TPoints';
               break;
            case 15:
               keyName = 'CheckPoints';
               break;
            case 16:
               keyName = 'AllCorrectBonus';
               break;
            case 17:
               keyName = 'MatchSummary';
               break;
            case 18:
               keyName = 'WinnerPrediction';
               break;
            case 19:
               keyName = 'PointsWord';
               break;
            case 22:
               keyName = 'QuestionAnsweredCount';
               break;
            case 23:
               keyName = 'CorrectAnswersCount';
               break;
            case 24:
               keyName = 'HistoryPoints';
               break;
            case 25:
               keyName = 'NearestRunPoints';
               break;
            default:
               keyName = `DefaultKey_${fId}`;
         }

         // Use keyName as the key
         if (!mappedObject[keyName]) {
            mappedObject[keyName] = {en: [], ta: [], hi: []};
         }

         mappedObject[keyName][language_code].push({language_code, feedback_txt});
      });

      return (mappedObject);
   };

   return (
      <Card style={{width: '100%'}}>
         <Card.Body className={"bot_card_body"}>
            <div className={classNames(styles.ballSecondBody, "d-flex mt-4")}
                 style={{justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
               <h7 className={classNames(styles.summarytxtlabel, "heading4 color-white bold d-flex capitalize green-glow")}
                   style={{alignItems: "center"}}>{mapData(summaryMessages)?.MatchSummary[lang][0]?.feedback_txt}</h7>
               <h7 className="color-textturquiose heading5 bold green-glow">{cardData?.overSummary?.totalPoints} pts
               </h7>
               <div className={classNames(styles.borderBottomNion, "d-flex mb-2")}></div>
               <div className={classNames(styles.boardWrapper)}>
             <span className="d-flex mt-1 heading6 semi-bold">
                <label className="color-white upper-case mb-0 pe-2">{mapData(summaryMessages)?.WinnerPrediction[lang][0]?.feedback_txt}:</label>
                <label className="body-text4 color-textturquiose">{cardData?.overSummary?.winnerPredictionPoints} pts</label>
             </span>
                  <span className="d-flex mt-1 heading6 semi-bold">
                     <label className="color-white upper-case mb-0 pe-2">{mapData(summaryMessages)?.CorrectAnswers[lang][0]?.feedback_txt}:</label>
                     <label className="body-text4 color-textturquiose">{cardData?.overSummary?.predictionPoints} pts</label>
                  </span>
                  <span className="d-flex mt-1 heading6 semi-bold">
                     <label className="color-white upper-case mb-0 pe-2">{mapData(summaryMessages)?.StreakBonus[lang][0]?.feedback_txt}</label>
                     <label className="body-text4 color-textturquiose">{cardData?.overSummary?.streakBonusPoints} pts</label>
                  </span>
                  <span className="d-flex mt-1 heading6 semi-bold"><label
                     className="color-white upper-case mb-0 pe-2">{mapData(summaryMessages)?.RowBonus[lang][0]?.feedback_txt}</label>
                     <label className="body-text4 color-textturquiose">{cardData?.overSummary?.consecutive3Points} pts</label>
                  </span>
                  <span className="d-flex mt-1 heading6 semi-bold">
                     <label className="color-white upper-case mb-0 pe-2">{mapData(summaryMessages)?.AllCorrectBonus[lang][0]?.feedback_txt}:</label>
                     <label className="body-text4 color-textturquiose">{cardData?.overSummary?.sixPredictionBonusPoints} pts</label>
                  </span>
                  <span className="d-flex mt-1 heading6 semi-bold">
                     <label className="color-white upper-case mb-0 pe-2">{mapData(summaryMessages)?.NearestRunPoints[lang][0]?.feedback_txt}:</label>
                     <label className="body-text4 color-textturquiose">{cardData?.overSummary?.nearestRunsPoints} pts</label>
                  </span>
                  <div className={classNames(styles.borderBottomNion, "d-flex mb-2 mt-2")}></div>
                  <span className="d-flex mt-1 heading6 semi-bold">
                     <label className="color-white upper-case mb-0 pe-2">{mapData(summaryMessages)?.TPoints[lang][0]?.feedback_txt}</label>
                     <label className="body-text4 color-textturquiose">{cardData?.overSummary?.totalPoints} pts</label>
                  </span>
               </div>
            </div>
         </Card.Body>
      </Card>
   )
}

const ResolveCard = ({cardData, lang}) => {

   const [disable, setDisable] = useState(true);

   return (
      <Card style={{width: '100%'}}>
         <Card.Body className={"bot_card_body"}>
            {/*<Card.Title>Card Title</Card.Title>*/}
            <Card.Subtitle
               className="mb-2">{cardData?.resolveFeedbackMessage?.find(message => message?.language_code == lang)?.feedback_txt}</Card.Subtitle>
            <Card.Text>
               {cardData?.questionTxt}
            </Card.Text>
            <Card.Text>

               <input disabled={disable} type={"radio"} id={cardData?.userAnswer?.answerId} checked={true}
                      value={cardData?.userAnswer?.answer} name={"answer"}/>
               <label htmlFor={cardData?.userAnswer?.answerId}
                      className={cardData?.result ? "selected-input qbotLabel" : "deselected-resolve-input qbotLabel"}
                      onClick={null}>{cardData?.userAnswer?.answer}</label>


            </Card.Text>
            <Card.Text>
               {cardData?.resultText?.find(message => message?.language_code == lang)?.feedback_txt}
            </Card.Text>
            <Card.Text>
               Points : {cardData?.points}
            </Card.Text>
         </Card.Body>
      </Card>
   )
}

const CardComponent = ({data, onSubmit, disableCallback, lang, summaryMessages}) => {

   const handleAnswerSelected = (selectedAnswer) => {

      onSubmit(selectedAnswer?.questionId, selectedAnswer?.answerId, selectedAnswer?.answer, selectedAnswer?.time, selectedAnswer?.questionText, selectedAnswer?.messageType, selectedAnswer?.maxMillsEnc, selectedAnswer?.sendAnswer, selectedAnswer?.questionRun);

   };

   switch (data?.messageBodyType) {
      case "GREETING":
         if (getConf(MATCH_PAGE.DISABLE_QBOT)) {
            return <QbotDisableGreetCard/>;
         }else {
            return <GreetCard cardData={data} onAnswerSelected={handleAnswerSelected} lang={lang}/>;
         }
      case "QUESTION":
         return <QuestionCard cardData={data} onAnswerSelected={handleAnswerSelected} disableBack={disableCallback}
                              lang={lang} summaryMessages={summaryMessages}/>;
      case "RESULT_CARD":
         return <StatisticsCard cardData={data} lang={lang} summaryMessages={summaryMessages}/>;
      case "OVER_SUMMARY":
         return <OverSummaryCard cardData={data} lang={lang} summaryMessages={summaryMessages}/>;
      case "RESOLVE":
         return <ResolveCard cardData={data} lang={lang}/>;
   }
}

export default CardComponent;
