import * as actionTypes from "./actionTypes";
import {encryptData} from "../../helpers/SecureLocalStorage";

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START,
    };
};

export const authSuccess = (accessToken, refreshToken, oAuthProviderType, imageURL, userName, name, email) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        accessToken: accessToken,
        refreshToken: refreshToken,
        oAuthProviderType: oAuthProviderType,
        imageURL: imageURL,
        userName: userName,
        name: name,
        email: email
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error,
    };
};

export const auth = (accessToken, refreshToken, oAuthProviderType, imageURL, userName, name, email) => {
    return (dispatch) => {
        try {
            dispatch(authStart());
            localStorage.setItem('accessToken', encryptData(accessToken))
            localStorage.setItem("refreshToken", encryptData(refreshToken))
            localStorage.setItem("oAuthProviderType", encryptData(oAuthProviderType))
            localStorage.setItem("imageURL", encryptData(imageURL))
            localStorage.setItem("userName", encryptData(userName))
            localStorage.setItem("name", encryptData(name))
            localStorage.setItem("email", encryptData(email))
            dispatch(authSuccess(accessToken, refreshToken, oAuthProviderType, imageURL, userName, name, email));

        } catch (error) {
            dispatch(authFail(error));
        }
    };
};
export const sessionRefresh = (accessToken, refreshToken) => {
    return {
        type: actionTypes.REFRESH_SESSION,
        accessToken: accessToken,
        refreshToken: refreshToken
    }
}
export const refreshSession = (accessToken, refreshToken) => {
    return (dispatch) => {
        try {
            localStorage.setItem('accessToken', encryptData(accessToken))
            localStorage.setItem("refreshToken", encryptData(refreshToken))
            dispatch(sessionRefresh(accessToken, refreshToken));

        } catch (error) {
            dispatch(authFail(error));
        }
    }


}
export const updateProfile = (imageURL) => {

    return {
        type: actionTypes.UPDATE_PROFILE,
        imageURL: imageURL,
    }

}
export const updateName = (name) => {

    return {
        type: actionTypes.UPDATE_NAME,
        name: name,
    }

}
export const updateUserProfile = (imageURL) => {
    return (dispatch) => {
        try {
            localStorage.setItem("imageURL", encryptData(imageURL));
            dispatch(updateProfile(imageURL));
        } catch (error) {
            console.log(error)
        }
    };
};
export const updateProfileName = (name) => {
    return (dispatch) => {
        try {
            localStorage.setItem("name", encryptData(name));
            dispatch(updateName(name));
        } catch (error) {
            console.log(error)
        }
    };
};
export const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("oAuthProviderType");
    localStorage.removeItem("imageURL");
    localStorage.removeItem("userName");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("socketAuth");
    localStorage.removeItem("pubnubQuizToken");
    localStorage.removeItem("pubnubMatchToken");
    localStorage.removeItem("pubnubLeaderboardToken");
    localStorage.removeItem("pubnubLiveToken");
    localStorage.removeItem("previouslyLogged");
    return {
        type: actionTypes.AUTH_LOGOUT,
    };
};
export const sessionTimeout = () => {
    console.log("timed out")
    return {
        type: actionTypes.AUTH_LOGOUT,
    };
}
