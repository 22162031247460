import React, {useEffect, useState} from 'react';
import Classnames from "classnames";
import Styles from "./OmniCreditsNavBar.module.scss";
import CreditIcon from "../../assets/images/icons/24_sprts_token.webp";
import OmniPoints from "../../assets/images/icons/24_xp.webp";
import * as WalletService from "../../services/WalletService";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {AUTHTOKEN_ERRORS, TRANSACTION_STATUS} from "../../utilities/Constants";
import CreditPackagesModal from "../Wallet/CreditPackagesModal/CreditPackagesModal";
import SequenceLoginModal from "../Wallet/SequenceLoginModal/SequenceLoginModal";
import CreditPurchaseStatusModal from "../Wallet/CreditPurchaseStatusModal/CreditPurchaseStatusModal";
import {useTranslation} from "react-i18next";
import * as PubNubService from "../../services/PubNubService";
import UserDataChannelHandler from "../../services/PubNubHandlers/UserDataChannelHandler";
import {toast} from "react-toastify";

const OmniCreditsNavBar = (props) => {
   const [walletDetails, setWalletDetails] = useState([]);
   const [packageId, setPackageId] = useState(0);
   const [purchaseMaticAmount, setPurchaseMaticAmount] = useState(0);
   const [inputOmniValue, setInputOmniValue] = useState(0);
   const [isCreditPurchaseModalOpen, setIsCreditPurchaseModalOpen] = useState(false);
   const [isSequenceLoginModalOpen, setIsSequenceLoginModalOpen] = useState(false);
   const [isTransactionStatusModalOpen, setIsTransactionModalOpen] = useState(false);
   const [transactionHash, setTransactionHash] = useState(0);
   const [transactionAmount, setTransactionAmount] = useState(0);
   const [transactionStatus, setTransactionStatus] = useState(null);
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);
   let uuid = localStorage.getItem("uuid");
   const [subscribeChannel, setSubscribeChannel] = useState(null);
   const [unSubscribeChannel, setUnsubscribeChannel] = useState(null);
   const [pubNubToken, setPubNubToken] = useState(null);
   const [connectPubnub, setConnectPubnub] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   useEffect(() => {
      const fetchData = () => {
         if (props.token && props.userName) {
            WalletService.getOmniCreditsByUsername(props.token, props.userName)
               .then(res => {
                  if (res?.data?.apiStatus) {
                     setWalletDetails(res.data);
                  } else if (res?.status === 400 && res?.data?.rejectMessages[0] === AUTHTOKEN_ERRORS.AUTHENTICATION_TOKEN_HAS_EXPIRED) {
                     // props.onLogout();
                  }
               });
         }
      };

      fetchData();

   }, [props.token, props.userName, props.onLogout]);

   useEffect(() => {

      const fetchData = async () => {
         try {
            const response = await PubNubService.requestAccessTokenQuiz(props.userName, 1234, 1234, props.token).then(response => {
               if (response != null) {
                  console.log("user token ==========", response)
                  setPubNubToken(response);
                  setConnectPubnub(true);
                  localStorage.setItem("pubnubToken", response);
               }
            });

         } catch (error) {
            console.error("Error:", error);
         }

      };
      fetchData();
   }, [uuid]);


   function closeModal() {
      setIsCreditPurchaseModalOpen(false);
      setIsSequenceLoginModalOpen(false);
   }

   const handleTransactionStatus = (transactionStatus, transactionAmount, transactionHash) => {
      setTransactionAmount(transactionAmount);
      setTransactionHash(transactionHash);
      setIsTransactionModalOpen(true);

      if (transactionStatus === TRANSACTION_STATUS.SUCCESS) {
         setTransactionStatus(true);
      } else {
         setTransactionStatus(false);
      }
   }

   const openSequenceLoginModal = (packageId, maticValue, ominiCreditValue) => {
      setPackageId(packageId);
      setPurchaseMaticAmount(maticValue);
      setInputOmniValue(parseFloat(ominiCreditValue))
      setIsSequenceLoginModalOpen(true);
   }

   const handleGoBack = () => {
      setIsSequenceLoginModalOpen(false);
      setIsCreditPurchaseModalOpen(true);
   }

   const handleOpen = () => {
      toast.info(t("TOAST.YOULL_BE_ABLE_TO_WITHDRAW_$SPRTS_TOKENS_TO_YOUR_WALLET_VERY_SOON"));
      // setIsCreditPurchaseModalOpen(true)
      setTimeout(() => {
         window.open("https://birdeye.so/token/44cdhmfE8HrDAE3E1CKTzjR6n5S1CjL72VQj4tx66Kmy/9UknHZZ1WLhvvHFA3bEWUatkaGSchri5fqTKGRqWkfcY?chain=solana");
      }, 2000);
   }

   const closeAllModals = () => {
      setIsCreditPurchaseModalOpen(false);
      setIsSequenceLoginModalOpen(false);
      setIsTransactionModalOpen(false);
   }

   const handelWalletDataRecived = (message) => {
      try {
         console.log("userData recived json message", message);
         setWalletDetails(message);

      } catch (error) {
         console.error("JSON parsing error:", error);
      }
   }


   return (
      <React.Fragment>
         {(connectPubnub && pubNubToken != null && props.isAuthenticated) ?
            <UserDataChannelHandler handelWalletDataRecived={handelWalletDataRecived} pubNubToken={pubNubToken} subscribe={subscribeChannel}
                                    onUnsubscribe={unSubscribeChannel}/> : <></>}
         <div className={Classnames(Styles.OmniCreditsNavBar)} onClick={handleOpen}>
            <ul style={{cursor: 'pointer'}}>
               {/* <li>
            <a href=''>
                <img src={M11Titket} />
                <span>100</span>
            </a>
        </li> */}
               <li className='ddddd'>
                  <div className={Classnames(Styles.omniNavItem)}>
                     <img src={CreditIcon} alt={""}/>
                     <span>{walletDetails.omniCredits ? (walletDetails?.omniCredits).toFixed(3) : 0}</span>
                  </div>
               </li>
               <li>
                  <div>
                     <img src={OmniPoints} alt={""}/>
                     <span>{walletDetails.omniPoints ? (walletDetails.omniPoints) : 0}</span>
                  </div>
               </li>
            </ul>
         </div>
         {isCreditPurchaseModalOpen ?
            <CreditPackagesModal handleClose={closeModal} handleProceedClose={openSequenceLoginModal}/> : <></>}

         {isSequenceLoginModalOpen ?
            <SequenceLoginModal handleClose={closeModal} handleGoBack={handleGoBack} packageId={packageId} amount={purchaseMaticAmount} transactionStatus={handleTransactionStatus} ominiCreditValue={inputOmniValue}/> : <></>}

         {isTransactionStatusModalOpen && transactionStatus != null ?
            <CreditPurchaseStatusModal transactionStatus={transactionStatus} transactionAmount={transactionAmount} transactionHash={transactionHash} closeAllModals={closeAllModals}></CreditPurchaseStatusModal>
            : <></>}
      </React.Fragment>
   )
}

const mapDispatchToProps = (dispatch) => {
   return {
      onLogout: () => dispatch(actions.logout()),
   };
};
const mapStateToProps = (state) => {
   return {
      token: state.auth.accessToken, isAuthenticated: state.auth.accessToken !== null, profileImage: state.auth.imageURL, oAuthProviderType: state.auth.oAuthProviderType, userName: state.auth.userName, name: state.auth.name
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(OmniCreditsNavBar);
