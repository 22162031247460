import React, {useEffect, useState} from "react";
import Styles from "./Login.module.scss"
import Classnames from "classnames"
import * as UserService from "../../services/UserService"
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import Timeout from "../../components/Timeouts/Timeout";


const ResetPasswordSender2 = ({onCloseModal}) => {

    const [email, setEmail] = useState("");
    const [linkSendStatus, setLinkSendStatus] = useState(false);
    const [emailError, setEmailError] = useState("")
    const [emailValid, setEmailValid] = useState(false)
    const [emailWithStars, setEmailWithStars] = useState("")
    const [timerStart, setTimerStart] = useState(false)
    const [showTimer, setShowTimer] = useState(false)

    const closeModal = () => {
        onCloseModal(false);
    }
    const validateFields = (fieldName) => {
        let emailValid;
        switch (fieldName) {
            case 'email':
                emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

                setEmailValid(emailValid)
                if (email === "") {
                    setEmailError("")
                } else {
                    setEmailError(emailValid ? '' : "Invalid Email!");
                }

                break;

            default:
                break;
        }
    }
    useEffect(() => {
        if (email) {
            validateFields("email");
            obscureEmail(email)
        } else {
            setEmailError("")
        }

    }, [email])


    const resetLinkSender = () => {
        const LinkSenderResponse = UserService.forgotPasswordReset(
            {"attribute": email.toLowerCase()}
        );
        setLinkSendStatus(true);
        LinkSenderResponse.then(
            (response) => {
                if (response.apiStatus === true) {
                    setLinkSendStatus(true);
                    setEmailError("")
                } else {
                    setLinkSendStatus(true)
                    setEmailError(response.rejectMessages)
                }
            }
        )
    }
    const obscureEmail = (email) => {
        const [name, domain] = email.split('@');
        setEmailWithStars(`${name[0]}${new Array(name.length).join('*')}@${domain}`);
    };
    const timerResetter = (input) => {
        console.log(input)
        setShowTimer(input)
    }
    return (
        <div className={Classnames("modal fade show", Styles.modalBlur)} id="exampleModalCenter" tabIndex="-1"
             role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{display: "block"}}>
            <div className={Classnames("modal-dialog-centered", Styles.ModalDialog)} role="document">
                <div className="modal-content" className={Classnames(Styles.ModalContent)}>
                    {/*Modal-1 Started*/}
                    <div className={Classnames(Styles.ModalHeader, "modal-header")}>
                        <h5 className="modal-title" id="exampleModalLabel">{/*Modal title*/}</h5>
                        <button onClick={closeModal} type="button"
                                className={Classnames(Styles.CloseIcon, "close")} data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    {!linkSendStatus ? <div className={Classnames(Styles.ModalCont)}>

                        <div className="p-2 p-md-5 row">
                            <div className={Classnames(Styles.ModalTitle, "text-center")}>reset password</div>
                            <div className={Classnames(Styles.ModalSmallText, "pt-3", "text-center")}>Enter the email
                                you
                                signed up with and we will<br/>
                                send you password reset instructions
                            </div>
                            <div className="mt-5">
                                <div className={Classnames(Styles.group)}>
                                    <input type="email" name="name" onChange={(e) => {
                                        setEmail(e.target.value)
                                    }
                                    } required/>
                                    <label>Email address</label>
                                </div>
                            </div>
                            <div className="w-100 text-left error-text mb-3"
                                 style={{"color": '#ff006a', height: "5px"}}>
                                {emailError}
                            </div>
                            <div className="mt-3">
                                <button disabled={!emailValid || linkSendStatus} type="submit"
                                        onClick={resetLinkSender}
                                        className={Classnames(Styles.LoginButton)}>SEND ME A LINK</button>
                            </div>
                        </div>
                    </div> : <div className={Classnames(Styles.ModalCont)}>
                        <div className="p-5 row">
                            <div className={Classnames(Styles.ModalTitle, "text-center")}>reset password</div>
                            <div className={Classnames(Styles.ModalSmallText, "pt-5", "text-center")}>
                                If you haven’t received the email on <font style={{color: '#ffffff'}}>{emailWithStars}</font> after a few minutes, please check
                                your junk mail folder as it may have been mistaken for spam, or try a different email
                                address
                            </div>
                            <div className={Classnames(Styles.ModalSmallText2, "pt-5", "text-center")}>
                                can’t find the code? <font>
                                {showTimer ? <Timeout restartKey={timerStart} resetTimer={timerResetter}/> :
                                    <div>
                                        <font>Can't find the email?&nbsp;</font>
                                        <font onClick={() => {
                                            setShowTimer(true)
                                            setTimerStart(true)
                                            resetLinkSender()
                                        }}>Resend
                                            Email
                                        </font>
                                    </div>
                                }
                            </font><br/>
                                incorrect email? <font onClick={() => {
                                setLinkSendStatus(false)
                            }
                            }>update the email address</font>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>

    );
};
const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (accessToken, refreshToken, oAuthProviderType, imageURL, userName, name, email) => dispatch(actions.auth(accessToken, refreshToken, oAuthProviderType, imageURL, userName, name, email))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordSender2);
