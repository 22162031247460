import {ApiEndpoints} from "./ApiConstants";
import AxiosInstance from "../helpers/AxiosInstance";

export const getAllMatches = (page, size, sort) => {
   const url = `${ApiEndpoints.LIVE_MATCH_STAT}?page=${page}&size=${size}&sort=${sort}`
   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};
export const getAllMatchCards = (searchQuery, currentPage, postsPerPage, sort, selectedRadioBtnValue, pageLang, authToken) => {
   // https://qa.meta11.com/api/statistics/matches/card?attribute=sharks&page=0&size=9&sort=isLive%2CisScheduled%2CmatchId%2Cdesc&type=all
   let url;
   if (searchQuery) {
      url = `${ApiEndpoints.MATCH_STAT_CARDS}?attribute=${searchQuery}&page=${currentPage}&size=${postsPerPage}&sort=${sort}&type=${selectedRadioBtnValue}?lang=${pageLang}`
   } else {
      url = `${ApiEndpoints.MATCH_STAT_CARDS}?page=${currentPage}&size=${postsPerPage}&sort=${sort}&type=${selectedRadioBtnValue}&lang=${pageLang}`
   }
   return AxiosInstance()
      .get(url, {
         headers: {
            "AuthToken": authToken
         }
      })
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};
export const getAllMatchCardsByTournamentId = (searchQuery, currentPage, postsPerPage, sort, selectedRadioBtnValue, tournamentId, lang, authToken) => {
   // https://qa.meta11.com/api/statistics/matches/card?attribute=sharks&page=0&size=9&sort=isLive%2CisScheduled%2CmatchId%2Cdesc&type=all
   let url;
   if (searchQuery && selectedRadioBtnValue) {
      url = `${ApiEndpoints.MATCH_STAT_CARDS}?attribute=${searchQuery}&lang=${lang}&page=${currentPage}&size=${postsPerPage}&sort=${sort}&type=${selectedRadioBtnValue}&tournament=${tournamentId}`

   } else if (selectedRadioBtnValue && !searchQuery) {
      url = `${ApiEndpoints.MATCH_STAT_CARDS}?lang=${lang}&page=${currentPage}&size=${postsPerPage}&sort=${sort}&type=${selectedRadioBtnValue}&tournament=${tournamentId}`

   } else if (!selectedRadioBtnValue) {
      url = `${ApiEndpoints.MATCH_STAT_CARDS}?lang=${lang}&page=${currentPage}&size=${postsPerPage}&sort=${sort}&tournament=${tournamentId}`

   }
   return AxiosInstance()
      .get(url, {
         headers: {
            "AuthToken": authToken
         }
      })
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};
export const getAllMatchCardsByTeamId = (page, size, sort, teamId, type, lang, authToken) => {
   const url = `${ApiEndpoints.MATCH_STAT_CARDS_BY_TEAMID}/${teamId}?lang=${lang}&page=${page}&size=${size}&sort=${sort}&type=${type}`
   return AxiosInstance()
      .get(url, {
         headers: {
            "AuthToken": authToken
         }
      })
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const getAllTeams = () => {
   return AxiosInstance()
      .get(ApiEndpoints.GET_ALL_TEAMS)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const getAllTeamsCards = (search, page, posts) => {
   return AxiosInstance()
      .get(`${ApiEndpoints.GET_ALL_TEAM_CARDS}?attribute=${search}&page=${page}&size=${posts}`)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const getAllPlayers = () => {
   return AxiosInstance()
      .get(ApiEndpoints.PLAYER_ASSET_PROFILE)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};
export const getAllPlayersFromStat = (page, size) => {
   return AxiosInstance()
      .get(`${ApiEndpoints.GET_PLAYERS}?page=${page}&size=${size}`)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const getHomePageMatches = () => {
   return AxiosInstance()
      .get(ApiEndpoints.HOME_MATCH_STAT)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const getMatchStat = (matchId, authToken) => {
   const url = `${ApiEndpoints.LIVE_MATCH_STAT}/${matchId}`
   return AxiosInstance()
      .get(url, {
         headers: {
            "AuthToken": authToken
         }
      })
      .then((response) => {
         return {
            data: response.data,
            status: response.status
         };
      })
      .catch((error) => {
         return error.response;
      });
};

export const getTeamByTeamId = (teamId) => {
   const url = `${ApiEndpoints.GET_TEAM}/${teamId}`
   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};
export const getPlayerProfileByStat = (playerId) => {

   const url = `${ApiEndpoints.PLAYER_PROFILE}/${playerId}`;
   return AxiosInstance().get(url).then((response) => {
      return response.data;
   })
      .catch((error) => {
         return error.response;
      })
}
export const getPlayerMatchHistory = (playerId, size) => {

   const url = `${ApiEndpoints.PLAYER_PROFILE}/${playerId}/history?size=${size}`;
   return AxiosInstance().get(url).then((response) => {
      return response.data;
   })
      .catch((error) => {
         return error.response;
      })
}

export const getPlayerProfile = (playerId) => {
   const url = `${ApiEndpoints.PLAYER_ASSET_PROFILE}/${playerId}`;
   return AxiosInstance().get(url).then((response) => {
      return response.data;
   })
      .catch((error) => {
         return error.response;
      })
}

export const getBattingRanks = (page, size, sort) => {
   // http://localhost:8080/api/statistics/batting/rank?page=0&size=10&sort=battingPoints%2Cdesc
   const url = `${ApiEndpoints.GET_BATTING_RANKINGS}?page=${page}&size=${size}&sort=${sort}`;

   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const getBowlingRanks = (page, size, sort) => {
   // http://localhost:8080/api/statistics/bowling/rank?page=0&size=10&sort=bowlingPoints%2Cdesc
   const url = `${ApiEndpoints.GET_BOWLING_RANKINGS}?page=${page}&size=${size}&sort=${sort}`;

   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const getLiveMatchesStatus = () => {
   const url = ApiEndpoints.LIVE_MATCHES_STATUS;

   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const getLastOutcomeOfMatchesByTeamId = (noOfMatches, teamId) => {
   // https://qa.meta11.com/api/statistics/last/outcome/53?noOfMatches=10
   const url = `${ApiEndpoints.GET_LAST_OUTCOME_OF_MATCHES_BY_TEAM_ID}/${teamId}?noOfMatches=${noOfMatches}`;

   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const getTeamMatchesHistoryById = (tournamentId, teamId) => {
   // https://qa.meta11.com/api/statistics/last/outcome/53?noOfMatches=10
   const url = `${ApiEndpoints.GET_TEAM_MATCHES_HISTORY_BY_ID}/${tournamentId}/${teamId}`;
   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};
