import React, {useEffect, useState} from "react";
import PubNub from "pubnub";
import {PUBNUB_KEYS} from "../../utilities/Constants";
import {connect} from "react-redux";


const PubNubAuthHandler = (props) => {

   const [pubnub, setPubNub] = useState(null);
   const [authSend,setAuthSend] = useState(false)

   useEffect(() => {
      // Initialize PubNub if the user is authenticated
      if (props.isAuthenticated) {
         const pubnubInstance = new PubNub({
            publishKey: PUBNUB_KEYS.PUBLISH_KEY,
            subscribeKey: PUBNUB_KEYS.SUBSCRIBE_KEY,
            uuid: props.userName || 'default-uuid',
            authKey: props.token,
         });
         setPubNub(pubnubInstance);
         return () => {
            console.log("Cleaning up PubNub");
            pubnubInstance.unsubscribeAll();
            pubnubInstance.stop();
         };
      }
   }, [props.isAuthenticated, props.token, props.userName]);

   useEffect(() => {
      if (props.sendMessage != null && pubnub && !authSend) {
         pubNubMessageSender(props.sendMessage.message, props.sendMessage.channel)
         setAuthSend(true)
      } else {
         console.log("message is null")
      }
      return () => {

      }
   }, [props.sendMessage,pubnub]);

   useEffect(() => {
      if(props?.resendAuth && pubnub) {
         console.log("Resending Auth", new Date())
         pubNubMessageSender(props?.sendMessage?.message, props?.sendMessage?.channel)
         props?.afterResendAuth();
      }
   }, [props?.resendAuth, props?.sendMessage, pubnub]);

    const pubNubMessageSender = (message, channel) => {
        let jsonString = JSON.stringify(message);
           if (pubnub){
                  console.log("/////*** -- PUBLISH LOG - AUTH -- *** ///// :",jsonString);
                  let userChannel = `${props.userName}-${props.matchId}`
                  pubnub.subscribe({userChannel});
                  try {
                     console.log("****ENTERED LOG - AUTH ****");
                     pubnub.publish({
                        message: JSON.parse(jsonString),
                        channel: channel,
                     });
                  }catch (e){
                     console.log("****Publish Exception****",e)
                  }
           }
    }




    return (<></>)
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.accessToken !== null,
        refresh: state.auth.refreshToken,
        Token: state.auth.accessToken,
        userId: state.auth.userId,
        profileImage: state.auth.imageURL,
        userName: state.auth.userName,
        email: state.auth.email
    };
}

export default connect(mapStateToProps)(PubNubAuthHandler);
