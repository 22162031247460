import React, {useEffect, useState} from "react";
import Classnames from "classnames";
import styles from "./FantasyMatchHistoryModal.module.scss";
import {getPlayerFantasyMatchHistory} from "../../../services/FantasyService";
import {useNavigate} from "react-router-dom";
import {t} from "i18next";
import {connect} from "react-redux";

const FantasyMatchHistoryModal = ({playerId, tournamentId, playerImage, playerName, handleModalClose, userName, token}) => {
   const [isLoading, setIsLoading] = useState(true);
   const [isHistoryEmpty, setIsHistoryEmpty] = useState(false);
   const [playerMatchHistory, setPlayerMatchHistory] = useState(null);

   const navigate = useNavigate();

   const handleViewMatch = (matchId) => {
      navigate(`/match/${matchId}`);
   };

   useEffect(() => {
      const fetchPlayerMatchHistory = async () => {
         getPlayerFantasyMatchHistory(playerId, tournamentId, userName, token)
            .then((response) => {
               if (response?.apiStatus === true && response?.history?.length > 0) {
                  setPlayerMatchHistory(response?.history);
                  setIsHistoryEmpty(false);
               } else {
                  setIsHistoryEmpty(true);
               }
               setIsLoading(false);
            })
            .catch((error) => {
               console.log(error);
               setIsLoading(false);
            });
      }

      fetchPlayerMatchHistory();
   }, [playerId]);

   const loadingIndicator = (<div className="text-center">
      <div className="lds-facebook">
         <div></div>
         <div></div>
         <div></div>
      </div>
   </div>);

   return (<div
      className={Classnames("modal fade show", styles.modalBlur, styles.PaymentConfirmmodal, styles.FantasyMatchHistoryModal)}
      id="exampleModalCenter"
      tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
      style={{display: "block"}}>
      <div className={Classnames(styles.CreditPurchaseModal, "modal-dialog modal-dialog-centered")} role="document">
         <div className={Classnames("modal-content", styles.modalContentContainer)}>
            <button className={Classnames(styles.closeButton, "close z-3")} type="button" onClick={handleModalClose}
                    data-dismiss="modal" aria-label="Close">
                   <span className={styles.closeButton} aria-hidden="true">
                      <img alt='Close Icon' src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                   </span>
            </button>
            <div className={Classnames(styles.UserProfileModalWrapper)}>

               <div className={Classnames(styles.ellipse, styles.e392)}></div>
               <div className="row p-0">
                  <div className={Classnames(styles.UserProfileModal, "col-md-12")}>
                     <div className={Classnames(styles.TitleWrapper)}>
                        <h4>{t("FANTASY.FANTASY_MATCH_HISTORY")}</h4>
                     </div>
                  </div>
               </div>

               {isLoading ? loadingIndicator : <>
                  <div className="row p-0">
                     <div className={Classnames(styles.ProfilePicWrapper)}>
                        <div className={Classnames(styles.ProfilePicBorder)}>
                           <div className={Classnames(styles.ProfilePic)}
                                style={{backgroundImage: `url(${playerImage})`}}/>
                        </div>
                     </div>
                  </div>
                  <div className={Classnames(styles.PlayerNameWrapper, "text-center pb-2")}><h4>{playerName}</h4></div>
                  <div className="row p-0">
                     <div className={Classnames(styles.FantasyHistoryTable)}>
                        {isHistoryEmpty ? <div className={Classnames(styles.NoFantasyHistoryData)}>{t("FANTASY.NO_HISTORY_AVAILABLE")}</div> : <table>
                           <thead>
                           <tr>
                              <th>{t("FANTASY.TEAM")}</th>
                              <th>{t("FANTASY.OPPOSING_TEAM")}</th>
                              <th>{t("FANTASY.STATUS")}</th>
                              <th>{t("FANTASY.GO_TO_MATCH")}</th>
                              <th>{t("FANTASY.POINTS")}</th>
                           </tr>
                           </thead>
                           <tbody>
                           {playerMatchHistory?.map((match, index) => (<tr key={match?.matchId}  style={{height: "30px"}}>
                              <td>{match?.teamName}</td>
                              <td>{match?.oppositionTeamName}</td>
                              <td>{match?.matchStatus}</td>
                              <td>
                                 <button
                                    onClick={() => handleViewMatch(match?.matchId)}>{t("FANTASY.VIEW_MATCH")}</button>
                              </td>
                              <td>{match?.points > 0 ? "+" + match?.points : match?.points}</td>
                           </tr>))}

                           </tbody>
                        </table>}
                     </div>
                  </div>
               </>}

               <div className="row p-0">
                  <div className={Classnames(styles.ModalButtonRow, "row p-0")}>
                     <button className="lightGreenButton" onClick={handleModalClose}>
                        <text>{t("FANTASY.CLOSE")}</text>
                     </button>
                  </div>
               </div>
               <div className={Classnames(styles.Radial1)}></div>
               <div className={Classnames(styles.Radial2)}></div>
               {/* <div className={Classnames(styles.Radial3)}></div> */}
               <div className={Classnames(styles.EdgeRadial1)}></div>
               <div className={Classnames(styles.EdgeRadial2)}></div>
            </div>
         </div>
      </div>
   </div>)
}

const mapStateToProps = (state) => {
   return {
      userName: state.auth.userName,
      token: state.auth.accessToken
   };
};

export default connect(mapStateToProps)(FantasyMatchHistoryModal);
