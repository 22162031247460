import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Classnames from "classnames";
import styles from "../../Wallet/CreditPackagesModal/CreditPackagesModal.module.scss";
import QbotLogo from "../../../assets/images/Logo_Q_BOT.webp";

const HowDoesBotWork = ({handleShowHowDoesBotWorkModal}) => {
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   return (
      <div className={Classnames("modal fade show", styles.modalBlur, styles.PaymentConfirmmodal, styles.HowDoesBotWorkMmodal)} id="exampleModalCenter"
           tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{display: "block"}}>
         <div className={Classnames(styles.CreditPurchaseModal, "modal-dialog modal-dialog-centered")} role="document">
            <div className={Classnames("modal-content", styles.modalContentContainer)}>
               <button className={Classnames(styles.closeButton, "close")} type="button" onClick={handleShowHowDoesBotWorkModal} data-dismiss="modal" aria-label="Close">
                   <span className={styles.closeButton} aria-hidden="true">
                      <img alt='Close Icon' src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                   </span>
               </button>
               <div className={Classnames(styles.UserProfileModalWrapper)}>
                  {/* <div className={Classnames(styles.ModalHeader)}>
                     <h4><img src={WalletIcon}/> Wallet</h4>
                  </div> */}
                  <div className={Classnames(styles.ellipse, styles.eMulticolorHalf)}></div>
                  <div className={Classnames(styles.ellipse, styles.e392)}></div>
                  <div className="row p-0">
                     <div className={Classnames(styles.UserProfileModal, "col-md-12")}>
                        <div className={Classnames(styles.TitleWrapper)}>
                           <h4>How Does <img src={QbotLogo}/> Work? </h4>
                        </div>
                     </div>
                  </div>

                  <div className="row p-0">
                     <div className={Classnames(styles.ModalBody, "col-md-12 ps-4 pe-4")}>
                        <p>
                           <span>🚀Activate QBOT:</span>
                           Sign in and agree to play to activate QBOT.
                        </p>

                        <p>
                           <span>🔮Predict Outcomes: </span>
                           BOT asks you questions during the game. Try your best to predict the outcomes correctly.
                        </p>

                        <p>
                           <span>✅Check Results & Earn Points: </span>
                           QBOT tells you if you're right or wrong. For every correct answer, you earn points that save to your account.
                        </p>

                        <p>
                           <span>🌟 Summary & Rewards: </span>
                           After each game, you'll see a summary of your performance and points. These points help you climb the leaderboard and win prizes!
                        </p>
                     </div>
                     <div className={Classnames(styles.CheckBoxRow, "row p-0")}>
                        <div className={Classnames(styles.CheckBoxWrapper)}>
                           {/* <span></span> */}
                           <input type="checkbox" id="ShowHide" name="vehicle1" value="ShowHide"/>
                           <label for="ShowHide">Don't show this again</label>
                        </div>
                     </div>
                     <div className={Classnames(styles.ModalButtonRow, "row p-0")}>
                        <button className="grayButton">
                           <text>GET STARTED</text>
                        </button>
                     </div>
                  </div>
                  <div className={Classnames(styles.ellipse, styles.eMulticolorFull)}></div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default HowDoesBotWork;
