import React, {useEffect, useState} from "react";
import styles from "./Error404.module.scss";
import Main_Img from "../../assets/images/404_Main_Img.webp";
import neonLbl from "../../assets/images/404_neonLbl.webp";
import classNames from "classnames";
import Classnames from "classnames";
import Styles from "../PasswordRestoreSuccess/PasswordRestoreSuccess.module.scss";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Error404 = (props) => {
  let navigate = useNavigate();

  const {t} = useTranslation();
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTranslationsLoaded(true);
    }, 2000);
  }, []);

  const loadingIndicator = (
      <div className="w-100 text-center">
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
          <div className="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>);

  if (!translationsLoaded) {
    return loadingIndicator;
  }
  return (
    <>
      <div className={styles.error404Container}>
        <div className="row gx-0">
          <div className={classNames(styles.back404, "col-12")}>
            <div className={classNames(styles.ellipse, styles.e1)}></div>
            <div className={classNames(styles.ellipse, styles.e2)}></div>
            <div className={classNames(styles.ellipse, styles.e3)}></div>
            <div className={classNames(styles.ellipse, styles.e4)}></div>
            <div className="container">
              <div className="row" style={{position: "relative"}}>
                <div className={classNames(styles.logoImgWrapper, "col-12 mt-4")}>
                  <img className={classNames(styles.logoImg)} src="https://storage.googleapis.com/meta11/serviceplatform/M11FantasyBetaLogo.webp"/>
                </div>
                <div className={classNames(styles.mainImgWrapper)}>
                  <img className={classNames(styles.MainImg)} src={Main_Img}/>
                </div>
                <div className={classNames(styles.errorImgWrapper)}>
                  <label className={classNames(styles.errorNo404, styles.errorNo4041)}>404</label>
                  <label className={classNames(styles.errorNo404, styles.errorNo4042)}>404</label>
                  <label className={classNames(styles.errorNo404, styles.errorNo4043)}>404</label>
                  <label className={classNames(styles.errorNo404, styles.errorNo4044)}>404</label>
                  <label className={classNames(styles.errorNo404, styles.errorNo4045)}>404
                  <span className={classNames(styles.errorTxtEllipse)}></span>
                  </label>
                </div>
                <div className={classNames(styles.mainText, "col-12")}>
                  <label className="w-100 heading8 color-white semi-bold " style={{textAlign: "center"}}>
                    {t("404_PAGE.SORRY_YOURE_LOST_IN_THE_META_11_OMNIVERSE")}
                  </label>
                </div>
                <div className={classNames(styles.mainBtnWrapper, "col-12")}>
                  <a className="lightGreenButton" onClick={() => navigate("/")}>
                    {t("404_PAGE.LETS_GET_YOU_HOME")}
                  </a>
                  <br></br>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default (Error404);
