import React, {useEffect, useState} from "react";
import Classnames from "classnames";
import styles from "./PowerBoosterPurchaseModal.module.scss";
import {t} from "i18next";

const PowerBoosterPurchaseModal = ({data, handleModalClose}) => {
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      setIsLoading(false);
   }, []);

   const loadingIndicator = (<div className="text-center">
      <div className="lds-facebook">
         <div></div>
         <div></div>
         <div></div>
      </div>
   </div>);

   return (<div
      className={Classnames("modal fade show", styles.modalBlur, styles.PaymentConfirmmodal, styles.FantasyMatchHistoryModal)}
      id="exampleModalCenter"
      tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
      style={{display: "block"}}>
      <div className={Classnames(styles.CreditPurchaseModal, "modal-dialog modal-dialog-centered")} role="document">
         <div className={Classnames("modal-content", styles.modalContentContainer)}>
            <button className={Classnames(styles.closeButton, "close z-3")} type="button" onClick={handleModalClose}
                    data-dismiss="modal" aria-label="Close">
                   <span className={styles.closeButton} aria-hidden="true">
                      <img alt='Close Icon' src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                   </span>
            </button>
            <div className={Classnames(styles.UserProfileModalWrapper)}>

               <div className={Classnames(styles.ellipse, styles.e392)}></div>
               <div className="row p-0">
                  <div className={Classnames(styles.UserProfileModal, "col-md-12")}>
                     <div className={Classnames(styles.TitleWrapper)}>
                        <h4>{data?.playerName}</h4>
                     </div>
                  </div>
               </div>

               {isLoading ? loadingIndicator :
                     <>
                        <div>Please select one/multiple cards to apply to the selected player.</div>
                        <div className="row p-0">


                        </div>
                     </>}

               <div className="row p-0">
                  <div className={Classnames(styles.ModalButtonRow, "row p-0")}>
                     <button className="lightGreenButton" onClick={handleModalClose}>
                        <text>{t("FANTASY.CLOSE")}</text>
                     </button>
                  </div>
               </div>
               <div className={Classnames(styles.Radial1)}></div>
               <div className={Classnames(styles.Radial2)}></div>
               {/* <div className={Classnames(styles.Radial3)}></div> */}
               <div className={Classnames(styles.EdgeRadial1)}></div>
               <div className={Classnames(styles.EdgeRadial2)}></div>
            </div>
         </div>
      </div>
   </div>)
}

export default PowerBoosterPurchaseModal;
